.dashboard-ca.content-area {
  width: 100% !important;
}

.dashboard-ca .dashboardContainer {
  border-radius: 4px;
  margin: 10px 15px;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 6px 0px;
  background-color: rgb(255, 255, 255);
  padding: 16px 20px 16px 16px;
}

.dashboard-ca .linkContainer {
  margin-top: 10px;
  margin-right: 20px;
}

.dashboard-ca .summaryContainer {
  display: flex;
  flex-flow: row wrap;
}

.dashboard-ca .cardFilters {
  align-items: center;
  margin: 10px;
  flex: 1 1 100%;
}

.dashboard-ca .cardFilters .ant-radio-button-wrapper {
  border: none;
  color: #357FA1;
  font-size: 16px;
  font-size: 18px;
  margin-left: 0;
  margin-right: 10px;
  text-align: center;
}

.dashboard-ca .cardFilters .ant-radio-button-wrapper-checked {
  background-color: #005d89;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #fff;
  text-align: center;
}

.dashboard-ca .ant-radio-button-wrapper span {
  vertical-align: middle;
  line-height: 1;
  font-weight: bolder;
}

.dashboard-ca .cardFilters .ant-radio-button-wrapper-checked:hover {
  color: #fff;
}

.dashboard-ca .cardFilters .ant-radio-button-wrapper::before {
  display: none;
}

.dashboard-ca .serviceChart .customDateRadioButton .ant-radio-button-wrapper {
  margin-right: 0px;
  margin-left: 0px;
  font-size: 16px;
}

.dashboard-ca .cardFilters .ant-select {
  width: 230px;
}

.dashboard-ca .franchiseFilter {
  display: flex;
  justify-content: end;
}

.dashboard-ca .filterNote {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: grey;
  padding-left: 15px;
}

.dashboard-ca .cardMetric {
  margin: 5px 10px;
  padding: 16px 20px 16px 16px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 6px 0px;
  background-color: rgb(255, 255, 255);
}

.dashboard-ca .cardMetricTotalCount {
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.38px;
  text-align: left;
  line-height: 1;
}

.dashboard-ca .cardMetricTitle {
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1;
  margin-left: 10px;
  color: #000;
  text-decoration: underline;
}

.dashboard-ca .cardMetricTotalSection {
  font-weight: 900;
  font-size: 1.1em;
  line-height: 1;
}

.dashboard-ca .cardMetricTotalUnit {
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.dashboard-ca .cardMetricGoTo {
  display: none;
  font-size: 10px;
  margin-left: 12px;
  text-decoration: underline;
  color: #2485fc;
  cursor: not-allowed;
}

.dashboard-ca .metricTitle {
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: normal;
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.38px;
  line-height: 1;
  color: #3e3e3e;
}

.dashboard-ca .cardContainer {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 6px 0px;
  background-color: rgb(255, 255, 255);
}

.dashboard-ca .serviceChart {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;

}

.dashboard-ca .serviceChart .ant-radio-button-wrapper {
  margin-right: 0px;
  margin-left: 0px;
  font-size: 16px;
}

.dashboard-ca .cardTitle {
  margin: 0 10px 0px 5px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dashboard-ca .cardSubTitle {
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dashboard-ca .ant-table-wrapper .ant-table-tbody td:first-child {
  padding-left: 10px;
}

.dashboard-ca .ant-table-wrapper .ant-table-thead th:first-child {
  padding-left: 10px;
}

.dashboard-ca .ant-table-wrapper .ant-table-tbody td:first-child {
  padding: 3px 0 3px 5px;
}

.dashboard-ca .ant-table-wrapper .ant-table-tbody td {
  padding: 3px;
}

.dashboard-ca td.columnProgress {
  font-family: Lato;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dashboard-ca .cardContainer.pieContainer {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.dashboard-ca .no-data-component {
  width: 550px;
  height: 300px;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-ca .no-data-title {
  position: absolute;
  pointer-events: none;
  width: 179.2px;
  transform: translate(-50%, -100%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 0px;
  -webkit-text-stroke: 0px;
  font-weight: 300;
  color: rgb(75, 83, 94);
  text-align: center;
  font-size: 20px;
  line-height: 1;
  left: 265.5px;
  top: 146px;
  z-index: 7;
}

.dashboard-ca .no-data-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-ca .no-data-content {
  position: absolute;
  pointer-events: none;
  width: 179.2px;
  transform: translate(-50%, 0px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 0px;
  -webkit-text-stroke: 0px;
  font-weight: bold;
  color: rgba(44, 53, 66, 0.85);
  text-align: center;
  font-size: 32px;
  line-height: 1;
  left: 265.5px;
  top: 154px;
  z-index: 7;
}

.dashboard-ca .anticon-caret-up:before {
  color: rgb(74 154 251);
  width: 20px;
  margin-bottom: 0px;
}

.dashboard-ca .anticon-caret-down:before {
  color: rgb(223, 61, 49);
  width: 20px;
  margin: 0px;
}

.dashboard-ca .anticon-caret-up svg {
  display: none;
}

.dashboard-ca .anticon-caret-down svg {
  display: none;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.staffAssignMenu {
  max-height: 200px;
  overflow: auto;
}

.dashboard-ca .ant-dropdown-trigger.btn {
  background-color: #ffff;
  border-color: #d6d4d4;
  width: 45px;
  color: #000;
  padding: 8px 0;
}

.dashboard-ca .ant-select-selector {
  background-color: #fff !important;
  border: 1px solid #eee !important;
}

.dashboard-ca .ant-select-selection-item {
  color: #bbbbbb !important;
}

.dashboard-ca .ant-select-selection-item::before {
  content: "Franchise: ";
}

.dashboard-ca .quick-link-title {
  color: #000;
  text-decoration: underline;
  font-weight: 900;
  font-size: 14px;
  margin-top: 10px;
}

.dashboard-ca .quick-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.dashboard-ca .quick-link-home {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.dashboard-ca .calendarViewAddBtn+.list-group {
  right: 116px;
}

.dashboard-ca .quick-link-wrapper {
  justify-content: flex-end;
}

.dashboard-ca .global-links-wrapper {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  max-height: 51px;
}

.dashboard-ca .commercial-count img,
.dashboard-ca .residential-count img {
  margin-right: 5px
}

.dashboard-ca .commercial-count {}

.dashboard-ca .residential-count {
  margin-left: 15px;
}

.dashboard-ca .cardMetricTotalCount .ant-skeleton-input {
  width: 50px;
  height: 14px;
  line-height: 1;
  min-width: 0px;
  vertical-align: initial;
}

.dashboard-ca .commercial-residential-skeleton {
  width: 240px;
  height: 14px;
  line-height: 1;
  min-width: 0px;
  vertical-align: initial;
}

.dashboard-ca .comparison-skeleton {
  width: 240px;
  height: 14px;
  line-height: 1;
  min-width: 0px;
  vertical-align: initial;
}

@media (max-width: 768px) {
  .dashboard-ca .quick-link-container {
    width: 40px;
  }

  .dashboard-ca .quick-link-title {
    font-size: 10px;
  }

  .dashboard-ca .quick-link-wrapper {
    justify-content: center;
  }

  .dashboard-ca .global-links-wrapper {
    justify-content: center;
  }

  .dashboard-ca .cardFilters .ant-radio-button-wrapper {
    font-size: 14px;
  }

  .dashboard-ca .franchiseFilter {
    justify-content: center;
  }

  .dashboard-ca .global-links-wrapper {
    margin-top: 10px;
    background-color: #f4f7f9;
  }

  .dashboard-ca .residential-count,
  .dashboard-ca .commercial-count {
    width: 100%;
  }

  .dashboard-ca .residential-count {
    margin-left: 0 !important;
  }

  .dashboard-ca .cardMetricTotalSection {
    font-size: 1em;
  }

  .dashboard-ca .cardMetricTotalCount,
  .dashboard-ca .cardMetricTitle {
    font-size: 18px;
  }

  .dashboard-ca .quick-link-container {
    width: 60px;
  }

  .dashboard-ca .calendarViewAddBtn .buttonText {
    font-size: 14px;
    bottom: 0px;
    right: 0px;
    width: auto;
  }

  .dashboard-ca .calendarViewAddBtnMobile {
    width: 100px !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
    bottom: 0;
    left: 0;
    height: 40px;
  }

  .dashboard-ca .quick-link-title {
    font-size: 14px;
  }

  .dashboard-ca .quick-link-wrapper {
    justify-content: center;
  }

  .calendarViewAddBtn+.list-group.list-view-mobile {
    top: 55px !important;
    left: auto !important;
    right: 122px !important;
    margin-top: 0px;
  }

  .dashboard-ca .cardFilters .ant-select {
    width: 100%;
  }

  .dashboard-ca .timeFilter {
    order: 2;
    margin: 5px 0;
  }

  .dashboard-ca .franchiseFilter {
    order: 1;
  }

  .dashboard-ca .filterNote {
    order: 3;
  }

  .dashboard-ca .cardFilters {
    margin-top: 0px;
  }

}

@media (max-width: 480px) {
  .dashboard-ca .quick-link-container {
    width: 40px;
  }

  .dashboard-ca .quick-link-title {
    font-size: 10px;
  }
}