.proposal-heading {
    padding: 50px 30px 40px 25px;
}

.btn.btn-proposal-cancel {
    background-color: #fff;
    color: #000;
    border: 1px solid;
}

.global-links-wrapper-proposal {
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    max-height: 58px;
}

.linkContainer-proposal {
    display: flex;
    justify-content: flex-end;
    max-height: 70px;
}

.quick-link-title-proposal {
    color: #000;
    text-decoration: underline;
    font-weight: 900;
    font-size: 14px;
    margin-top: 10px;
}

.quick-link-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;
}

.quick-link-home-proposal {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.franchise_type.ant-select-single.ant-select-show-arrow .ant-select-selection-item::before {
    content: "Franchise: ";
    font-weight: 600;
}

.Proposal-text {
    font-weight: 800;
    font-size: 20px;
}

.proposalRightButton {
    padding-right: 10px;
}

.arrowButtonProposal {
    display: inline-block;
    height: 35px;
    border-left: 2px solid #fff;
    margin-left: 13px;
}

.arrowButtonActionProposal {
    display: inline-block;
    height: 35px;
    border-left: 2px solid #fff;
    margin-left: 30px;
}

.proposalRightButton .proposalbtn,
.proposalRightButton .proposalActionbtn,
.proposalRightButton .proposalEditbtn {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 37px;
    display: inline-flex;
}

.proposalRightButton .proposalbtn.btn.btn-primary.btn-bdr.flex:hover,
.proposalRightButton .proposalbtn.btn.btn-primary.btn-bdr.flex:focus,
.proposalRightButton .proposalActionbtn.btn.btn-primary.btn-bdr.flex:hover,
.proposalRightButton .proposalActionbtn.btn.btn-primary.btn-bdr.flex:focus,
.proposalRightButton .proposalEditbtn.btn.btn-primary.btn-bdr.flex:hover {
    background-color: #ff681d;
    opacity: 0.7;
    cursor: pointer;
}
.proposalRightButton .proposalEditbtn.btn.btn-primary.btn-bdr.flex:focus {
    background-color: #ff681d;
    cursor: pointer;
}

.downArrowIcon {
    position: relative;
    left: 12px;
    display: inline-block;
    padding-top: 14px;
}

.downArrActionIcon {
    position: relative;
    left: 11px;
    top: 14px;
    display: inline-block;
}

.buttonTextProposal {
    color: #fff;
    padding-top: 8px;
}

.proposalActionbtn {
    width: 150px;
}

.proposalbtn+.list-group {
    position: absolute;
    display: block;
    top: 65px;
    width: 118px;
    right: 360px;
    margin-top: -9px;
    margin-left: 4px;
    box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.proposalActionbtn+.list-group {
    position: absolute;
    display: block;
    top: 65px;
    width: 145px;
    right: 78px;
    margin-top: -9px;
    margin-left: 4px;
    box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.proposalbtn+.list-group .list-group-item,
.proposalActionbtn+.list-group .list-group-item {
    background: white;
    border-radius: 2px;
    line-height: 35px;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 0px;
}

.mitem-proposal {
    position: relative;
    top: 4px;
}

.content-area.proposal-container {
    width: 100%;
}

.content-area.proposal-container .ant-table-content {
    overflow-x: auto;
}

.proposal-action-dd .ant-dropdown-menu-item {
    color: #000;
    font-weight: 400;
}

.proposal-heading {
    padding-left: 25px;
    padding-top: 50px;
}

.dout-text {
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.csv-icon,
.pdf-icon {
    max-width: 25px;
    margin-top: 10px;
}

.export-text {
    padding-left: 12px;
    cursor: pointer;
}

.new-proposal-text {
    cursor: not-allowed;
    opacity: 0.6;
}

.bulk-email-text {
    cursor: pointer;
}

.right_side.but-sec {
    padding-right: 40px
}

.filterDrawer.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    margin-bottom: 5px;
    height: auto;
}
.proposal-action-dd.disableActions .ant-dropdown-menu-item{
    pointer-events: none;
    color: rgba(0,0,0,.25);
    cursor: not-allowed;
}
.proposal-action-dd.disableActions{
    cursor: not-allowed;
}