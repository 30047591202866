.report-ca.content-area {
  width: 100% !important;
}
.report-ca .flist .card {
  display: flex;
  flex: auto;
  flex-direction: column;
}
.report-ca .card .card-title {
  flex: 0 0 auto;
}
.report-ca .card .card-body {
  flex: 1;
}
.report-ca .card .card-footer {
  flex: 0 0 auto;
}
.report-ca .btn.btn-bdr,
.report-ca button[type="button"].btn-bdr {
  background-color: #fff;
  color: rgb(0, 93, 137);
}
.report-ca .btn-warning-outline {
  background-color: transparent;
  color: #ff681d;
  border-color: #ff681d;
}
.report-ca .btn.btn-bdr button:disabled,
.report-ca .btn.btn-bdr button[disabled],
.report-ca button:disabled,
.report-ca button[disabled] {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
  cursor: not-allowed;
}
.report-ca .breadcrumb li span {
  font-family: "Lato", sans-serif;
  font-weight: bolder;
}
.report-ca .pointer {
  display: flex;
  align-items: center;
}
.report-ca .pointer > span svg {
  -webkit-transition: color 2s ease-out;
  -moz-transition: color 2s ease-out;
  -o-transition: color 2s ease-out;
  transition: color 2s ease-out;
  -webkit-transition: transform 1s ease-out;
  -moz-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}
.pointer:hover > span svg {
  transform: translateX(-3px);
  color: rgb(0, 93, 137);
}
.report-ca .account_module_wrapper .block-row .filter_wrapper {
  background-color: #f4f7f9;
  margin-bottom: 10px !important;
}
.report-ca .main h2 {
  line-height: 1;
  border-bottom: 0;
  padding-bottom: 0;
  font-weight: 500;
}
.report-ca .aside-area .main {
  border: none;
}
.report-ca .territory-chart {
  margin: 30px 0px;
  border: 2px solid rgb(238, 238, 238);
  padding: 0px 5px;
}
.report-ca .ant-table-thead > tr > th {
  background: #fbfbfd;
}
.report-ca .ant-table-tbody > tr.ant-table-row:hover > td,
.report-ca .ant-table-tbody > tr.ant-table-row:focus > td {
  background-color: #f2f7d2;
}
.report-ca .csv-icon,
.report-ca .pdf-icon {
  font-size: 1.8em;
}
.report-ca .csv-icon {
  color: #848586;
}
.report-ca .block-row.pt-10 {
  padding-left: 0px;
}
.report-ca .anticon.anticon-left.PlayGround-module--trigger--3uH44 {
  font-size: 12px;
  position: relative;
  margin: auto;
  color: #6c7f90;
  width: 30px !important;
  left: -15px;
  z-index: 4;
  cursor: pointer;
  transition: color 0.3s;
  background-color: #fff;
  height: 30px;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #ececec;
  border-left: none;
  text-align: center;
}
.report-ca
  .anticon.anticon-left.PlayGround-module--trigger--3uH44[type="menu-unfold"] {
  background-color: #005d89;
  color: #fff;
}
.report-ca
  .anticon.anticon-left.PlayGround-module--trigger--3uH44[type="menu-unfold"]
  svg {
  transform: rotate(180deg);
}
.report-ca .PlayGround-module--trigger--3uH44 svg {
  position: absolute;
  left: 9px;
  top: 8px;
}
.report-ca .invoice-table-container,
.report-ca .territory-table-container {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.report-ca .invoice-table-container .ant-table-content {
  /* border: 1px solid #dee2e6; */
  margin-bottom: 20px;
}
.report-ca tfoot.ant-table-summary td.ant-table-cell {
  font-weight: bolder;
  background: #fbfbfd;
}
.report-ca tfoot td {
  border-top: none;
}
.report-ca th.ant-table-cell.tname {
  font-weight: 900;
  font-size: 14px;
  color: #000;
}
.report-ca .territory-table-container .table.hscroll tr:nth-child(2n) {
  background-color: #f4f7f9;
}
.report-ca .table.hscroll thead {
  background-color: #f1f1f1;
}
.report-ca .required {
  color: red;
}

.report-ca .invoice_rec_services {
  text-align: left;
  color: #bcbcbe;
  font-size: 12px;
  background: rgba(245, 245, 250, 0.4);
  padding: 8px 30px;
  text-transform: capitalize;
  line-height: 5px;
}
.report-ca .inv-service-list {
  padding-left: 45px !important;
}
.report-ca .territory-table-container .table td:first-child,
.report-ca .invoice-table-container .table th:first-child {
  border-right: 1px solid #efeff8;
  min-width: 217px;
  width: 58% !important;
}
.report-ca .lead-table-container .table td:nth-child,
.report-ca .lead-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 300px;
  width: 58% !important;
}
.report-ca .lead-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .lead-table-container .table td:nth-child(13),
.report-ca .lead-table-container .table th:nth-child(13) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .lead-table-container .table td:nth-child(14),
.report-ca .lead-table-container .table th:nth-child(14) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .lead-table-container .table td:nth-child(15),
.report-ca .lead-table-container .table th:nth-child(15) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .customer-table-container .table td:nth-child,
.report-ca .customer-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 300px;
  width: 58% !important;
}
.report-ca .customer-table-container .table td:first-child{
  font-weight: normal;
}
.report-ca .customer-table-container .table td:nth-child(10),
.report-ca .customer-table-container .table th:nth-child(10) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .customer-table-container .table td:nth-child(11),
.report-ca .customer-table-container .table th:nth-child(11) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .customer-table-container .table td:nth-child(14),
.report-ca .customer-table-container .table th:nth-child(14) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .customer-table-container .table td:nth-child(15),
.report-ca .customer-table-container .table th:nth-child(15) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .customer-table-container .table td:nth-child(17),
.report-ca .customer-table-container .table th:nth-child(17) {
  min-width: 225px;
  max-width: 500px;
}
.report-ca
  .aging_summary_reports_container
  .lead-table-container
  .table
  td,
.report-ca
  .aging_summary_reports_container
  .lead-table-container
  .table
  th {
  min-width: 150px;
}

.report-ca .franch-min-w {
  min-width: 15%;
  position: relative;
}
.report-ca
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  .ant-table-cell
  .ant-table-wrapper:only-child
  .ant-table {
  margin: 0;
}

.report-ca .work_order_report_container aside,
.report-ca .expiring_customer_payment_method_report_container aside {
  display: none !important;
}

.report-ca
  .captured_lead_reports_container
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.report-ca
  .work_order_report_container
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.report-ca
  .expiring_customer_payment_method_report_container
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fff;
}
.report-ca .captured_lead_reports_container .ant-picker,
.report-ca .work_order_report_container .ant-picker,
.report-ca .expiring_customer_payment_method_report_container .ant-picker {
  background-color: #fff;
}
.report-ca .report-table h6 {
  font-size: 17px;
  font-weight: 900 !important;
}
.report-ca .ant-picker-date-panel .ant-picker-body {
  padding: none;
}
.report-ca th.ant-table-cell.leadcustomerreport {
  font-weight: 900;
  font-size: 14px;
  color: #000;
}

.report-ca
  .customer-table-container
  .table
  td,
.report-ca
  .lead-table-container
  .table
  th {
  min-width: 150px;
}
.report-ca th.ant-table-cell.leadcustomerreport{
  font-weight: 900;
  font-size: 14px;
  color: #000;
}

.report-ca .chemical-table-container .table td:nth-child,
.report-ca .chemical-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .chemical-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .chemical-table-container  .table td:nth-child(3),
.report-ca .chemical-table-container  .table th:nth-child(3) {
  min-width: 200px;
  max-width: 500px;
}
.report-ca .chemical-table-container  .table td:nth-child(5),
.report-ca .chemical-table-container  .table th:nth-child(5) {
  min-width: 110px;
  max-width: 500px;
}
.report-ca .chemical-table-container  .table td:nth-child(6),
.report-ca .chemical-table-container  .table th:nth-child(6) {
  min-width: 110px;
  max-width: 500px;
}

.report-ca .chemical-summary-table-container .table td:nth-child,
.report-ca .chemical-summary-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .chemical-summary-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .chemical-summary-table-container  .table td:nth-child(2),
.report-ca .chemical-summary-table-container .table th:nth-child(2) {
  min-width: 170px;
  max-width: 500px;
}
.report-ca .chemical-summary-table-container .table td:nth-child(5),
.report-ca .chemical-summary-table-container  .table th:nth-child(5) {
  min-width: 110px;
  max-width: 500px;
}

.report-ca .technician-notes-table-container .table td:nth-child,
.report-ca .technician-notes-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .technician-notes-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .technician-notes-table-container  .table td:nth-child(10),
.report-ca .technician-notes-table-container  .table th:nth-child(10) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .technician-notes-table-container  .table td:nth-child(3),
.report-ca .technician-notes-table-container  .table th:nth-child(3) {
  min-width: 260px;
  max-width: 500px;
}

.report-ca .technician-notes-1-table-container .table td:nth-child,
.report-ca .technician-notes-1-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .technician-notes-1-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .technician-notes-1-table-container  .table td:nth-child(9),
.report-ca .technician-notes-1-table-container  .table th:nth-child(9) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .technician-notes-1-table-container  .table td:nth-child(3),
.report-ca .technician-notes-1-table-container  .table th:nth-child(3) {
  min-width: 260px;
  max-width: 500px;
}
.report-ca .technician-notes-2-table-container .table td:nth-child,
.report-ca .technician-notes-2-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .technician-notes-2-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .technician-notes-2-table-container  .table td:nth-child(8),
.report-ca .technician-notes-2-table-container  .table th:nth-child(8) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .technician-notes-2-table-container  .table td:nth-child(2),
.report-ca .technician-notes-2-table-container  .table th:nth-child(2) {
  min-width: 260px;
  max-width: 500px;
}

.report-ca .technician-notes-3-table-container .table td:nth-child,
.report-ca .technician-notes-3-table-container .table th:nth-child {
  border-right: 1px solid #efeff8;
  min-width: 150px;
  max-width: 300px;
}
.report-ca .technician-notes-3-table-container .table td:first-child {
  font-weight: normal;
}
.report-ca .technician-notes-3-table-container  .table td:nth-child(9),
.report-ca .technician-notes-3-table-container  .table th:nth-child(9) {
  min-width: 300px;
  max-width: 500px;
}
.report-ca .technician-notes-3-table-container  .table td:nth-child(2),
.report-ca .technician-notes-3-table-container  .table th:nth-child(2) {
  min-width: 260px;
  max-width: 500px;
}