@media screen and (min-width: 768px) {
    .addNewCustomerBaseJobModal {
        
        width: clamp(50%, calc(100vw - 330px), 600px) !important;
        min-width: clamp(50%, calc(100vw - 330px), 600px) !important;
        max-width: clamp(50%, calc(100vw - 330px), 600px) !important;
        margin: unset;
        left: 50%;
        margin-left:-25%;
    }

    .addNewCustomerBaseJobModal .ant-modal-content {
        margin-top: unset;
        width: unset;
    }
}

.addNewCustomerBaseJobModal .oplist {
    display: flex;
    flex:1 1 auto;
}

.addNewCustomerBaseJobModal .oplist .inline-checks {
    display: flex;
    flex: 1 1 46%;
    justify-content: start;
    align-items: center;
}

.addNewCustomerBaseJobModal .oplist .inline-checks label {
    width:100%;
}

.addNewCustomerBaseJobModal .ant-modal-content .ant-col:nth-child(even) {
    padding: 1vh 0 1vh 0.5vw;
}
.addNewCustomerBaseJobModal .ant-modal-content .ant-col:nth-child(odd) {
    padding: 1vh 0.5vw 1vh 0;
}