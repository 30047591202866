.react-autosuggest__container {
  position: relative;
  z-index: 2;
}

.suggestion-selected .react-autosuggest__container {
  z-index: 10001;
}

.react-autosuggest__input {
  font-weight: 300 !important;
  font-size: 14px !important;
  padding: 17px 16px 17px 45px !important;
  background: #fff !important;
  border: none !important;
  line-height: 1 !important;
  border-radius: 0 !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container,
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 54px;
  width: 100%;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 254px;
  overflow-y: auto;
  z-index: 10001;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(3, 88, 152, 0.15) !important;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(3, 88, 152, 0.15) !important;
}

.react-autosuggest__suggestion-match {
  color: black;
  /*rgb(0, 93, 137)*/
  font-weight: bold;
}

.react-autosuggest__suggestions-container .ant-empty {
  padding: 15px 0;
  color: rgba(0, 0, 0, .25);
}

.inputContainer {
  position: relative;
}

.icon {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 24px;
  color: #1E2025;
  font-size: 19px;
  font-weight: bold;
  transform: rotate(-90deg);
}

.suggestion-type {
  margin-left: 5px;
}

.suggestion-type .ant-tag {
  padding: 0 4px;
}

.suggestion-icon {
  margin-right: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  color: white;
}

.react-autosuggest__section-title {
  margin: 10px 0 0 10px;
  text-transform: capitalize;
  font-weight: 600;
  color: #005d89;
}
