.ant-radio-checked .ant-radio-inner {
    border-color: #396d6b;
}

.ant-radio-inner::after {
    background-color: #396d6b !important
}

.ant-select-single {
    border-radius: 4px;
}

.ant-select-single:not(.ant-select-disabled):hover,
.ant-select-multiple:not(.ant-select-disabled):hover {
    border-color: #407977 !important;
    /*border-width: 2px !important;*/

}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #396d6b !important
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #396d6b !important
}

.refund_select {
    border: 1px solid #edeeef !important;
    border-width: 2px !important;
    background: none;

}

@media (max-width:768px) {
    .ant-select-single {
        padding-bottom: 5px;
    }
}

.ant-select-single:not(.ant-select-disabled):focus .ant-select-selector,
.ant-select-multiple:not(.ant-select-disabled):focus .ant-select-selector {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-select-single.ant-select-focused .ant-select-selector,
.ant-select-multiple.ant-select-focused .ant-select-selector {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}