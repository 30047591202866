.invoice-container .flex-service {
    /* max-width: 60%; */
}
.invoice-container .table-block {
    max-width: 100%;
    overflow: hidden;
}
.editrow{
    margin-left: -10px !important;
}  
.edittax{
    margin-top: 12px;
}
.divhead .dcell, .divrow .dcell {
    flex: 1;
    flex: 1;
    min-width: 130px !important;
    min-width: 180px;
    width: 10%;
    width: 10%;
    max-width: 180px; 
}
.editcell{
    min-width: 115px !important;
}

.divhead .dcell.sno, .divrow .dcell.sno {
    padding-left: 10px; 
}
.divhead .dcell.squantity {
    padding-left: 6px; 
}
.divrow.dfoot .pricing {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    padding: 10px 102px;
}

.clear-item{
    padding-left: 15px;
    font-weight: bold;
    font-weight: bold;
}
.invoice-message-label{
    color: #6F6F6F !important;
}

.divrow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 0px; 
}

.divrow.dfoot .dcell {
    padding: 0;
    max-width: 80%;
    align-items: flex-start; 
}

.divrow.dfoot .price-block {
    padding-right:0px 
}

.dark-background + div > span.ant-select-selector {
    color: white !important;
    opacity: 1;
}

.dark-background > span > span > svg {
    background-color: white !important;
}

.maintenance-table{
    display: block !important;
}
.maintenance-cell-margin{
    margin-top: 85px!important;
}