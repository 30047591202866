.aleft {
  border-right: 2px solid hsla(0, 0%, 84.7%, 0.5);
  min-width: 360px;
  width: 360px;
}

.main {
  padding: 2em 2em 1em;
  margin-right: auto;
  margin-left: auto;
}

.main h2 {
  font-size: 1.5em;
  font-weight: 300;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 0.5em;
  margin: 0 0 1em;
  letter-spacing: -0.5px;
}

.main h1 {
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: -0.5px;
  margin-right: 5px;
}

.btn-disabled.disabledBtn {
  background-color: gray;
  cursor: no-drop;
}

.rc-virtual-list .rc-virtual-list-holder {
  overflow-y: auto !important;
  cursor: pointer;
}

.rc-virtual-list-holder::-webkit-scrollbar {
  width: 10px;
} 

/* Track */
.rc-virtual-list-holder::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius:4px;
}   

/* Handle */
.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:4px;
} 

.rc-virtual-list-scrollbar {
  display: block !important;
  visibility: visible !important;
  z-index: -1;
}

.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  z-index: 99;
  background: transparent !important; 
} 

.tec-re-btn .pagination_page_size {
  position: relative;
  padding: 0;
  margin-top: -40px;
  width: max-content;
}

.hide {
  display: none;
}

.amount-process input::-webkit-outer-spin-button,
.amount-process input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
