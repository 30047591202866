.propsal-base-tab {
  margin-bottom: 60px;
}

.propsal-base-tab .status-arrow {
  width: 63%;
  margin-right: 2%;
}

.propsal-base-tab .div-status {
  height: 240px;
  padding: 20px;
  background-color: white;

}

.proposal-button {
  border: 0;
  text-align: left;
  padding: 9px 20px;
  width: 91%;
}

.proposal-status {
  border: 0;
  text-align: left;
  padding: 7px 20px;
  width: 100%;
  height: 50px;
  border-right: 1px solid #fff;
  margin-bottom: 7px;
  background-color: #f0f0f0 !important  ;
}

.proposal-button.inquire {
  background-color: #fad136;
}


.w-200 {
  width: 250px;
}

.status-w-215 {
  width: 223px;
}

.status-w-233 {
  width: 233px;
}

.status-w-235 {
  width: 235px;
}

.inquire-status .trangle {
  border-top: 21px solid #29b6e2;
  border-bottom: 21px solid #29b6e2;
  border-left: 21px solid #fad136;
  position: absolute;
}
.inquire-status .trangles-inq{
  border-top: 21px solid #29b6e2;
  border-bottom: 21px solid #29b6e2;
  border-left: 21px solid #f0f0f0;
  position: absolute;
}

.inquire-status .trangle-one {
  border-top: 21px solid #b8cf26;
  border-bottom: 21px solid #b8cf26;
  border-left: 21px solid #29b6e2;
  position: absolute;
}
.inquire-status .trangle-pro {
  border-top: 21px solid #b8cf26;
  border-bottom: 21px solid #b8cf26;
  border-left: 21px solid #f0f0f0;
  position: absolute;
}
.inquire-status .trangle-send {
  border-top: 21px solid #f0f0f0;
  border-bottom: 21px solid #f0f0f0;
  border-left: 21px solid #f0f0f0;
  position: absolute;
}

.inquire-status .trangle-two {
  border-top: 21px solid #f0f0f0;
  border-bottom: 21px solid #f0f0f0;
  border-left: 21px solid #b8cf26;
  position: absolute;
}

.inquire-status .trangle-three {
  border-top: 21px solid white;
  border-bottom: 21px solid white;
  border-left: 21px solid #f0f0f0;
  position: absolute;
}

.proposal-button.progress {
  background-color: #29b6e2;
  color: black;
}

.proposal-button.sent {
  background-color: #b8cf26;
  color: black;

}

.proposal-button.accepted {
  background-color: #005d89;
  color: black;
}

.proposal-button.sent-two {
  background-color: #f4f7f9;
  width: 4%;

}

.propsal_tab_css {
  margin: 20px;
}

.propsal_tab_css .propsal_body {
  width: 300px;
  height: 230px;
  border: 2px dashed;
  border-color: orange;
}

.propsal_tab_css .pro_label {
  margin: 30px;
  text-align: center;
}

.propsal_tab_css .proposal_lable {
  margin: 10px;
  text-align: center;
}

.propsal_tab_css .pro_btn {
  margin: 10px;
  text-align: center;
}

.main_pro_css {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
}

.main_pro_css .sec_pro_css {
  background-color: #fff;
  width: 56%;
}

.main_pro_css .foure_pro_css {
  margin-top: 30px;
}

.pool-css {
  margin-top: 30px;
}

.half-pool-css {
  display: -webkit-inline-box;
}

.proposal_notes {
  width: 40%;
}

.proposal_additional {
  background-color: #f4f7f9;
  padding: 15px;
  border-radius: 4px #f4f7f9;
  border: 0;
  height: 200px;
  width: 100%;
}

.proposal_notes .propsal_mail {
  background-color: #f4f7f9;
  padding: 15px;
  border-radius: 11px;
  height: 200px;
  overflow: auto;
}

.proposal_info {
  background-color: white;
  width: 35%;
  padding: 23px;
}

.maim_data {
  display: flex;
  justify-content: space-between;
}

.main_sec_data {
  display: flex;
  justify-content: space-between;
}

.main_sec_data,
.maim_data {
  margin-bottom: 15px;
}

/* .main_thee_data{
    padding: 10px;
  } */
.number_date label {
  display: block;
  font-weight: bold;
  float: left;
}

.text___color {
  color: gray;
  font-size: 12px;
}

.number_date input {
  width: 100%;
  background-color: #f4f7f9;
  border: 0px;
  padding: 5px 10px;
  height: 36px;
}

.number_date {
  width: 49%;
}

.dp-flex {
  display: flex;
}

.margin-top-30 {
  margin-top: 30px;
}

.main_sec_data p,
.main-three-data p {
  margin-bottom: 0;
}

.status-in-progress {
  background-color: rgb(0, 93, 137);
  color:black;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.proposal-a {
  color: rgb(0, 93, 137);
}

.proposal-a.disabled, .proposal-link-disabled{
  color: rgba(0,0,0,.75) !important;
  pointer-events: none;
  cursor: default !important;
}

.proposalListBtn {
  background: #ff671d !important;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 40px;
  height: 37px;
  margin-left: 0 !important;
  border-radius: 1px !important;
}

.proposalSaveBtn {
  background: #ff671d !important;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 155px;
  height: 37px;
  margin-right: 0 !important;
  border-right: 2px solid #efefef;
  border-radius: 1px !important;
}

.proposalListBtn .arrowButton {
  display: inline-block;
  height: 37px;
  border-left: 2px solid #fff;
  margin-left: 13px;
}

.proposalListBtn .downArrowIco {
  position: relative;
  display: inline-block;
}

.proposalListBtn+.list-group {
  position: absolute;
  display: block;
  top: -42px;
  width: 150px;
  right: 34px;
  margin-top: -9px;
  margin-left: 4px;
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.proposalListBtn+.list-group.list-view-mobile {
  top: -150px !important;
  left: -111px !important;
}

.proposalListBtn+.list-group .list-group-item {
  background: white;
  border-radius: 2px;
  line-height: 35px;
  text-align: left;
  padding-left: 15px;
  padding-bottom: 0px;
}

.proposalListBtn+.list-group .list-group-item:hover {
  background: #e5e4e4;
  cursor: pointer;
}

.proposalListBtn:hover {
  opacity: 0.99 !important;
}

.propsal_tab_css {
  margin: 20px;
}

.propsal_tab_css .propsal_body {
  width: 300px;
  height: 230px;
  border: 2px dashed;
  border-color: orange;
}

.propsal_tab_css .pro_label {
  margin: 30px;
  text-align: center;
}

.propsal_tab_css .proposal_lable {
  margin: 10px;
  text-align: center;
}

.propsal_tab_css .pro_btn {
  margin: 10px;
  text-align: center;
}

.proposal-flex-end {
  display: flex;
  justify-content: end;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.proposal-leftIcon {
  margin-right: 7px;
  top: 1px;
  position: relative;
  height: 10px;
}

.proposal-form-container {
  display: flex;
  margin-bottom: 30px;
  height: 240px;
}

.proposal-date-picker .ant-picker-input .ant-picker-suffix {
  order: -1;
}

.proposal-add-item-button {
  background-color: transparent;
  border: none;
}

.mb-5 {
  margin-bottom: 5px;
}

button.proposal-button:after {
  content: " ";
    position: absolute;
    top: 0;
    right: 5.5px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #f0f0f0;
    z-index: 2;
    transition: border-color 0.2s ease;
}

button.proposal-button:before {
  content: " ";
    position: absolute;
    top: 0;
    left: 0px;
    right: auto;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 14px solid white;
    z-index: 0;
    transition: border-color 0.2s ease;
}

.inquire-status.received button.proposal-button:before {
  border-left: none;
}

.proposal-button.inquire:after {
  border-left: 17px solid #fad136;
}

.proposal-button.progress:after {
  border-left: 17px solid #29b6e2;
}

.proposal-button.sent:after {
  border-left: 17px solid #b8cf26;;
}

.proposal-button.accepted:after {
  border-left: 17px solid #005d89;
}

.container-one .inquire-status:nth-child(2) {
    left: -15px;
}

.container-one .inquire-status:nth-child(3) {
  left: -30px;
}

.container-one .inquire-status:nth-child(4) {
  left: -45px;
}

.proposal-inquiry-slab{
  border: 0;
    text-align: left;
    padding: 7px 20px;
    width: 100%;
    border-right: 1px solid #fff;
    background-color: #005d89;
    color: white;
}

.before-color{
  color: white;
}
.after-color{
  color: black;
}
.div-status .proposal-SO-btn{
  border: 8px solid #f0f0f0;
  width: 233px;
  position: relative;
  right: 57px
}
.div-status .proposal-row-SO-css{
  display: flex;
  justify-content: space-between;
}

.inline-checks > .ant-picker.active {
  border-color: #ff7734;
  background: #fff;
}