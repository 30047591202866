@media screen and (min-width: 768px) {
    .addNewCustomerModal {
        width: 550px !important;
        min-width: 550px !important;
        max-width: 550px !important;
        margin: unset;
        left: calc((100vw - 550px)/2);
        right: calc((100vw - 550px)/2);
    }
    
    .addNewCustomerModal .ant-modal-content {
        margin: unset;
        width: unset;
    }
}