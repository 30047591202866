@media screen and (min-width: 768px) {
    .addNewTaskModal {
        
        width: clamp(50%, calc(100vw - 330px), 600px);
        min-width: clamp(50%, calc(100vw - 330px), 600px);
        max-width: clamp(50%, calc(100vw - 330px), 600px);
        margin: unset;
        left: 50%;
        margin-left:-25%;
    }

    .addNewTaskModal .ant-modal-content {
        margin-top: unset;
        width: unset;
    }

    .jobPopAddress {
        width:100%;
        margin-top: 10px;
    }

    .taskCustomer {        
        margin-top: 10px;
    }

    .taskCustomer>.customerDetails>.address>label,
    .taskCustomer>.customerDetails>.email-id>label {
        font-size: 11px;
        font-weight: normal;
        width: 100%;
    }

    .taskErrorMessage {
        text-align: center;
    }  
    
}
.taskTimePicker {
    width:44%;
    margin-left: "5px";
    background-color: #fff;
}

.taskDatePicker {
    width: 50%;
    margin-right: "5px";
}