

.success_bg {
    background-color: green;
}

/* Login Register */
.auth_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;


}

.auth_wrapper>form {
    width: 100%;
}






/* // loader */

.loader_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #297c785c;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000000;
}

.loader07 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    top: 50%;
    margin: -8px auto 0;
}

@-webkit-keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #f99d20, 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.5), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.7), 1.8em -1.8em 0 0em #f99d20,
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.5), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.7),
            2.5em 0em 0 0em #f99d20, 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.5),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.7), 1.75em 1.75em 0 0em #f99d20,
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.5), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.7),
            0em 2.5em 0 0em #f99d20, -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.5),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.7), -1.8em 1.8em 0 0em #f99d20,
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.5), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.7),
            -2.6em 0em 0 0em #f99d20, -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.5),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.7), -1.8em -1.8em 0 0em #f99d20;
    }
}

@keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #f99d20, 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.5), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.7), 1.8em -1.8em 0 0em #f99d20,
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.5), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.7),
            2.5em 0em 0 0em #f99d20, 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.5),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.7), 1.75em 1.75em 0 0em #f99d20,
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.5), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.7),
            0em 2.5em 0 0em #f99d20, -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.2),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.5),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.7), -1.8em 1.8em 0 0em #f99d20,
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.2), -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.5), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.7),
            -2.6em 0em 0 0em #f99d20, -1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(249, 157, 32, 0.2), 1.8em -1.8em 0 0em rgba(249, 157, 32, 0.2),
            2.5em 0em 0 0em rgba(249, 157, 32, 0.2), 1.75em 1.75em 0 0em rgba(249, 157, 32, 0.2),
            0em 2.5em 0 0em rgba(249, 157, 32, 0.2), -1.8em 1.8em 0 0em rgba(249, 157, 32, 0.5),
            -2.6em 0em 0 0em rgba(249, 157, 32, 0.7), -1.8em -1.8em 0 0em #f99d20;
    }
}


.image-input { position: relative; width:240px; }
.image-input input { border:none; background-color:#fff; display:block; width: 100%; box-sizing: border-box }
.image-input img { position: absolute; top: 20px; right: 10px }
.type-border{ border: 2px solid #407977 !important; width:174px !important}
.type-body{width: 170px !important;}
.type-body {
    max-height: 125px !important;
}