@charset "UTF-8";
/* CSS Document */
/* Table of Contents
-----------------------------------------------------------------------------
  1. Clean Base
  2. Base Typography
  3. Images
  4. Links
  5. Forms
  6. Tables
  7. Framework
*/
/* 1. Clean Base  
------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
a,
nav,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  list-style: none;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

img {
  border: none;
}

input[type="text"],
input[type="number"],
input[type="submit"],
input[type="button"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  outline: none;
}

textarea:focus,
input:focus,
a,
div,
img {
  outline: none;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.display-block {
  display: block;
}

.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* 2. Base Typography
------------------------------------------------------------------------------*/
body {
  color: #1c1d21 !important;
  font-size: 13px !important;
  font-family: "Lato" !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  background: #f4f7f9 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-weight: 700;
  color: #1c1d21;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 42px;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 32px;
}

h3,
.h3 {
  font-size: 18px;
  line-height: 21px;
}

h4,
.h4 {
  font-size: 16px;
  line-height: 19px;
}

h5,
.h5 {
  font-size: 15px;
  line-height: 19px;
}

h6,
.h6 {
  font-size: 14px;
  line-height: 18px;
}

p {
  color: #1c1d21;
  font-weight: normal;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;
}

.smhead {
  color: #2b2b38;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.xshead {
  font-size: 12px;
}

ul {
  margin: 0px;
  padding: 0;
}

ul li {
  font-size: 14px;
  color: #1c1d21;
  font-weight: normal;
  padding: 0 0 5px 0;
  list-style: none;
}

ol {
  margin: 20px 20px;
  padding: 0;
}

ol li {
  font-size: 14px;
  color: #1c1d21;
  font-weight: normal;
  padding: 0 0 5px 0;
  list-style: decimal;
  margin-left: 20px;
}

b,
strong {
  font-weight: bold;
}

hr {
  border: 0 #000 solid;
  border-top-width: 1px;
  clear: both;
  height: 0;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-10 {
  margin-left: 10px;
}

/* 3. Images
------------------------------------------------------------------------------*/
img {
  border: none;
  border-style: none;
  max-width: 100%;
}

/* 4. Link
------------------------------------------------------------------------------*/
a {
  color: #3fbee5;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover {
  color: #000;
  text-decoration: none;
}

a:active,
a.current {
  color: #000;
}

a:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.secondary-link {
  color: #303130;
  font-weight: bold;
  margin-bottom: 20px;
}

.primary-link {
  color: rgb(0, 93, 137);
  font-weight: bold;
  cursor: pointer;
}

.primary-link:hover,
.primary-link:focus {
  color: #1c1d21;
}

img .signature {
  background-color: #f4f7f9;
}

/* 5. Forms
------------------------------------------------------------------------------*/

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="date"],
.form-control,
.form--control,
.ant-input-number .ant-input-number-input {
  border: none;
  border: 1px solid #f4f7f9;
  padding: 8px 16px;
  color: #1c1d21;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  height: auto;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #f4f7f9;
  line-height: 24px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control,
input[type="tel"].form-control,
input[type="search"].form-control,
.form-control.form-control,
.form--control.form-control,
.ant-input-number .ant-input-number-input.form-control {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #ffffff;
  background-image: none !important;
}

input[type="text"].form--control,
input[type="password"].form--control,
input[type="email"].form--control,
input[type="tel"].form--control,
input[type="search"].form--control,
.form-control.form--control,
.form--control.form--control,
.ant-input-number .ant-input-number-input.form--control {
  height: 42px;
}

input[type="text"].bdr-control,
input[type="password"].bdr-control,
input[type="email"].bdr-control,
input[type="tel"].bdr-control,
input[type="search"].bdr-control,
.form-control.bdr-control,
.form--control.bdr-control,
.ant-input-number .ant-input-number-input.bdr-control {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background-color: #ffffff;
}

input[type="text"].plain-control,
input[type="password"].plain-control,
input[type="email"].plain-control,
input[type="tel"].plain-control,
input[type="search"].plain-control,
.form-control.plain-control,
.form--control.plain-control,
.ant-input-number .ant-input-number-input.plain-control {
  border: none;
  background: none;
}

input[type="text"].w-100 {
  width: 100% !important;
}

.bdr-control {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background-color: #ffffff;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
textarea:focus,
.from-control,
.bdr-control:focus,
.ant-input-number-focused .ant-input-number-input {
  border-color: rgb(0, 93, 137);
}

textarea {
  border: 1px solid #f4f7f9;
  padding: 8px 16px;
  color: #1c1d21;
  background-color: #f4f7f9 !important;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  height: auto;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #f4f7f9;
  line-height: 24px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-height: 70px;
  resize: none;
}

.btn,
button[type="button"],
button[type="submit"],
.submit,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  background-color: rgb(0, 93, 137);
  cursor: pointer;
  color: #fff;
  height: auto;
  line-height: 18px;
  display: inline-block;
  font-size: 13px;
  padding: 8px 25px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: capitalize;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
  font-family: "Lato", sans-serif;
  border-radius: 3px;
  border: 1px solid rgb(0, 93, 137);
}

.btn.plain-btn,
button[type="button"].plain-btn,
button[type="submit"].plain-btn,
.submit.plain-btn,
input[type="submit"].plain-btn,
input[type="button"].plain-btn,
input[type="reset"].plain-btn {
  background: transparent;
  padding: 0;
  border: none;
}

.btn.plain-btn:hover,
.btn.plain-btn:focus,
button[type="button"].plain-btn:hover,
button[type="button"].plain-btn:focus,
button[type="submit"].plain-btn:hover,
button[type="submit"].plain-btn:focus,
.submit.plain-btn:hover,
.submit.plain-btn:focus,
input[type="submit"].plain-btn:hover,
input[type="submit"].plain-btn:focus,
input[type="button"].plain-btn:hover,
input[type="button"].plain-btn:focus,
input[type="reset"].plain-btn:hover,
input[type="reset"].plain-btn:focus {
  background-color: transparent;
  border: none;
  color: #000;
}

.btn.plain-btn.ant-btn-primary:hover,
.btn.plain-btn.ant-btn-primary:focus,
button[type="button"].plain-btn.ant-btn-primary:hover,
button[type="button"].plain-btn.ant-btn-primary:focus,
button[type="submit"].plain-btn.ant-btn-primary:hover,
button[type="submit"].plain-btn.ant-btn-primary:focus,
.submit.plain-btn.ant-btn-primary:hover,
.submit.plain-btn.ant-btn-primary:focus,
input[type="submit"].plain-btn.ant-btn-primary:hover,
input[type="submit"].plain-btn.ant-btn-primary:focus,
input[type="button"].plain-btn.ant-btn-primary:hover,
input[type="button"].plain-btn.ant-btn-primary:focus,
input[type="reset"].plain-btn.ant-btn-primary:hover,
input[type="reset"].plain-btn.ant-btn-primary:focus {
  background-color: transparent;
  border: none;
}

.btn.plain-btn.danger,
button[type="button"].plain-btn.danger,
button[type="submit"].plain-btn.danger,
.submit.plain-btn.danger,
input[type="submit"].plain-btn.danger,
input[type="button"].plain-btn.danger,
input[type="reset"].plain-btn.danger {
  color: #d12619 !important;
}

.btn i,
button[type="button"] i,
button[type="submit"] i,
.submit i,
input[type="submit"] i,
input[type="button"] i,
input[type="reset"] i {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}

.btn.btn-lg,
button[type="button"].btn-lg,
button[type="submit"].btn-lg,
.submit.btn-lg,
input[type="submit"].btn-lg,
input[type="button"].btn-lg,
input[type="reset"].btn-lg {
  padding: 10px 15px;
}

.btn.btn-gray,
button[type="button"].btn-gray,
button[type="submit"].btn-gray,
.submit.btn-gray,
input[type="submit"].btn-gray,
input[type="button"].btn-gray,
input[type="reset"].btn-gray {
  background-color: #f0f0f3;
  color: #6f6f6f;
  border-color: transparent;
}

.btn.btn-full,
button[type="button"].btn-full,
button[type="submit"].btn-full,
.submit.btn-full,
input[type="submit"].btn-full,
input[type="button"].btn-full,
input[type="reset"].btn-full {
  width: 100%;
  display: block;
  min-width: 100px;
  margin: 0 auto;
}

.btn.btn-full + .btn-full,
button[type="button"].btn-full + .btn-full,
button[type="submit"].btn-full + .btn-full,
.submit.btn-full + .btn-full,
input[type="submit"].btn-full + .btn-full,
input[type="button"].btn-full + .btn-full,
input[type="reset"].btn-full + .btn-full {
  margin-top: 10px;
}

.btn.btn-half,
button[type="button"].btn-half,
button[type="submit"].btn-half,
.submit.btn-half,
input[type="submit"].btn-half,
input[type="button"].btn-half,
input[type="reset"].btn-half {
  width: 50%;
}

.btn.btn-half:last-child,
button[type="button"].btn-half:last-child,
button[type="submit"].btn-half:last-child,
.submit.btn-half:last-child,
input[type="submit"].btn-half:last-child,
input[type="button"].btn-half:last-child,
input[type="reset"].btn-half:last-child {
  margin: 0;
  margin-left: 10px;
}

.btn.btn-half:first-child,
button[type="button"].btn-half:first-child,
button[type="submit"].btn-half:first-child,
.submit.btn-half:first-child,
input[type="submit"].btn-half:first-child,
input[type="button"].btn-half:first-child,
input[type="reset"].btn-half:first-child {
  margin-right: 10px;
}

.btn + .btn,
button[type="button"] + .btn,
button[type="submit"] + .btn,
.submit + .btn,
input[type="submit"] + .btn,
input[type="button"] + .btn,
input[type="reset"] + .btn {
  margin-left: 10px;
}

.btn.btn-primary,
.btn.ant-btn-link,
.btn.ant-btn-primary,
button[type="button"].btn-primary,
button[type="button"].ant-btn-link,
button[type="button"].ant-btn-primary,
button[type="submit"].btn-primary,
button[type="submit"].ant-btn-link,
button[type="submit"].ant-btn-primary,
.submit.btn-primary,
.submit.ant-btn-link,
.submit.ant-btn-primary,
input[type="submit"].btn-primary,
input[type="submit"].ant-btn-link,
input[type="submit"].ant-btn-primary,
input[type="button"].btn-primary,
input[type="button"].ant-btn-link,
input[type="button"].ant-btn-primary,
input[type="reset"].btn-primary,
input[type="reset"].ant-btn-link,
input[type="reset"].ant-btn-primary {
  background-color: rgb(0, 93, 137);
  color: #fff;
}

.btn.btn-primary.btn-bdr,
.btn.ant-btn-link.btn-bdr,
.btn.ant-btn-primary.btn-bdr,
button[type="button"].btn-primary.btn-bdr,
button[type="button"].ant-btn-link.btn-bdr,
button[type="button"].ant-btn-primary.btn-bdr,
button[type="submit"].btn-primary.btn-bdr,
button[type="submit"].ant-btn-link.btn-bdr,
button[type="submit"].ant-btn-primary.btn-bdr,
.submit.btn-primary.btn-bdr,
.submit.ant-btn-link.btn-bdr,
.submit.ant-btn-primary.btn-bdr,
input[type="submit"].btn-primary.btn-bdr,
input[type="submit"].ant-btn-link.btn-bdr,
input[type="submit"].ant-btn-primary.btn-bdr,
input[type="button"].btn-primary.btn-bdr,
input[type="button"].ant-btn-link.btn-bdr,
input[type="button"].ant-btn-primary.btn-bdr,
input[type="reset"].btn-primary.btn-bdr,
input[type="reset"].ant-btn-link.btn-bdr,
input[type="reset"].ant-btn-primary.btn-bdr {
  color: rgb(0, 93, 137);
}

.btn.btn-primary:focus,
.btn.btn-primary:hover,
.btn.ant-btn-link:focus,
.btn.ant-btn-link:hover,
.btn.ant-btn-primary:focus,
.btn.ant-btn-primary:hover,
button[type="button"].btn-primary:focus,
button[type="button"].btn-primary:hover,
button[type="button"].ant-btn-link:focus,
button[type="button"].ant-btn-link:hover,
button[type="button"].ant-btn-primary:focus,
button[type="button"].ant-btn-primary:hover,
button[type="submit"].btn-primary:focus,
button[type="submit"].btn-primary:hover,
button[type="submit"].ant-btn-link:focus,
button[type="submit"].ant-btn-link:hover,
button[type="submit"].ant-btn-primary:focus,
button[type="submit"].ant-btn-primary:hover,
.submit.btn-primary:focus,
.submit.btn-primary:hover,
.submit.ant-btn-link:focus,
.submit.ant-btn-link:hover,
.submit.ant-btn-primary:focus,
.submit.ant-btn-primary:hover,
input[type="submit"].btn-primary:focus,
input[type="submit"].btn-primary:hover,
input[type="submit"].ant-btn-link:focus,
input[type="submit"].ant-btn-link:hover,
input[type="submit"].ant-btn-primary:focus,
input[type="submit"].ant-btn-primary:hover,
input[type="button"].btn-primary:focus,
input[type="button"].btn-primary:hover,
input[type="button"].ant-btn-link:focus,
input[type="button"].ant-btn-link:hover,
input[type="button"].ant-btn-primary:focus,
input[type="button"].ant-btn-primary:hover,
input[type="reset"].btn-primary:focus,
input[type="reset"].btn-primary:hover,
input[type="reset"].ant-btn-link:focus,
input[type="reset"].ant-btn-link:hover,
input[type="reset"].ant-btn-primary:focus,
input[type="reset"].ant-btn-primary:hover {
  background-color: #4c6a6a;
}

.btn.disabled,
button[type="button"].disabled,
button[type="submit"].disabled,
.submit.disabled,
input[type="submit"].disabled,
input[type="button"].disabled,
input[type="reset"].disabled {
  background: #8a8886;
  color: #fff;
  border-color: #f0f0f3;
  cursor: not-allowed;
}

.btn.btn-disabled,
button[type="button"].btn-disabled,
button[type="submit"].btn-disabled,
.submit.btn-disabled,
input[type="submit"].btn-disabled,
input[type="button"].btn-disabled,
input[type="reset"].btn-disabled {
  /* background: #ff681d;
  color: #fff; */
  background: #f0f0f3;
  color: #9494a5;
  border-color: #f0f0f3;
}

.btn.btn-warning,
button[type="button"].btn-warning,
button[type="submit"].btn-warning,
.submit.btn-warning,
input[type="submit"].btn-warning,
input[type="button"].btn-warning,
input[type="reset"].btn-warning {
  background-color: #ff681d;
  color: #fff;
  border-color: #ff681d;
}

.btn.btn-warning.btn-bdr,
button[type="button"].btn-warning.btn-bdr,
button[type="submit"].btn-warning.btn-bdr,
.submit.btn-warning.btn-bdr,
input[type="submit"].btn-warning.btn-bdr,
input[type="button"].btn-warning.btn-bdr,
input[type="reset"].btn-warning.btn-bdr {
  color: #ff681d;
}

.btn.btn-warning:focus,
.btn.btn-warning:hover,
button[type="button"].btn-warning:focus,
button[type="button"].btn-warning:hover,
button[type="submit"].btn-warning:focus,
button[type="submit"].btn-warning:hover,
.submit.btn-warning:focus,
.submit.btn-warning:hover,
input[type="submit"].btn-warning:focus,
input[type="submit"].btn-warning:hover,
input[type="button"].btn-warning:focus,
input[type="button"].btn-warning:hover,
input[type="reset"].btn-warning:focus,
input[type="reset"].btn-warning:hover {
  background-color: #ff681d;
}

.btn.btn-danger,
button[type="button"].btn-danger,
button[type="submit"].btn-danger,
.submit.btn-danger,
input[type="submit"].btn-danger,
input[type="button"].btn-danger,
input[type="reset"].btn-danger {
  background-color: #e02020;
  color: #fff;
  border-color: #e02020;
}

.btn.btn-danger.btn-bdr,
button[type="button"].btn-danger.btn-bdr,
button[type="submit"].btn-danger.btn-bdr,
.submit.btn-danger.btn-bdr,
input[type="submit"].btn-danger.btn-bdr,
input[type="button"].btn-danger.btn-bdr,
input[type="reset"].btn-danger.btn-bdr {
  color: #d12619;
}

.btn.btn-danger:focus,
.btn.btn-danger:hover,
button[type="button"].btn-danger:focus,
button[type="button"].btn-danger:hover,
button[type="submit"].btn-danger:focus,
button[type="submit"].btn-danger:hover,
.submit.btn-danger:focus,
.submit.btn-danger:hover,
input[type="submit"].btn-danger:focus,
input[type="submit"].btn-danger:hover,
input[type="button"].btn-danger:focus,
input[type="button"].btn-danger:hover,
input[type="reset"].btn-danger:focus,
input[type="reset"].btn-danger:hover {
  background-color: #b41919;
}

.btn.btn-success,
button[type="button"].btn-success,
button[type="submit"].btn-success,
.submit.btn-success,
input[type="submit"].btn-success,
input[type="button"].btn-success,
input[type="reset"].btn-success {
  background-color: #3ba93f;
  color: #fff;
  border-color: #3ba93f;
}

.btn.btn-success.btn-bdr,
button[type="button"].btn-success.btn-bdr,
button[type="submit"].btn-success.btn-bdr,
.submit.btn-success.btn-bdr,
input[type="submit"].btn-success.btn-bdr,
input[type="button"].btn-success.btn-bdr,
input[type="reset"].btn-success.btn-bdr {
  color: #3ba93f;
}

.btn.btn-success:focus,
.btn.btn-success:hover,
button[type="button"].btn-success:focus,
button[type="button"].btn-success:hover,
button[type="submit"].btn-success:focus,
button[type="submit"].btn-success:hover,
.submit.btn-success:focus,
.submit.btn-success:hover,
input[type="submit"].btn-success:focus,
input[type="submit"].btn-success:hover,
input[type="button"].btn-success:focus,
input[type="button"].btn-success:hover,
input[type="reset"].btn-success:focus,
input[type="reset"].btn-success:hover {
  background-color: #2e8331;
}

.btn.btn-bdr,
button[type="button"].btn-bdr,
button[type="submit"].btn-bdr,
.submit.btn-bdr,
input[type="submit"].btn-bdr,
input[type="button"].btn-bdr,
input[type="reset"].btn-bdr {
  background-color: #ff681d;
  color: #fff;
}

.btn.btn-bdr.updateLeadButton {
  padding: 8px 15px;
}

.btn.btn-bdr.addLeadButton {
  padding: 8px 22px;
}

.btn.btn-bdr:hover,
.btn.btn-bdr:focus,
button[type="button"].btn-bdr:hover,
button[type="button"].btn-bdr:focus,
button[type="submit"].btn-bdr:hover,
button[type="submit"].btn-bdr:focus,
.submit.btn-bdr:hover,
.submit.btn-bdr:focus,
input[type="submit"].btn-bdr:hover,
input[type="submit"].btn-bdr:focus,
input[type="button"].btn-bdr:hover,
input[type="button"].btn-bdr:focus,
input[type="reset"].btn-bdr:hover,
input[type="reset"].btn-bdr:focus {
  background-color: #fff;
  color: #000;
}

.btn.btn-upload {
  background-color: #fff;
  color: #000;
  border: 1px solid #ff681d;
}

.btn.btn-upload:hover {
  background-color: #ff681d;
  color: #fff;
  border: 1px solid #000;
}

.btn.btn-save-add,
button[type="button"].btn-save-add,
button[type="submit"].btn-save-add,
.submit.btn-save-add,
input[type="submit"].btn-save-add,
input[type="button"].btn-save-add,
input[type="reset"].btn-save-add {
  background-color: #fff1ea;
  color: #000;
  border: 1px solid #ff681d;
}

.btn.btn-save-add:hover,
.btn.btn-save-add:focus,
button[type="button"].btn-save-add:hover,
button[type="button"].btn-save-add:focus,
button[type="submit"].btn-save-add:hover,
button[type="submit"].btn-save-add:focus,
.submit.btn-save-add:hover,
.submit.btn-save-add:focus,
input[type="submit"].btn-save-add:hover,
input[type="submit"].btn-save-add:focus,
input[type="button"].btn-save-add:hover,
input[type="button"].btn-save-add:focus,
input[type="reset"].btn-save-add:hover,
input[type="reset"].btn-save-add:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.btn.btn-txt,
button[type="button"].btn-txt,
button[type="submit"].btn-txt,
.submit.btn-txt,
input[type="submit"].btn-txt,
input[type="button"].btn-txt,
input[type="reset"].btn-txt {
  color: rgb(0, 93, 137);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-transform: none;
  margin: 0 auto;
}

.btn.btn-txt:hover,
.btn.btn-txt:focus,
button[type="button"].btn-txt:hover,
button[type="button"].btn-txt:focus,
button[type="submit"].btn-txt:hover,
button[type="submit"].btn-txt:focus,
.submit.btn-txt:hover,
.submit.btn-txt:focus,
input[type="submit"].btn-txt:hover,
input[type="submit"].btn-txt:focus,
input[type="button"].btn-txt:hover,
input[type="button"].btn-txt:focus,
input[type="reset"].btn-txt:hover,
input[type="reset"].btn-txt:focus {
  color: #1c1d21;
}

.btn.btn-txt.btn-full,
button[type="button"].btn-txt.btn-full,
button[type="submit"].btn-txt.btn-full,
.submit.btn-txt.btn-full,
input[type="submit"].btn-txt.btn-full,
input[type="button"].btn-txt.btn-full,
input[type="reset"].btn-txt.btn-full {
  display: block;
  margin: 0 auto;
}

.btn.btn-txt.disabled,
button[type="button"].btn-txt.disabled,
button[type="submit"].btn-txt.disabled,
.submit.btn-txt.disabled,
input[type="submit"].btn-txt.disabled,
input[type="button"].btn-txt.disabled,
input[type="reset"].btn-txt.disabled {
  color: #6f6f6f;
}

.btn.add-btn,
button[type="button"].add-btn,
button[type="submit"].add-btn,
.submit.add-btn,
input[type="submit"].add-btn,
input[type="button"].add-btn,
input[type="reset"].add-btn {
  background: #ff681d;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
  font-size: 21px;
  font-weight: bold;
}

.btn.add-btn:hover,
.btn.add-btn:focus,
button[type="button"].add-btn:hover,
button[type="button"].add-btn:focus,
button[type="submit"].add-btn:hover,
button[type="submit"].add-btn:focus,
.submit.add-btn:hover,
.submit.add-btn:focus,
input[type="submit"].add-btn:hover,
input[type="submit"].add-btn:focus,
input[type="button"].add-btn:hover,
input[type="button"].add-btn:focus,
input[type="reset"].add-btn:hover,
input[type="reset"].add-btn:focus {
  background: rgb(0, 93, 137);
}

.btn.btn-xs,
button[type="button"].btn-xs,
button[type="submit"].btn-xs,
.submit.btn-xs,
input[type="submit"].btn-xs,
input[type="button"].btn-xs,
input[type="reset"].btn-xs {
  padding: 9px 10px;
}

.btn.xs-btn,
button[type="button"].xs-btn,
button[type="submit"].xs-btn,
.submit.xs-btn,
input[type="submit"].xs-btn,
input[type="button"].xs-btn,
input[type="reset"].xs-btn {
  padding: 3px 10px;
}

.btn.btn-white,
button[type="button"].btn-white,
button[type="submit"].btn-white,
.submit.btn-white,
input[type="submit"].btn-white,
input[type="button"].btn-white,
input[type="reset"].btn-white {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.btn.btn-white:focus,
.btn.btn-white:hover,
button[type="button"].btn-white:focus,
button[type="button"].btn-white:hover,
button[type="submit"].btn-white:focus,
button[type="submit"].btn-white:hover,
.submit.btn-white:focus,
.submit.btn-white:hover,
input[type="submit"].btn-white:focus,
input[type="submit"].btn-white:hover,
input[type="button"].btn-white:focus,
input[type="button"].btn-white:hover,
input[type="reset"].btn-white:focus,
input[type="reset"].btn-white:hover {
  background-color: #000;
}

.btn.btn-transparent,
button[type="button"].btn-transparent,
button[type="submit"].btn-transparent,
.submit.btn-transparent,
input[type="submit"].btn-transparent,
input[type="button"].btn-transparent,
input[type="reset"].btn-transparent {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #efeff8;
}

.btn.btn-transparent:focus,
.btn.btn-transparent:hover,
button[type="button"].btn-transparent:focus,
button[type="button"].btn-transparent:hover,
button[type="submit"].btn-transparent:focus,
button[type="submit"].btn-transparent:hover,
.submit.btn-transparent:focus,
.submit.btn-transparent:hover,
input[type="submit"].btn-transparent:focus,
input[type="submit"].btn-transparent:hover,
input[type="button"].btn-transparent:focus,
input[type="button"].btn-transparent:hover,
input[type="reset"].btn-transparent:focus,
input[type="reset"].btn-transparent:hover {
  background-color: #ff681d;
}

.btn.btn-fltr {
  background-color: #efeff8;
  color: #ff681d;
  border: 2px solid #ff681d;
  width: 50px;
  padding: 0;
  font-size: 14px;
}

.btn.btn-border-blue {
  background-color: #efeff8;
  color: #2c3d53;
  border: 1px solid #2c3d53;
  width: 44%;
  justify-self: right;
}

.btn.btn-border,
button[type="button"].btn-border,
button[type="submit"].btn-border,
.submit.btn-border,
input[type="submit"].btn-border,
input[type="button"].btn-border,
input[type="reset"].btn-border {
  background-color: #efeff8;
  color: #000;
  border: 1px solid #ff681d;
}

.btn.btn-border:focus,
.btn.btn-border:hover,
button[type="button"].btn-border:focus,
button[type="button"].btn-border:hover,
button[type="submit"].btn-border:focus,
button[type="submit"].btn-border:hover,
.submit.btn-border:focus,
.submit.btn-border:hover,
input[type="submit"].btn-border:focus,
input[type="submit"].btn-border:hover,
input[type="button"].btn-border:focus,
input[type="button"].btn-border:hover,
input[type="reset"].btn-border:focus,
input[type="reset"].btn-border:hover {
  background-color: #ff681d;
}

.btn.btn-white-background,
button[type="button"].btn-white-background,
button[type="submit"].btn-white-background,
.submit.btn-white-background,
input[type="submit"].btn-white-background,
input[type="button"].btn-white-background,
input[type="reset"].btn-white-background {
  background-color: #fff;
  color: #000;
  border: 1px solid #efeff8;
}

.btn.btn-white-background:focus,
.btn.btn-white-background:hover,
button[type="button"].btn-white-background:focus,
button[type="button"].btn-white-background:hover,
button[type="submit"].btn-white-background:focus,
button[type="submit"].btn-white-background:hover,
.submit.btn-white-background:focus,
.submit.btn-white-background:hover,
input[type="submit"].btn-white-background:focus,
input[type="submit"].btn-white-background:hover,
input[type="button"].btn-white-background:focus,
input[type="button"].btn-white-background:hover,
input[type="reset"].btn-white-background:focus,
input[type="reset"].btn-white-background:hover {
  background-color: #ff681d;
}

.fix-btn-grp {
  background-color: #212121;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 80px);
  text-align: right;
  padding: 15px 30px;
  z-index: 9;
}

.fix-btn-grp-form {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 80px);
  text-align: right;
  padding: 15px 30px;
  z-index: 9;
}

input[type="submit"]:focus,
input[type="button"]:focus,
button[type="button"]:hover,
button[type="submit"]:hover,
.submit:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  border-color: rgb(0, 93, 137);
}

/*background:$input-bg url(assets/images/chevron-down.png);*/
select {
  background-size: 11px auto;
  background-position: 99% center;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 16px;
  min-width: 150px;
  border: 1px solid #f4f7f9;
  font-size: 14px;
  padding-right: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  line-height: 24px;
  color: #1c1d21;
  font-weight: 400;
  width: 100%;
  font-family: "Lato", sans-serif;
}

select:focus {
  border-color: rgb(0, 93, 137);
}

label {
  color: #000;
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  display: inline-block;
  font-weight: 500;
  line-height: 19px;
}

label.error-color {
  padding-right: 4px;
}

label.error-color:after {
  content: "*";
  color: #d12619;
  display: inline-block;
  vertical-align: top;
}

label.error {
  color: #d12619;
  text-transform: none;
  font-weight: 400;
  margin-top: 4px;
}

label.validate-message {
  color: #3ba93f;
  text-transform: none;
  font-weight: 400;
}

label.spl {
  color: #22222a;
}

.txt-none {
  text-transform: none;
}

.form-group .fitem {
  padding: 0 0 0;
  display: block;
  margin-bottom: 10px;
}

.form-group .fitem-half-space {
  margin-bottom: 15px;
}

.form-group .full-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  margin: 0;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  width: 100%;
}

.form-group .full-item textarea,
.form-group .full-item input,
.form-group .full-item select {
  margin: 0;
  width: 100%;
}

.form-group .full-item label {
  margin: 0;
}

.form-group .half-fitem {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.pool-field-width {
  width: 270px !important;
}

.pool-width-misc-field {
  width: 565px !important;
}

.pool-width-date-field {
  width: 18% !important;
}

.pool-width-serial-field {
  width: 17% !important;
}

.form-group .half-fitem .half-fitem {
  width: 50%;
  display: block;
  margin: 0 5px;
}

.form-group .half-fitem .half-fitem:first-child {
  margin-left: 0;
}

.form-group .half-fitem .half-fitem:last-child {
  margin-right: 0;
}

.fitem-ricon-phone {
  position: relative;
  margin-top: 2px;
  margin-right: -25px;
  padding: 5px 9px;
  text-align: center;
}

.fitem-rinner {
  position: relative;
}

.fitem-rinner input {
  padding-right: 30px;
}

.fitem-rinner .fitem-ricon {
  position: absolute;
  right: 10px;
  max-width: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.fitem-rinner .fitem-ricon img {
  display: block;
}

.fitem-rinner .fitem-ricon.btn {
  max-width: 32px;
  width: 32px;
  padding: 5px 5px;
  text-align: center;
}

.fitem-rinner.licon .fitem-ricon {
  right: auto;
  left: 15px;
}

.fitem-rinner.licon input {
  padding-left: 45px;
}

.fitem-rinner.licon.search_box {
  max-width: 300px !important;
  display: flex;
}

.close {
  position: absolute;
  right: 0px;
  max-width: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.frachise_border {
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.fitem.plain-control input[type="text"],
.fitem.plain-control input[type="password"],
.fitem.plain-control input[type="email"],
.fitem.plain-control input[type="tel"],
.fitem.plain-control input[type="search"],
.fitem.plain-control .from-control,
.fitem.plain-control textarea,
.fitem.plain-control select {
  border: none;
  background: transparent;
  border-radius: 0;
}

.fitem.plain-control input[type="text"]:hover,
.fitem.plain-control input[type="text"]:focus,
.fitem.plain-control input[type="password"]:hover,
.fitem.plain-control input[type="password"]:focus,
.fitem.plain-control input[type="email"]:hover,
.fitem.plain-control input[type="email"]:focus,
.fitem.plain-control input[type="tel"]:hover,
.fitem.plain-control input[type="tel"]:focus,
.fitem.plain-control input[type="search"]:hover,
.fitem.plain-control input[type="search"]:focus,
.fitem.plain-control .from-control:hover,
.fitem.plain-control .from-control:focus,
.fitem.plain-control textarea:hover,
.fitem.plain-control textarea:focus,
.fitem.plain-control select:hover,
.fitem.plain-control select:focus {
  border: none;
}

.fitem.grey .ant-picker {
  background-color: #f4f4f4;
  border-radius: 0;
}

.fitem.grey
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f4f4f4;
  border-radius: 0;
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: rgba(28, 29, 33, 0.5);
  opacity: 1;
  font-weight: 700;
}

select:-moz-placeholder,
textarea:-moz-placeholder,
input:-moz-placeholder {
  color: rgba(28, 29, 33, 0.5);
  opacity: 1;
  font-weight: 700;
}

select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: rgba(28, 29, 33, 0.5);
  opacity: 1;
  font-weight: 700;
}

select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: rgba(28, 29, 33, 0.5);
  opacity: 1;
  font-weight: 700;
}

select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
  color: rgba(28, 29, 33, 0.5);
  opacity: 1;
  font-weight: 700;
}

[class*="inline-col"] {
  margin: 0;
}

.inline-col-2 {
  width: 20%;
}

.inline-col-3 {
  width: 30%;
}

.inline-col-4 {
  width: 40%;
}

.inline-col-5 {
  width: 50%;
}

.inline-col-6 {
  width: 60%;
}

.inline-col-7 {
  width: 70%;
}

.inline-col-8 {
  width: 80%;
}

.check-list.half-fitem {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.check-list.half-fitem .fitem-check {
  width: auto;
  max-width: 200px;
  display: block;
  margin: 0;
}

.check-list .fitem-check {
  position: relative;
  z-index: 1;
  display: block;
}

.check-list .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}

.fitem-check {
  position: relative;
  z-index: 1;
}

.fitem-check label {
  margin: 0;
}

.fitem-check input.fitem-ck-input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.fitem-check input.fitem-ck-input[type="radio"] ~ .fitem-ck-txt {
  color: #6f6f6f;
  font-weight: 600;
  text-transform: none;
  font-size: 13px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  line-height: 24px;
}

.fitem-check input.fitem-ck-input[type="radio"] ~ .fitem-ck-txt:before {
  position: absolute;
  border: 1px solid #005d89;
  background-color: #fff;
  content: "";
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.fitem-check input.fitem-ck-input[type="radio"] ~ .fitem-ck-txt:after {
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
  left: 7px;
  top: 4px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fitem-check input.fitem-ck-input[type="radio"].is-checked ~ .fitem-ck-txt,
.fitem-check input.fitem-ck-input[type="radio"]:checked ~ .fitem-ck-txt,
.fitem-check
  input.fitem-ck-input[type="radio"].fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt {
  color: #1c1d21;
}

.fitem-check
  input.fitem-ck-input[type="radio"].is-checked
  ~ .fitem-ck-txt:before,
.fitem-check input.fitem-ck-input[type="radio"]:checked ~ .fitem-ck-txt:before,
.fitem-check
  input.fitem-ck-input[type="radio"].fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:before {
  background-color: #005d89;
}

.fitem-check
  input.fitem-ck-input[type="radio"].is-checked
  ~ .fitem-ck-txt:after,
.fitem-check input.fitem-ck-input[type="radio"]:checked ~ .fitem-ck-txt:after,
.fitem-check
  input.fitem-ck-input[type="radio"].fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:after {
  opacity: 1;
}

.fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  color: #6f6f6f;
  font-weight: 600;
  text-transform: none;
  font-size: 13px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  line-height: 24px;
}

.fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  position: absolute;
  border: none;
  background-color: #f0f0f3;
  content: "";
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.fitem-check input.fitem-ck-input ~ .fitem-ck-txt:after {
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
  left: 7px;
  top: 4px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fitem-check input.fitem-ck-input.is-checked ~ .fitem-ck-txt,
.fitem-check input.fitem-ck-input:checked ~ .fitem-ck-txt,
.fitem-check
  input.fitem-ck-input.fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt {
  color: #1c1d21;
}

.fitem-check input.fitem-ck-input.is-checked ~ .fitem-ck-txt:before,
.fitem-check input.fitem-ck-input:checked ~ .fitem-ck-txt:before,
.fitem-check
  input.fitem-ck-input.fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:before {
  background-color: #005d89;
}

.fitem-check input.fitem-ck-input.is-checked ~ .fitem-ck-txt:after,
.fitem-check input.fitem-ck-input:checked ~ .fitem-ck-txt:after,
.fitem-check
  input.fitem-ck-input.fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:after {
  opacity: 1;
}

.inline-check-list.half-fitem {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.inline-check-list.half-fitem .fitem-check {
  width: auto;
  max-width: 200px;
  display: block;
  margin: 0;
}

.inline-check-list .fitem-check {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.inline-check-list .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}

.inline-check-list.rcheck .fitem-check {
  display: block;
}

.inline-check-list.rcheck
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  left: auto;
  right: 0;
}

.inline-check-list.rcheck
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  left: auto;
  right: 7px;
}

.inline-check-list.rcheck .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  padding-left: 0;
  padding-right: 30px;
  font-weight: bold;
}

.inline-check-list.rcheck .fitem-check .fitem-ck-txt {
  width: 100%;
}

.inline-check-list.gcheck
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  background-color: #e9edf0;
  border-color: transparent;
}

.inline-check-list.gcheck
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt {
  color: #1c1d21;
}

.inline-check-list.gcheck
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt:before {
  background-color: #005d89;
  border-color: #005d89;
}

.inline-check-list.gray-check
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  background-color: #e9edf0;
  border-color: transparent;
}

.inline-check-list.gray-check
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt {
  color: #1c1d21;
}

.inline-check-list.gray-check
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt:before {
  background-color: #c1c1c1;
  border-color: #fff;
}

.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  background-color: #e9edf0;
  border-color: transparent;
}

.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input.is-checked
  ~ .fitem-ck-txt:before,
.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt:before,
.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input.fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:before {
  background-color: #d12619;
}

.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input.is-checked
  ~ .fitem-ck-txt:after,
.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input:checked
  ~ .fitem-ck-txt:after,
.inline-check-list.red-check
  .fitem-check
  input.fitem-ck-input.fitem-ck-input[checked="true"]
  ~ .fitem-ck-txt:after {
  opacity: 1;
}

.inline-check-list.sm-checks
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  width: 16px;
  height: 16px;
}

.inline-check-list.sm-checks
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  width: 5px;
  height: 9px;
  left: 6px;
}

.disable {
  cursor: not-allowed;
}

.success_bg {
  background-color: #3ba93f !important;
}

.greenbox {
  border: 2px solid #3ba93f;
  border-radius: 4px;
  background-color: #f5fff6;
  padding: 10px 15px;
  margin: 15px 0;
}

.dangerbox {
  border: 2px solid #f92020;
  border-radius: 4px;
  background-color: #fffdfd;
  padding: 10px 15px;
  margin: 15px 0;
}

.whitebox {
  border: 2px solid #e9edf0;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 15px;
  margin: 15px 0;
}

.text-lowercase {
  text-transform: lowercase;
}

.check-cols {
  width: 20px;
  height: 20px;
  margin: 13px 15px 10px 17px;
  padding: 6px 4px 6px 5px;
  border-radius: 4px;
  background-color: #005d89;
}

/* 6. Tables
------------------------------------------------------------------------------*/
table {
  border-spacing: 0;
  border-collapse: collapse;
}

td {
  text-align: left;
  font-weight: normal;
}

/* 7. Layout Framework
------------------------------------------------------------------------------*/
/* main container */
#wrapper {
  width: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

#wrapper.white-bg {
  background: #fff;
}

.block-row {
  padding: 30px;
}

.container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.container.card-view {
  background: #fff;
}

.container .md-container {
  max-width: 75%;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.content-area {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  -o-flex: 1;
  flex: 1;
  width: 100%;
  padding-left: 80px;
}

.content-area.no-padding {
  padding: 0;
}

header {
  width: 100%;
  background-color: #fff;
  height: 60px;
  border: 1px solid #efeff8;
}

header .fitem-rinner input {
  padding-right: 30px;
  padding: 17px 16px 17px 45px;
  background: #fff;
  border: none;
}

header .fitem-rinner i {
  color: #1e2025;
  font-size: 19px;
  font-weight: bold;
  transform: rotate(-90deg);
}

footer {
  width: 100%;
}

.aside-area {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-align-items: stretch;
  align-items: stretch;
  width: 100%;
  position: relative;
  padding-top: 40px;
}

.aside-area aside {
  max-width: 360px;
  margin: 0;
  width: 100%;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  -o-order: 1;
  order: 1;
  margin-left: 30px;
}

.aside-area aside.white {
  border: 1px solid #efeff8;
  background-color: #fff;
}

.aside-area aside.laside {
  -webkit-order: 0;
  -moz-order: 0;
  -ms-order: 0;
  -o-order: 0;
  order: 0;
  margin-left: 0;
  margin-right: 30px;
}

.aside-area aside.minWidth {
  min-width: 335px;
}

.aside-area .main {
  max-width: 100%;
  border: 1px solid #efeff8;
  background-color: #fff;
  width: 100%;
  padding: 30px;
  padding: 30px;
}

.aside-area .main.full-main-wrap {
  padding: 0;
}

.aside-area .back-area {
  position: absolute;
  top: 10px;
}

.transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.btn-group {
  padding: 15px;
  text-align: right;
}

.w-100 {
  width: 100%;
}

.h-98 {
  height: 98% !important;
}

.with-side-nav {
  position: relative;
}

.with-side-nav .full-sidenav {
  position: absolute;
  max-width: 305px;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  width: 100%;
  border-right: 1px solid #e0e0ea;
  height: 100%;
  z-index: 0;
}

.with-side-nav .right-content-area {
  display: block;
  margin: 0;
  padding: 35px 0;
  padding-left: 435px;
}

.with-side-nav .right-content-area .sm-content-area {
  padding: 70px 0 30px;
}

.with-side-nav .right-content-area > .container {
  margin: 0;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none;
}

.float-right {
  float: right;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex,
.form-group .half-fitem .half-fitem.flex,
.d-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.flex.ai-stretch,
.form-group .half-fitem .ai-stretch.half-fitem.flex,
.d-flex.ai-stretch {
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
}

.flex.ai-center,
.form-group .half-fitem .ai-center.half-fitem.flex,
.d-flex.ai-center,
.form-group .half-fitem .d-flex.half-fitem.ai-center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.flex-start {
  justify-content: start;
}

.flex-end {
  justify-content: flex-end;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.btn-grp {
  margin-bottom: 10px;
}

.btn-grp.flex .btn-txt,
.form-group .half-fitem .btn-grp.half-fitem.flex .btn-txt {
  padding-left: 0;
  padding-right: 0;
}

.btn-grp.flex .btn-txt,
.form-group .half-fitem .btn-grp.half-fitem.flex .btn-txt,
.btn-grp.flex .btn,
.form-group .half-fitem .btn-grp.half-fitem.flex .btn {
  margin: 0;
}

.btn-grp.pb-40 {
  padding-bottom: 40px;
}

.btn-grp.btn-center {
  margin: 0 auto;
  text-align: center;
}

.btn-grp.btn-center .btn {
  margin: 0 5px;
}

.btn-grp.text-right .btn + .btn {
  margin-left: 5px;
  margin-right: 0;
}

.btn-grp.text-left .btn + .btn {
  margin-left: 5px;
  margin-right: 0;
}

hr {
  border: none;
  height: 1px;
  background: #e1e1e5;
  margin: 10px 0;
}

.card {
  border: 1px solid #efeff8;
  background-color: #fff;
}

.card.sm-card {
  max-width: 450px;
}

.card.card-gray {
  background-color: #f7f7f7;
}

.card.inline-card {
  display: inline-block;
  margin: 0 auto;
  width: auto;
}

.card.add-card {
  background: transparent;
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed rgba(64, 121, 119, 0.5);
  padding: 15px;
  border-image-slice: 2;
  border-image-repeat: repeat;
  border-image-outset: 0;
}

.card.add-card .btn.lg-btn {
  width: 200px;
}

.card.add-card h5 {
  margin: 0;
  line-height: 36px;
}

.card.add-card p {
  text-align: center;
  padding: 0 40px;
}

.card-row.flex .card,
.form-group .half-fitem .card-row.half-fitem.flex .card {
  margin: 0;
}

.card-row .card-half {
  flex: 1;
  max-width: 50%;
}

.card-row .card-half:last-child {
  margin-left: 15px;
}

.card-row .card-full {
  flex: 1;
  max-width: 100%;
  width: 100%;
  padding: 24px 32px;
}

.card .card-footer {
  margin: 0;
  text-align: right;
  width: 100%;
  padding: 20px 30px;
}

.card .card-footer .txt-btn {
  color: #fff;
  font-size: 14px;
}

.card .card-title {
  padding: 10px 30px;
  background: #fff;
  border-bottom: 1px solid #e1e1e5;
}

.card .card-title.bdr-b-none {
  border-bottom: none;
}

.card .card-title h5 {
  line-height: 32px;
}

.card .card-title h1,
.card .card-title h2,
.card .card-title h5 {
  margin: 0;
}

.card .card-title h3 {
  font-weight: 400;
  margin: 0;
}

.card .card-title p {
  margin: 0;
}

.card .card-body {
  padding: 20px 30px;
}

.flist {
  display: flex;
  flex-wrap: wrap;
}

.flist .card {
  margin: 0 30px 30px 0;
  flex: auto;
  max-width: 370px;
  width: 30%;
}

.cursor_pointer {
  cursor: pointer;
}

/*ellipse & break text*/
.ellipse {
  white-space: nowrap;
  display: block;
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word;
}

/* LOGIN pages  */
.login-wrap-row {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.login-wrap-row .login-wrap {
  text-align: left;
  max-width: 570px;
}

.login-wrap-row .login-wrap .logo {
  text-align: center;
  margin-bottom: 140px;
  margin: 0 auto 140;
}

.login-wrap-row .login-wrap .logo a {
  display: inline-block;
  max-width: 300px;
}

.field-wrap-address {
  max-width: 170px !important;
  width: 20% !important;
  min-width: 170px !important;
}

.col-count-2 {
  column-count: 2;
}

.filled-item {
  display: block;
  line-height: 36px;
}

.m-0 {
  margin: 0;
}

/*Upload File*/
.upld-file-wrap {
  padding: 35px;
  border: 2px dashed #f4f7f9;
  border-radius: 6px;
  background-color: #fff;
  text-align: center;
  position: relative;
  margin-top: 20px;
}

.upld-file-wrap h6,
.upld-file-wrap p {
  color: rgba(34, 34, 34, 0.8);
  margin-bottom: 10px;
}

.upld-file-wrap p {
  font-size: 13px;
}

.upld-file-wrap input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: #ccc;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.upld-file-wrap .btn {
  position: relative;
  z-index: 9;
}

/*side nav*/
.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 9999;
  transform: translate(0, 0);
}

.side-nav .ant-menu {
  height: 100%;
  /* background: linear-gradient(270deg, rgb(0, 93, 137) 0%, #336665 100%), linear-gradient(270deg, #005d89 0%, #34615E 100%); */
}

.side-nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.side-nav .ant-menu .ant-menu-item {
  margin: 2px 0 0;
  padding: 0 15px !important;
  height: auto;
}

.side-nav .ant-menu .ant-menu-item.ant-menu-submenu > a {
  position: relative;
  display: block;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  > a
  .ant-menu-submenu-arrow {
  color: #fff;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: 0;
  transition: none;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  > a
  .ant-menu-submenu-arrow:after {
  display: none;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu.has-open
  > a
  .ant-menu-submenu-arrow {
  transform: rotate(180deg);
  margin-top: -7px;
}

.side-nav .ant-menu .ant-menu-item.ant-menu-submenu.has-open .ant-menu-sub {
  opacity: 1;
  display: block;
  background: transparent;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu.has-open
  .ant-menu-sub
  .ant-menu-item.active
  .mitem {
  background: rgba(255, 255, 255, 0.1);
}

.side-nav .ant-menu .ant-menu-item.ant-menu-submenu .ant-menu-sub {
  opacity: 0;
  display: none;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item {
  padding-left: 42px !important;
  padding-right: 0 !important;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item
  a {
  display: block;
}

.side-nav
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item
  .mitem {
  background: transparent;
  font-size: 12px;
}

.side-nav .ant-menu .ant-menu-item img {
  margin: 0 25px 0 0;
}

.side-nav .ant-menu .ant-menu-item .img1 {
  margin: 0 22px 0 0;
  width: 20px;
}

.side-nav
  .ant-menu-inline-collapsed.ant-menu
  .ant-menu-item
  .mitem
  .retailSale {
  padding-top: 10px !important;
}

.side-nav .ant-menu .ant-menu-item:hover:not(.opitem) .mitem,
.side-nav .ant-menu .ant-menu-item:focus:not(.opitem) .mitem,
.side-nav .ant-menu .ant-menu-item.active:not(.opitem) .mitem {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-nav .ant-menu .ant-menu-item .mitem,
.side-nav .ant-menu .ant-menu-item.btn-sec > i {
  line-height: 18px;
  height: auto;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  font-weight: 700;
  padding: 12px 10px !important;
  border-radius: 4px;
  display: block;
}

.side-nav .ant-menu .ant-menu-item .mitem span,
.side-nav .ant-menu .ant-menu-item.btn-sec > i span {
  opacity: 1;
}

.side-nav .ant-menu .ant-menu-item.btn-sec {
  margin: 40px 0;
}

.side-nav .ant-menu .ant-menu-item.btn-sec > i {
  font-size: 20px;
}

.side-nav .ant-menu .ant-menu-item.btn-sec .btn {
  display: none;
}

.side-nav .ant-menu .ant-menu-item.btn-sec .btn.btn-warning {
  padding: 0 0 0 9px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.side-nav .ant-menu .ant-menu-item.btn-sec .btn.btn-warning > span {
  opacity: 0;
}

.side-nav .ant-menu .ant-menu-item.btn-sec .btn.btn-warning .dwnld {
  border-radius: 4px 0 0 4px;
  background: rgba(0, 0, 0, 0.1);
  padding: 13px 8px;
}

.side-nav .ant-menu .ant-menu-item.btn-sec .btn.btn-warning i {
  font-size: 20px;
}

.side-nav .ant-menu .ant-menu-item.opitem {
  background: rgb(28, 108, 148);
  margin: 0;
}

.side-nav .ant-menu .ant-menu-item.opitem .mitem {
  display: flex;
  align-items: center;
}

.side-nav .ant-menu .ant-menu-item.opitem .mitem .userimg {
  height: 30px;
  width: 30px;
  margin: 0;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  max-width: 30px;
  max-height: 30px;
}

.userimgheader {
  height: 30px;
  width: 30px;
  margin: 0;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  max-width: 30px;
  max-height: 30px;
}

.side-nav .ant-menu .ant-menu-item.opitem:first-child .mitem {
  padding: 12px 10px !important;
}

.side-nav .ant-menu .plain-btn {
  margin-bottom: 0 !important;
  padding: 20px;
  display: flex;
  align-items: center;
  background: transparent;
}

.side-nav .ant-menu .plain-btn img + span + img {
  margin-left: 25px;
}

.side-nav .ant-menu .plain-btn:after {
  display: none;
}

.side-nav .ant-menu .ant-menu-item:active,
.side-nav .ant-menu .ant-menu-submenu-title:active {
  background: transparent;
}

.side-nav .ant-menu:hover {
  width: 250px;
}

.side-nav .ant-menu:hover > .plain-btn img + span + img {
  display: block;
}

.side-nav .ant-menu:hover ul .ant-menu-item .ant-menu-submenu-arrow {
  opacity: 1;
}

.side-nav .ant-menu:hover ul .ant-menu-item .mitem span {
  opacity: 1;
}

.side-nav .ant-menu:hover ul .ant-menu-item.btn-sec > i {
  display: none;
}

.side-nav .ant-menu:hover ul .ant-menu-item.btn-sec .btn {
  display: flex;
}

.side-nav .ant-menu:hover ul .ant-menu-item.btn-sec .btn.btn-warning span {
  opacity: 1;
}

.side-nav .ant-menu-inline .ant-menu-selected:after,
.side-nav .ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}

.side-nav .ant-menu-inline-collapsed.ant-menu .ant-menu-item .mitem span {
  opacity: 0;
  vertical-align: top;
}

.side-nav .ant-menu-inline-collapsed > .plain-btn img + span + img {
  display: none;
}

.side-nav
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.side-nav
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0;
}

.side-nav
  .ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0;
}

.side-nav hr {
  margin: 30px 0;
  border: none;
  background: #d8d8d8;
  height: 1px;
}

.side-nav .vertical-scroll {
  height: calc(100vh - 205px);
}

.side-nav .btm-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.ant-btn-primary {
  box-shadow: none;
}

/*Header*/
header {
  padding-right: 30px;
}

header .fitem-rinner {
  flex: auto;
}

header .action_menu {
  flex: auto;
  max-width: 180px;
  height: 60px;
  justify-content: flex-end;
}

header .fitem-rinner.licon input:focus + .fitem-ricon i {
  color: #005d89;
}

/*Profile wrap*/
.profile-wrap figure {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.profile-wrap figure img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.profile-wrap.user-profile figure {
  height: 120px;
  width: 120px;
}

.profile-wrap.md-profile figure {
  height: 80px;
  width: 80px;
  margin-bottom: 0;
}

/*Login*/
.auth-page {
  display: flex;
  width: 100%;
  background: #fff;
  min-height: 100vh;
  height: 100%;
}

.auth-page .logo {
  width: 160px;
  height: 76px;
  object-fit: contain;
  margin-bottom: 50px;
}

.auth-page .authform {
  background: url("../Assets/images/Floater.png") #fff;
  background-repeat: no-repeat;
  background-position: left 50px bottom -80px;
  background-size: 200px;
  left: 16px;
  border-radius: 0 16px 16px 0;
  position: relative;
  z-index: 9;
  top: 0;
}

.auth-page .authform,
.auth-page .sidebg {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.auth-page .sidebg {
  background-image: linear-gradient(to left, #005d89, #014c70 0%);
  /* background: linear-gradient(270deg, rgb(0, 93, 137) 0%, #336665 100%), linear-gradient(270deg, #005d89 0%, #34615E 100%); */
  flex-direction: column;
}

.auth-page .sidebg .slide {
  margin-top: 50px;
  color: #fff;
  max-width: 280px;
}

.auth-page .sidebg .slide h2,
.auth-page .sidebg .slide p {
  color: #fff;
}

.auth-page form {
  margin-bottom: 100px;
}

.auth-page .auth-container {
  max-width: 430px;
  margin: 80px auto;
}

.auth-page .fitem-rinner i {
  color: rgba(85, 85, 85, 0.6);
  font-size: 16px;
}

.auth-page .fitem-rinner .fitem-ricon {
  top: 75%;
  transform: translateY(-75%);
  right: 15px;
}

.validate {
  color: #6f6f6f;
  line-height: 22px;
}

.validate .circle {
  height: 10px;
  width: 10px;
  background-color: transparent;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #555555;
  opacity: 0.6;
  margin-right: 10px;
}

/*Ant Tables*/
.ant-table.hscroll .ant-table-content {
  overflow-x: auto;
}

.ant-table-thead > tr > th {
  background: rgba(245, 245, 250, 0.4);
  color: #606060;
  font-size: 12px;
  font-weight: bold;
  border-top: 1px solid #f0f2f2;
  border-bottom: 1px solid #f0f2f2;
  white-space: nowrap;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > td,
.ant-table-tbody > tr > th,
.ant-table-tbody > tr > td {
  padding: 13px 16px;
  max-width: 200px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  color: #22222a;
}

.ant-table-tbody > tr > td:first-child {
  font-weight: bold;
  line-height: 21px;
}

.ant-table .ant-table-column-sorters {
  padding: 0;
}

.ant-table-wrapper .ant-table-thead th {
  padding: 15px 10px;
}

.ant-table-wrapper .ant-table-thead th.tname {
  max-width: 160px;
  width: 15%;
}

.ant-table-wrapper .ant-table-thead th.tproductcode {
  max-width: 160px;
  width: 15%;
}

.ant-table-wrapper .ant-table-thead th.tphnum {
  max-width: 120px;
  width: 15%;
  min-width: 130px;
}

.ant-table-wrapper .ant-table-thead th.temail,
.ant-table-wrapper .ant-table-thead th.tadd {
  max-width: 115px;
  width: 22%;
}

.ant-table-wrapper .ant-table-thead th.tgrp,
.ant-table-wrapper .ant-table-thead th.trole {
  max-width: 115px;
  width: 12%;
}

.ant-table-wrapper .ant-table-thead th.tprofile {
  max-width: 125px;
  width: 15%;
}

.ant-table-wrapper .ant-table-thead th.tstatus {
  max-width: 110px;
  width: 12%;
}

.ant-table-wrapper .ant-table-thead th.tdate {
  max-width: 140px;
  width: 20%;
  min-width: 90px;
}

.ant-table-wrapper .ant-table-thead th.taction {
  width: 70px;
}

.ant-table-wrapper .ant-table-thead th.tuser {
  max-width: 90px;
  width: 10%;
}

.ant-table-wrapper .ant-table-thead th.tdesc {
  max-width: 300px;
  width: 45%;
}

.ant-table-wrapper .ant-table-thead th.tprice {
  max-width: 100px;
  width: 10%;
}

.ant-table-wrapper .ant-table-thead th.taddress {
  max-width: 215px;
  width: 25%;
}

.ant-table-wrapper .ant-table-thead th.tstatus {
  max-width: 100px;
  width: 10%;
}

.ant-table-wrapper .ant-table-thead th.lfbdr {
  padding-left: 30px;
  min-width: 130px;
}

.ant-table-wrapper .ant-table-thead th.lfbdr:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6f8f8;
  position: absolute;
  left: 0px;
  top: 0;
}

.ant-table-wrapper .ant-table-thead th:first-child {
  padding-left: 30px;
}

.ant-table-wrapper .ant-table-thead th:last-child {
  padding-right: 30px;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: rgba(245, 245, 250, 0.4);
}

.ant-table-wrapper .ant-table-tbody td {
  padding: 7px 10px;
}

.ant-table-wrapper .ant-table-tbody td.tname {
  max-width: 130px;
  width: 15%;
}

.ant-table-wrapper .ant-table-tbody td.tphnum {
  max-width: 120px;
  width: 15%;
  min-width: 130px;
}

.ant-table-wrapper .ant-table-tbody td.tproductcode {
  max-width: 160px;
  width: 15%;
  font-weight: bold;
}

.ant-table-wrapper .ant-table-tbody td.temail,
.ant-table-wrapper .ant-table-tbody td.tadd {
  max-width: 115px;
  width: 22%;
}

.ant-table-wrapper .ant-table-tbody td.tgrp,
.ant-table-wrapper .ant-table-tbody td.trole {
  max-width: 115px;
  width: 12%;
}

.ant-table-wrapper .ant-table-tbody td.tprofile {
  max-width: 125px;
  width: 15%;
}

.ant-table-wrapper .ant-table-tbody td.tstatus {
  max-width: 110px;
  width: 12%;
}

.ant-table-wrapper .ant-table-tbody td.tdate {
  max-width: 140px;
  width: 20%;
  min-width: 90px;
}

.ant-table-wrapper .ant-table-tbody td.taction {
  width: 70px;
}

.ant-table-wrapper .ant-table-tbody td.tuser {
  max-width: 90px;
  width: 10%;
}

.ant-table-wrapper .ant-table-tbody td.tdesc {
  max-width: 300px;
  width: 45%;
}

.ant-table-wrapper .ant-table-tbody td.tprice {
  max-width: 100px;
  width: 10%;
}

.ant-table-wrapper .ant-table-tbody td.taddress {
  max-width: 215px;
  width: 25%;
}

.ant-table-wrapper .ant-table-tbody td.tstatus {
  max-width: 100px;
  width: 10%;
}

.ant-table-wrapper .ant-table-tbody td.lfbdr {
  padding-left: 30px;
  min-width: 130px;
}

.ant-table-wrapper .ant-table-tbody td.lfbdr:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6f8f8;
  position: absolute;
  left: 0px;
  top: 0;
}

.ant-table-wrapper .ant-table-tbody td:first-child {
  padding-left: 30px;
}

.ant-table-wrapper .ant-table-tbody td:last-child {
  padding-right: 30px;
}

.ant-table-wrapper .text-successs {
  font-weight: 600;
  color: #508483;
}

.ant-table-wrapper .text-capitalize {
  text-transform: capitalize;
}

.leadstbl .ant-table-wrapper .ant-table-thead th.tprofile {
  width: 12%;
}

.leadstbl .ant-table-wrapper .ant-table-thead th.tadd {
  width: 18%;
}

/*Sorting arrow & font icons*/
.anticon-caret-up,
.anticon-caret-down,
.ico-before,
.ant-select-suffix,
.ico-btn .anticon-filter {
  font-family: "Line Awesome Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 18px;
}

.anticon-caret-up:before,
.anticon-caret-down:before,
.ico-before:before,
.ant-select-suffix:before,
.ico-btn .anticon-filter:before {
  color: rgba(85, 85, 85, 0.6);
  height: 12px;
  width: 10px;
  display: block;
}

.ant-table-column-sorter-up.active:before,
.ant-table-column-sorter-down.active:before {
  color: #005d89;
}

.anticon-caret-up {
  font-size: 14px;
}

.anticon-caret-up:before {
  content: "\f062";
}

.anticon-caret-down {
  font-size: 14px;
}

.anticon-caret-down:before {
  content: "\f063";
  margin-top: -5px;
}

.ant-select-suffix svg,
.ant-table-column-sorter-up svg,
.ant-table-column-sorter-down svg,
.ico-btn .anticon-filter svg {
  display: none;
}

.ico-btn .anticon-filter {
  width: 20px;
  height: 18px;
  line-height: 25px;
}

.ico-btn .anticon-filter:before {
  content: "\f0b0";
  font-size: 19px;
  font-weight: bold;
  color: rgb(0, 93, 137);
}

.ant-select-suffix:before {
  content: "\f107";
  color: #1c1d21;
  font-size: 14px;
  width: 15px;
  height: 15px;
  line-height: 12px;
}

.ant-picker-suffix svg {
  color: #1c1d21;
  opacity: 0.6;
  font-weight: bold;
}

.ant-table-filter-trigger i {
  font-size: 17px;
  color: #555555;
}

/*User status*/
.user-status {
  margin: 0 20px 0 0;
  position: relative;
  display: block;
}

.user-status .astatus {
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  background-color: #3ba93f;
  position: absolute;
  border-radius: 100%;
  right: -5px;
  z-index: 999;
  bottom: 0;
}

/*Breadcrumbs*/
.breadcrumb {
  display: flex;
}

.breadcrumb li {
  font-size: 12px;
  font-family: "Line Awesome Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 18px;
  padding-right: 15px;
  margin-right: 10px;
  position: relative;
  padding-bottom: 0;
}

.breadcrumb li:after {
  content: "\f105";
  color: #1c1d21;
  font-size: 14px;
  width: 15px;
  height: 15px;
  line-height: 12px;
  height: 12px;
  width: 10px;
  display: block;
  position: absolute;
  top: 3px;
  right: 0;
}

.breadcrumb li span {
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.breadcrumb li:last-child:after {
  display: none;
}

/*Active Inactive status*/
.status {
  color: #6f6f6f;
  background-color: rgba(41, 124, 120, 0.1);
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  padding: 5px 8px;
}

.status-optimo {
  color: #6f6f6f;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  padding: 5px 8px;
}

.status.active {
  color: #005d89;
  background-color: rgba(41, 124, 120, 0.1);
}

.status.inactive {
  color: #8e0a0a;
  background-color: rgba(209, 38, 25, 0.1);
}

.status small,
.billable small {
  display: block;
  font-weight: normal;
  text-transform: none;
  margin-top: 2px;
  text-align: center;
}

.status-optimo small {
  display: block;
  font-weight: normal;
  text-transform: none;
  margin-top: 2px;
}

.status.danger-status {
  text-align: left;
  line-height: 12px;
}

/*status color text*/
.danger {
  color: #8e0a0a !important;
}

.red {
  color: #d12619;
}

.success {
  color: rgb(0, 93, 137) !important;
}

.black {
  color: #000;
}

.orange {
  color: #ff681d;
}

.las,
.la {
  font-size: 18px;
}

.grey {
  color: #8181a5;
}

/*Blockquote*/
blockquote {
  padding: 15px 20px;
  line-height: 16px;
  font-size: 13px;
}

blockquote.horiz {
  border-top: 1px solid #ffb64d;
  background-color: rgba(255, 182, 77, 0.2);
}

blockquote.suspend {
  border-top: 1px solid #d12619;
  background-color: rgba(209, 38, 25, 0.2);
}

/*priority*/
.prty {
  padding-left: 15px;
  position: relative;
}

.prty:before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 2px;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.prty.high:before,
.prty.danger:before {
  background-color: #d12619;
}

.prty.open:before {
  background-color: #005d89;
}

.prty.success:before {
  background-color: #3ba93f;
}

.prty.closed:before {
  background-color: rgba(41, 124, 120, 0.2);
}

.prty.warning:before,
.prty.medium:before {
  background-color: #ff681d;
}

.prty.low:before {
  background-color: #3ba93f;
}

/*Listing*/
.check-list li i {
  font-size: 20px;
  margin-right: 10px;
}

.can-toggle {
  position: relative;
}

.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
  left: -11px;
}

.can-toggle label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
  padding-left: 32px;
  font-family: "Lato", sans-serif;
}

.can-toggle label .can-toggle__switch {
  position: relative;
}

.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-align: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
}

.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-align: center;
  background: #ff681d;
  color: #fff !important;
  font-family: "Lato", sans-serif;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #ffffff;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #5e5e5e;
}

.can-toggle input[type="checkbox"]:hover ~ label {
  color: #6a6a6a;
}

.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #fff;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #fff;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #ffffff;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #ffffff;
}

.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #ffffff;
}

.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
  flex: 1;
}

.can-toggle label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #ffffff;
}

.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1),
    -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 8px 0 0 8px;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
  transform: translate3d(65px, 0, 0);
}

.can-toggle label {
  font-size: 14px;
}

.can-toggle label .can-toggle__switch {
  height: 36px;
  -webkit-box-flex: 0;
  flex: 0 0 134px;
  border-radius: 8px;
}

.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
  font-family: "Lato", sans-serif;
}

.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  font-family: "Lato", sans-serif;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}

.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  -webkit-transform: translate3d(44px, 0, 0);
  transform: translate3d(44px, 0, 0);
  border-radius: 0 8px 8px 0;
}

.can-toggle.can-toggle--size-small label {
  font-size: 13px;
  width: 70px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(32, 78, 86, 0.1);
  margin: 0;
  padding: 0;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  -webkit-box-flex: 0;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 32px;
  font-size: 10px;
  line-height: 28px;
  width: 40px;
  padding: 0 12px;
  color: #204e56;
  font-family: "Lato", sans-serif;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 0;
  left: 0px;
  width: 40px;
  line-height: 28px;
  font-size: 10px;
  z-index: 1;
}

.ant-table-column-sorters {
  display: inline-block;
}

.ant-table-column-sorter-inner {
  margin-left: 5px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -25px;
}

.ant-carousel .slick-dots li button {
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.fix_isd_code {
  position: relative;
}

.fix_isd_code span {
  position: absolute;
  padding: 12px 5px 12px 15px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.fix_isd_code input[type="text"] {
  padding: 10px 11px;
}

.ant-modal-mask {
  background: #00000033;
  position: relative;
}

.column-modal {
  width: 100px;
  min-height: 356px;
  overflow: hidden;
}

.column-modal .ant-modal-footer {
  background-color: #f5f5fa;
}

.column-modal .ant-modal-body {
  margin-left: 13px;
  margin-top: 10px;
  overflow-y: auto;
}

.column-modal .ant-modal-header {
  background-color: #f5f5fa;
}

.delete_user_modal .ant-modal-close {
  display: none;
}

.inactive_user_modal .ant-modal-close {
  display: none;
}

.inactive_user_modal .ant-modal-footer {
  background-color: white;
  border-top: 0px;
}

.inactive_user_modal .ant-modal-body{
  padding: 25px 30px 0px;
}

.inactive_user_modal .ant-modal-content{
  width: 500px;
}

.inactive_user_modal .anticon-exclamation-circle{
  position: relative;
  top: 1px;
  margin-right: 5px;
  font-size: 18px;
  color:  #ffa700;
}

.inactive_user_modal .btn {
  background-color: #ff681d;
  width: 90px;
  border-color: #ff681d;
  margin-right: 10px;
}

.inactive_user_modal .ant-modal-footer{
  padding: 20px 60px;
}

.delete_user_modal .ant-modal-header {
  display: none;
}

.delete_user_modal .ant-modal-body {
  padding: 10px 15px 0 !important;
}

.delete_customer_modal .ant-modal-footer .ant-btn-primary,
.delete_user_modal .ant-modal-footer .ant-btn-primary,
.pool_characterstics_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.delete_user_modal .ant-modal-content, 
.delete_customer_modal .ant-modal-content,
.pool_characterstics_modal .ant-modal-content {
  box-shadow: none;
}

.delete_customer_modal .ant-modal-close,
.delete_customer_modal .ant-modal-header,
.pool_characterstics_modal .ant-modal-close {
  display: none;
}

.delete_customer_modal .ant-modal-body,
.pool_characterstics_modal .ant-modal-body {
  padding: 15px 20px 20px !important;
}

.quickbook_setup_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.custom_frequency .ant-modal-content {
  box-shadow: none;
  width: 600px;
  left: -9%;
  border: 1px solid #989c9f;
  border-radius: 0px;
}

.task_custom_frequency .ant-modal-content {
  box-shadow: none;
  width: 600px;
  border: 1px solid #989c9f;
  border-radius: 0px;
}

.add_line_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.add_line_modal .ant-modal-footer .ant-btn-primary:hover {
  background: #ff681d;
  border-color: #ff681d;
}

.add_line_modal .ant-modal-footer .ant-btn-default {
  background-color: #fff;
  border: 1px solid #efeff8;
  color: #6f6f6f;
}

.filter_drawer_wrapper .footer {
  width: 100%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #f5f5fa;
  left: 0;
  right: 0;
}

.group_filter_wrapper {
  padding: 10px;
}

.fltr-txt {
  display: flex;
}

.fltr-txt .filter_ant_select {
  width: auto;
}

.fltr-txt .filter_ant_select .ant-select-selection-placeholder {
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
}

.fltr-txt .filter_ant_select .ant-select-selection-placeholder::before {
  font-weight: 700;
  position: absolute;
  width: 100%;
  left: 5px;
}

.fltr-txt .filter_ant_select .ant-select-selection-item {
  line-height: 25px !important;
}

.fltr-txt .filter_ant_select .ant-select-selection-item .pre_digit {
  display: none;
}

.fltr-txt .filter_ant_select .ant-select-arrow {
  transform: rotate(0) !important;
}

.fltr-txt .filter_by_franchise .ant-select-selection-placeholder {
  padding-left: 85px;
}

.fltr-txt .filter_by_franchise .ant-select-selection-placeholder::before {
  content: "Franchise: ";
}

.fltr-txt .filter_by_franchise .ant-select-selection-item::before {
  content: "Franchise: ";
}

.fltr-txt .filter_by_group .ant-select-selection-placeholder {
  padding-left: 85px;
}

.fltr-txt .filter_by_group .ant-select-selection-placeholder::before {
  content: "Group: ";
}

.fltr-txt .filter_by_group .ant-select-selection-item::before {
  content: "Group: ";
}

.fltr-txt .filter_by_assigned_to .ant-select-selection-placeholder {
  padding-left: 85px;
}

.fltr-txt .filter_by_assigned_to .ant-select-selection-placeholder::before {
  content: "Assigned to: ";
}

.fltr-txt .filter_by_assigned_to .ant-select-selection-item::before {
  content: "Assigned to: ";
}

.fltr-txt .filter_by_disposition .ant-select-selection-placeholder {
  padding-left: 85px;
}

.fltr-txt .filter_by_disposition .ant-select-selection-placeholder::before {
  content: "Disposition: ";
}

.fltr-txt .filter_by_disposition .ant-select-selection-item::before {
  content: "Disposition: ";
}

.fltr-txt .filter_by_status .ant-select-selection-placeholder {
  padding-left: 55px;
}

.fltr-txt .filter_by_status .ant-select-selection-placeholder::before {
  content: "Status: ";
}

.fltr-txt .filter_by_status .ant-select-selection-item::before {
  content: "Status: ";
}

.fltr-txt .filter_by_priority .ant-select-selection-placeholder {
  padding-left: 65px;
}

.fltr-txt .filter_by_priority .ant-select-selection-placeholder::before {
  content: "Priority: ";
}

.fltr-txt .filter_by_priority .ant-select-selection-item::before {
  content: "Priority: ";
}

.create_ticket_drawer
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px;
}

.create_ticket_drawer .ant-select-show-search .ant-select-arrow {
  transform: rotate(0);
}

.case_management_table .status_before {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.case_management_table .status_before i {
  margin-right: 10px;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.case_management_table .primary i {
  background-color: #005d89;
}

.case_management_table .gray i {
  background-color: rgba(41, 124, 120, 0.2);
}

.case_management_table .low i {
  background: #3ba93f;
}

.case_management_table .medium i {
  background: #f9a720;
}

.case_management_table .high i {
  background: #cde059;
}

.filter_by_sort .ant-select-selection-placeholder {
  padding-left: 85px;
}

.filter_by_sort .ant-select-selection-placeholder::before {
  content: "Sort by: ";
}

.filter_by_sort .ant-select-selection-item::before {
  content: "Sort by: ";
  padding-right: 5px;
}

.filter_by_assign .ant-select-selection-placeholder {
  padding-left: 85px;
}

.filter_by_assign .ant-select-selection-placeholder::before {
  content: "Assigned to: ";
}

.filter_by_assign .ant-select-selection-item::before {
  content: "Assigned to: ";
  padding-right: 5px;
}

.filter_by_status .ant-select-selection-placeholder {
  padding-left: 85px;
}

.filter_by_status .ant-select-selection-placeholder::before {
  content: "Status: ";
}

.filter_by_status .ant-select-selection-item::before {
  content: "Status: ";
  padding-right: 5px;
}

.filter_by_Priority .ant-select-selection-placeholder {
  padding-left: 85px;
}

.filter_by_Priority .ant-select-selection-placeholder::before {
  content: "Priority: ";
}

.filter_by_Priority .ant-select-selection-item::before {
  content: "Priority: ";
  padding-right: 5px;
}

.ticket_filter ul li {
  margin-right: 10px;
}

.ticket_filter .export_csv_btn {
  height: 36px;
  width: 36px;
  border: 1px solid #c4d6d6;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.message_list {
  border: 1px solid #efeff8;
  border-radius: 8px;
  background-color: #fff;
}

.message_list .msg-tbl-block {
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}

.message_list .msg-tbl-block .gap {
  border: none;
}

.message_list .msg-tbl-block .gap td {
  padding: 5px 0;
  border: none;
}

.message_list .msg-tbl-block .close_ticket {
  border: 1px solid #ececf2;
  background-color: #fff;
}

.message_list .msg-tbl-block .open_ticket {
  border: 1px solid #5e81f4;
  background-color: rgba(245, 245, 250, 0.4);
}

.message_list .msg-tbl-block .trow {
  margin-bottom: 10px;
  border-radius: 6px;
  height: 70px;
  padding: 20px;
  display: flex;
}

.message_list .msg-tbl-block .trow > span {
  flex: 1;
  padding: 0 10px;
}

.message_list .msg-tbl-block .trow > span:first-child {
  padding-left: 0;
}

.message_list .msg-tbl-block .trow > span:last-child {
  padding-right: 0;
}

.message_list .msg-tbl-block .trow .priord_user {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  line-height: 24px;
}

.message_list .msg-tbl-block .trow .title {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 1;
  cursor: pointer;
}

.message_list .msg-tbl-block .trow .assigned_user {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  line-height: 24px;
  max-width: 150px;
}

.message_list .msg-tbl-block .trow .priority {
  color: #1c1d21;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 20px;
  width: 16%;
  max-width: 150px;
}

.message_list .msg-tbl-block .trow .priority:after {
  content: "";
  position: absolute;
  left: 0;
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #b8b8b8;
}

.message_list .msg-tbl-block .trow .status_open:after {
  background-color: #005d89 !important;
}

.message_list .msg-tbl-block .trow .status_closed:after {
  background-color: rgba(41, 124, 120, 0.2) !important;
}

.message_list .msg-tbl-block .trow .status {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  background: transparent;
  line-height: 24px;
  position: relative;
  text-transform: capitalize;
  display: flex;
  padding-left: 20px;
  max-width: 120px;
  width: 15%;
  font-weight: 500;
}

.message_list .msg-tbl-block .trow .status:after {
  content: "";
  position: absolute;
  left: 0;
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #b8b8b8;
  top: 8px;
}

.message_list .msg-tbl-block .trow .time {
  color: #1c1d21;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  width: 16%;
  max-width: 180px;
}

.message_list .msg-tbl-block .trow .priority_success::after {
  background-color: #3ba93f !important;
}

.message_list .msg-tbl-block .trow .priority_high::after {
  background-color: #d12619 !important;
}

.message_list .msg-tbl-block .trow .priority_medium:after {
  background-color: #f9a720;
}

.message_list .msg-tbl-block .trow .status_success::after {
  background-color: #005d89;
}

.message_list .msg-tbl-block .trow.active {
  border: 1px solid rgb(0, 93, 137);
  background-color: rgba(64, 121, 119, 0.02);
  opacity: 1;
}

.message_list.ticket_detail {
  padding: 30px;
  flex: 1;
}

.message_list.ticket_detail .msg-tbl-block {
  border: 1px solid #ececf2;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 10px;
}

.message_list.ticket_detail .msg-tbl-block.active {
  border-color: #005d89;
  background-color: rgba(41, 124, 120, 0.02);
}

.message_list.ticket_detail .msg-tbl-block .trow {
  padding: 20px 30px;
  opacity: 1;
  display: block;
}

.message_list.ticket_detail .msg-tbl-block .title-head {
  color: #1e2025;
}

.message_list.ticket_detail .msg-tbl-block .title-head .name {
  text-transform: capitalize;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mhead-row,
.message_list.ticket_detail .msg-tbl-block .mrow .mbody {
  padding: 20px 30px;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mhead-row {
  border-bottom: 1px solid #ececf2;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mbody {
  font-size: 13px;
  padding: 20px 30px 15px;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mbody .mname {
  font-weight: 700;
  color: #313340;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mbody .time {
  color: rgba(74, 78, 97, 0.6);
  line-height: 15px;
  font-weight: 500;
}

.message_list.ticket_detail .msg-tbl-block .mrow .mbody p {
  color: #2b2d37;
  font-size: 13px;
}

.message_list.ticket_detail .msg-tbl-block .mhead .user-info {
  display: flex;
  margin-bottom: 20px;
}

.message_list.ticket_detail .msg-tbl-block .mhead .user-info .uname {
  flex: 1;
  text-align: left;
  justify-content: flex-start;
}

.message_list.ticket_detail
  .msg-tbl-block
  .mhead
  .user-info
  .uname
  .title-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back_link {
  background-color: #f1f1f5;
  border-bottom: 1px solid #e3e3e9;
  padding: 13px 0;
}

.back_link span {
  color: #1c1d21;
  font-size: 12px;
  padding-left: 30px;
  cursor: pointer;
}

.new_ticket_wrapper {
  border: 1px solid #efeff8;
  border-radius: 8px;
  background-color: #fff;
}

.new_ticket_wrapper .card {
  max-width: 360px;
  width: 360px;
  flex: 1;
}

.new_ticket_wrapper .ticket_detail {
  padding: 30px;
  flex: 1;
}

.new_ticket_wrapper .ticket_detail .review_images img {
  cursor: pointer;
  height: 25px;
  margin-right: 5px;
}

.new_ticket_wrapper .ticket_detail .review_images .disable_img {
  opacity: 0.4;
  cursor: pointer;
}

.new_ticket_wrapper .ticket_detail button {
  margin-top: 10px;
  float: right;
}

.new_ticket_wrapper .main_msg {
  border: 1px solid #ececf2;
  border-radius: 6px;
  background-color: #fff;
}

.new_ticket_wrapper .main_msg .header {
  padding: 25px 30px 15px 30px;
  border-bottom: 1px solid #ececf2;
}

.new_ticket_wrapper .main_msg .header b {
  color: #1c1d21;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
}

.new_ticket_wrapper .main_msg .header b span {
  border-radius: 3px;
  background-color: rgba(255, 182, 77, 0.2);
  padding: 5px 13px;
  margin-left: 10px;
  color: #ff681d;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}

.new_ticket_wrapper .main_msg .header p {
  margin-bottom: 0;
  color: #1e2025;
  font-size: 13px;
  line-height: 24px;
}

.new_ticket_wrapper .main_msg .header p b {
  font-weight: bold;
}

.new_ticket_wrapper .main_msg .body {
  padding: 25px 30px;
}

.new_ticket_wrapper .main_msg .body .user_details {
  display: flex;
  justify-content: space-between;
}

.new_ticket_wrapper .main_msg .body .user_details label {
  color: #313340;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.new_ticket_wrapper .main_msg .body .user_details span {
  color: #4a4e61;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
}

.new_ticket_wrapper .main_msg .body .msg_text {
  margin-bottom: 0;
}

.new_ticket_wrapper .message_replies .main_msg {
  margin-bottom: 20px;
}

.new_ticket_wrapper .message_replies .main_msg .body {
  padding-bottom: 15px;
}

.res-body {
  position: relative;
}

.message_replies .message-replies-scroll {
  height: 100%;
  max-height: 400px;
}

.message_replies .scrollbar {
  background: #005d89 !important;
  width: 5px !important;
}

.message_replies .scrollarea .scrollbar-container.vertical {
  background: #f99d204a;
  width: 7px !important;
  opacity: 1 !important;
}

.table_action_wrapper {
  position: relative;
}

.table_action_wrapper .las {
  padding: 5px;
}

.table_action_wrapper .las_success {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.table_action_wrapper .table_Action_menu {
  position: absolute;
  z-index: 10;
  left: -60px;
  padding: 0 20px;
  margin: 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
}

.table_action_wrapper .table_Action_menu li {
  margin: 10px 0;
}

.table_action_wrapper .table_Action_menu li span {
  font-weight: bold;
}

.step_wrapper {
  padding: 25px;
}

.grid_row {
  display: flex;
}

.grid_row .left_grid {
  max-width: 360px;
  width: 100%;
  margin-right: 15px;
}

.grid_row .right_grid {
  margin-left: 15px;
  width: 100%;
}

.top_card .main_card_test {
  max-width: 300px;
}

.top_card .main_card_test .side_card ul li {
  height: 47px;
}

.top_card .main_card_test .side_card ul li:first-child {
  height: 25px;
}

.main_card_test {
  display: flex;
  flex: 1;
}

.main_card_test .side_card {
  margin-right: 5px;
}

.main_card_test .side_card .header {
  width: 50px;
  padding: 20px 0 10px 0;
  text-align: center;
}

.main_card_test .side_card .header img {
  height: 16px;
  width: 16.67px;
}

.main_card_test .side_card ul li {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}

.main_card_test .side_card ul li .row_count {
  color: rgba(38, 39, 49, 0.61);
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.main_card_test .bottom_card .side_card .header {
  width: 30px;
  padding: 20px 0 10px 0;
  text-align: center;
}

.clean_list li {
  display: flex;
}

.clean_list li b {
  min-height: 50px;
}

.clean_list li label {
  font-weight: bold;
  flex: 1;
  line-height: 15px;
}

.clean_list li label:first-child {
  margin-right: 10px;
  white-space: pre;
}

.clean_list li label:last-child {
  padding-left: 40px;
}

.clean_list li input[type="text"] {
  justify-content: space-between;
}

.clean_list li input[type="text"]:first-child {
  margin-right: 10px;
}

.cleaning_wrapper {
  padding: 30px 0;
}

.cleaning_wrapper h4 {
  padding-left: 17px;
}

.cleaning_wrapper .card {
  position: relative;
}

.cleaning_wrapper .card .paw_icon {
  position: absolute;
  left: -30px;
  height: 16px;
}

.cleaning_wrapper .top_card .card {
  width: 265px;
  margin-right: 20px;
}

.cleaning_wrapper .top_card .card .card-title {
  padding: 10px 20px;
  border-bottom: 0;
}

.cleaning_wrapper .top_card .card .card-body {
  padding: 0 20px 20px 20px;
}

.cleaning_wrapper .top_card .clean_list li {
  position: relative;
  counter-reset: section;
}

.cleaning_wrapper .bottom_card {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  flex-basis: 50%;
}

.cleaning_wrapper .bottom_card .main_card_test .side_card ul {
  padding-top: 0;
}

.cleaning_wrapper .bottom_card .main_card_test .side_card ul li {
  margin-bottom: 7px;
}

.cleaning_wrapper .bottom_card .sider {
  width: 100%;
  margin-right: 30px;
}

.cleaning_wrapper .bottom_card .sider .card {
  border: 2px dashed #ff681d;
}

.cleaning_wrapper .bottom_card .sider .card-body {
  display: flex;
  padding: 10px 20px 20px 20px;
}

.cleaning_wrapper .bottom_card .sider .card-body .clean_list:nth-child(2) {
  margin: 0 30px;
}

.cleaning_wrapper .bottom_card .sider:last-child .card {
  border: 2px dashed #c1d82f;
}

.cleaning_wrapper .main_card_test .grid_col {
  margin-right: 10px;
}

.cleaning_wrapper .new_price_grid_list {
  display: flex;
}

.cleaning_wrapper .new_price_grid_list input[type="text"] {
  margin-bottom: 5px;
}

.cleaning_wrapper .new_price_grid_list .left {
  margin-right: 5px;
}

.cleaning_wrapper .new_price_grid_list .right {
  margin-left: 5px;
}

.customized_wrapper .card {
  width: 786px;
}

.initial_cleaning_wrapper {
  display: flex;
}

.initial_cleaning_wrapper .card-body {
  padding: 30px;
}

.initial_cleaning_wrapper .calendar_wrapper {
  flex: 1;
  margin-right: 30px;
}

.initial_cleaning_wrapper .calendar_wrapper .ant-picker-body {
  padding: 0;
}

.initial_cleaning_wrapper .calendar_wrapper .calendar_header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.initial_cleaning_wrapper .calendar_wrapper .calendar_header h5 {
  color: #1c1d21;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.initial_cleaning_wrapper .calendar_wrapper .calendar_header .header_action {
  display: flex;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .calendar_header
  .header_action
  .ico-btn {
  border: 1px solid #ececf2;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  height: 46px;
  width: 45.29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .calendar_header
  .header_action
  .ico-btn:first-child {
  margin-right: 6px;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .calendar_header
  .header_action
  .ico-btn
  i {
  color: #6f6f6f;
}

.initial_cleaning_wrapper .calendar_wrapper .ant-picker-calendar-date-content {
  display: flex;
  align-items: flex-end;
}

.initial_cleaning_wrapper .calendar_wrapper .ant-picker-calendar {
  background-color: #ffffff;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content {
  border: 1px solid #ececf2;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  thead {
  background-color: #f5f5fa;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  thead
  th {
  padding: 13px;
  color: #8181a5;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  td:last-child
  .ant-picker-calendar-date {
  border-right: 0;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  td:first-child
  .ant-picker-calendar-date {
  border-left: 0;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  td
  .ant-picker-calendar-date {
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body
  .ant-picker-content
  td
  .ant-picker-calendar-date-value {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-align: right;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-cell {
  color: #8181a5;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .ant-picker-cell-in-view {
  color: #1c1d21;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .events
  li
  span {
  border-radius: 6px;
  background-color: rgba(255, 182, 77, 0.2);
  color: #ff681d;
  font-size: 10px;
  font-weight: bold;
  line-height: 21px;
  padding: 5px;
  display: flex;
  white-space: pre;
}

.initial_cleaning_wrapper
  .calendar_wrapper
  .ant-picker-calendar
  .events
  li
  span
  i {
  align-self: center;
  font-size: 10px;
}

.initial_cleaning_wrapper .form_wrapper {
  width: 360px;
}

.initial_cleaning_wrapper .form_wrapper b {
  margin-bottom: 20px;
  display: block;
}

.setup_option_wrapper {
  display: flex;
  flex-direction: row;
}

.setup_option_wrapper .left_side {
  width: 65%;
  flex-basis: 65%;
  margin-right: 30px;
}

.setup_option_wrapper .left_side .card-body {
  padding-right: 80px;
}

.setup_option_wrapper .right_side {
  width: 35%;
  flex-basis: 35%;
}

.setup_option_wrapper .stripe_logo {
  height: 60px;
  margin-bottom: 10px;
}

.setup_option_wrapper p {
  color: #1f1f25;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 22px;
}

.setup_option_wrapper .quickbook_list {
  padding-bottom: 20px;
}

.setup_option_wrapper .quickbook_list li {
  color: #1f1f25;
  font-size: 14px;
  line-height: 22px;
  font-family: "Lato", sans-serif;
  padding-left: 10px;
  position: relative;
}

.quickbook_list a {
  text-decoration: underline !important;
}

.setup_option_wrapper .form_section {
  margin-top: 20px;
}

.setup_option_wrapper .form_section h4 {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.setup_option_wrapper .form_section p {
  color: #1f1f25;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.setup_option_wrapper .form_section p .text-success {
  color: rgb(0, 93, 137);
  font-weight: bold;
}

.setup_option_wrapper .stripe_Card .stripe_logo {
  height: 40px;
}

.setup_option_wrapper .stripe_Card p {
  color: #1f1f25;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.no-border {
  border: none !important;
}

.mt-10 {
  margin-top: 10px;
}

.btn-small {
  padding: 4px 12px;
}

.relative {
  position: relative;
}

.pac-target-input {
  background: #f4f7f9;
}

.add_zipcode_icon {
  position: absolute;
  right: 8px;
  top: 30px;
  cursor: pointer;
  background: #f4f7f9;
  padding: 8px 0 8px 8px;
  font-size: 16px;
}

.zip_code_list {
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px;
}

.zip_code_list b {
  color: #1e2025;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.zip_code_list ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.zip_code_list ul li {
  color: #1e2025;
  font-size: 13px;
  line-height: 30px;
  border-bottom: 1px solid #efeff8;
  display: flex;
  flex-grow: 1;
  flex: 1 0 30%;
}

.zip_code_list ul li i {
  color: #1e2025;
  cursor: pointer;
  font-size: 14px;
  align-self: center;
  padding-left: 3px;
}

.ant-table-row {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(28, 29, 33, 0.5) !important;
  opacity: 1 !important;
  font-weight: 700 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(28, 29, 33, 0.5) !important;
  opacity: 1 !important;
  font-weight: 700 !important;
}

::placeholder {
  color: rgba(28, 29, 33, 0.5) !important;
  opacity: 1 !important;
  font-weight: 700 !important;
}

.pointer {
  cursor: pointer;
}

.dwhite {
  background: #ffffff !important;
}

.dropdown_date_range .ant-picker-range-separator {
  display: none;
}

.dropdown_date_range
  .ant-picker-panel-container
  .ant-picker-panels
  .ant-picker-panel
  .ant-picker-header {
  background-color: #ffffff;
  padding: 0 20px;
  border: none;
}

.dropdown_date_range
  .ant-picker-panel-container
  .ant-picker-panels
  .ant-picker-panel
  .ant-picker-header
  .ant-picker-header-super-prev-btn,
.dropdown_date_range
  .ant-picker-panel-container
  .ant-picker-panels
  .ant-picker-panel
  .ant-picker-header
  .ant-picker-header-super-next-btn {
  display: none;
}

.dropdown_date_range
  .ant-picker-panel-container
  .ant-picker-panels
  .ant-picker-panel
  .ant-picker-content
  th {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner {
  background-color: #ff681d;
  border-radius: 100%;
}

.dropdown_date_range
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.dropdown_date_range
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #ff681d;
  border-radius: 100%;
}

.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
  border-top: none;
  border-bottom: none;
  background: #f6f9e1;
}

.dropdown_date_range
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.dropdown_date_range
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
    .ant-picker-cell-range-hover-edge-end-near-range
  )::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: none;
}

.dropdown_date_range
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.dropdown_date_range
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  )::after,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: none;
}

.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.dropdown_date_range
  .ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.dropdown_date_range
  .ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.dropdown_date_range .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.dropdown_date_range
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.dropdown_date_range
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: #f6f9e1;
  color: #1c1d21;
}

.dropdown_date_range .ant-picker-cell .ant-picker-cell-inner {
  color: #1c1d21;
}

.dropdown_date_range
  .ant-picker-date-panel
  .ant-picker-content
  td.ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-inner {
  color: #1c1d21;
}

.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner,
.dropdown_date_range
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  color: #fff;
  border-radius: 100%;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.ant-picker-footer {
  background-color: #f5f5fa;
}

.account_module_wrapper {
  display: flex;
}

.customer_list .status_type .ant-select-selector .ant-select-selection-item {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.customer_list
  .status_type
  .ant-select-selector
  .ant-select-selection-item::before {
  content: "";
}

.customer_list
  .status_type
  .ant-select-selector
  .ant-select-selection-item:first-child::before {
  content: "Status: ";
}

.customer_list
  .status_type
  .ant-select-selector
  .ant-select-selection-item:after {
  content: ",";
  padding-right: 5px;
  margin-left: -3px;
}

.customer_list
  .status_type
  .ant-select-selector
  .ant-select-selection-item-remove {
  display: none;
}

.customer_list .filter_type .ant-select-selection-placeholder {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.customer_list .filter_type .ant-select-selection-placeholder::before {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.customer_list .filter_type .ant-select-selection-item {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.customer_list .filter_type .ant-select-selection-item::before {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.account_module_wrapper .fixed_sidebar .summry_date_picker:before {
  content: "Viewing Summary For ";
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  white-space: pre;
}

.account_module_wrapper
  .fixed_sidebar
  .summry_date_picker
  .ant-picker-input
  input {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.account_module_wrapper
  .fixed_sidebar
  .summry_date_picker
  .ant-picker-suffix
  i {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.account_module_wrapper .ant-select .las,
.account_module_wrapper .ant-select .la,
.account_module_wrapper .ant-picker .las,
.account_module_wrapper .ant-picker .la {
  font-size: 14px;
  font-weight: bold;
  color: #6f6f6f;
}

.account_module_wrapper .ant-select-selection-placeholder {
  opacity: 1;
}

.account_module_wrapper .date_ranger::before {
  content: "Invoice Date: ";
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
  white-space: pre;
  font-weight: bold;
}

.account_module_wrapper .work-orders-range::before {
  content: "Work Order Date: ";
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
  white-space: pre;
  font-weight: bold;
}

.account_module_wrapper .due_ranger::before {
  content: "Due Date: ";
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
  white-space: pre;
  font-weight: bold;
}

.account_module_wrapper .filter_type .ant-select-selection-placeholder {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.account_module_wrapper .filter_type .ant-select-selection-placeholder::before {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.account_module_wrapper .filter_type .ant-select-selection-item {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.account_module_wrapper .filter_type .ant-select-selection-item::before {
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
}

.account_module_wrapper .franchise_type {
  position: relative;
}

.account_module_wrapper
  .franchise_type
  .ant-select-selection-placeholder::before {
  content: "Franchise: ";
  font-weight: 600;
}

.account_module_wrapper .franchise_type .ant-select-selection-item::before {
  content: "Franchise: ";
  font-weight: 600;
}

.account_module_wrapper .bill_type {
  position: relative;
}

.account_module_wrapper .bill_type .ant-select-selection-placeholder::before {
  content: "Billing Type: ";
  font-weight: 600;
}

.account_module_wrapper .bill_type .ant-select-selection-item::before {
  content: "Billing Type: ";
  font-weight: 600;
}

.account_module_wrapper .customer_type {
  position: relative;
}

.account_module_wrapper
  .customer_type
  .ant-select-selection-placeholder::before {
  content: "Customer Type: ";
  font-weight: 600;
}

.account_module_wrapper .customer_type .ant-select-selection-item::before {
  content: "Customer Type: ";
  font-weight: 600;
}

.account_module_wrapper .status_type .ant-select-selection-placeholder::before {
  content: "Status: ";
}

.account_module_wrapper .status_type .ant-select-selector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item::before {
  content: "";
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item:first-child::before {
  content: "Status: ";
  font-weight: 600;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item:after {
  content: ",";
  padding-right: 5px;
  margin-left: -3px;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item-remove {
  display: none;
}

.account_module_wrapper .single_customer .ant-select-selection-placeholder {
  padding-left: 85px;
}

.account_module_wrapper
  .single_customer
  .ant-select-selection-placeholder::before {
  content: "Single Customer: ";
}

.account_module_wrapper .single_customer .ant-select-selection-item::before {
  content: "Single Customer: ";
}

.account_module_wrapper .franchise_filter .ant-select-selection-placeholder {
  /* padding-left: 85px; */
}

.account_module_wrapper
  .franchise_filter
  .ant-select-selection-placeholder::before {
  content: "Franchise: ";
  font-weight: 600;
}

.account_module_wrapper .franchise_filter .ant-select-selection-item::before {
  content: "Franchise: ";
  font-weight: 600;
}

.account_module_wrapper
  .customer_type_filter
  .ant-select-selection-placeholder {
  padding-left: 85px;
}

.account_module_wrapper
  .customer_type_filter
  .ant-select-selection-placeholder::before {
  content: "Customer Type: ";
  font-weight: 600;
}

.account_module_wrapper
  .customer_type_filter
  .ant-select-selection-item::before {
  content: "Customer Type: ";
  font-weight: 600;
}

.account_module_wrapper
  .invoice_status_filter
  .ant-select-selection-placeholder {
  padding-left: 85px;
}

.account_module_wrapper
  .invoice_status_filter
  .ant-select-selection-placeholder::before {
  content: "Invoice Status: ";
}

.account_module_wrapper
  .invoice_status_filter
  .ant-select-selection-item::before {
  content: "Invoice Status: ";
}

.account_module_wrapper
  .service_status_filter
  .ant-select-selection-placeholder {
  padding-left: 85px;
}

.account_module_wrapper
  .service_status_filter
  .ant-select-selection-placeholder::before {
  content: "Service Type: ";
  font-weight: 600;
}

.account_module_wrapper
  .service_status_filter
  .ant-select-selection-item::before {
  content: "Service Type: ";
  font-weight: 600;
}

.account_module_wrapper
  .service_type_filter
  .ant-select-selection-item::before {
  content: "Service Type: ";
}

.account_module_wrapper
  .status_filter
  .ant-select-selection-placeholder::before {
  content: "Status: ";
}

.account_module_wrapper .status_filter .ant-select-selection-item::before {
  content: "Status: ";
}

.account_module_wrapper .sort_by .ant-select-selection-placeholder {
  padding-left: 85px;
}

.account_module_wrapper .sort_by .ant-select-selection-placeholder::before {
  content: "Sort By: ";
}

.account_module_wrapper .sort_by .ant-select-selection-item::before {
  content: "Sort By: ";
}

.account_module_wrapper .sort-by-border .ant-select-selector {
  border: 1px solid #ebebeb !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  background-image: none !important;
}

.account_module_wrapper .fixed_header {
  background-color: #f1f1f5;
  padding: 15px 30px;
  width: 100%;
}

.account_module_wrapper .fixed_header ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.account_module_wrapper .fixed_header ul li {
  margin-right: 10px;
}

.account_module_wrapper .fixed_header ul li:last-child {
  margin-right: 0;
}

.account_module_wrapper .data_wrapper {
  width: 70%;
}

.table_inner_filter_select {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(198, 198, 198, 0.5);
}

.table_inner_filter_select li {
  padding: 5px 10px;
  color: #1e2025;
  font-size: 13px;
  line-height: 30px;
  cursor: pointer;
}

.table_inner_filter_select li:hover {
  background-color: #c1d82f4d;
}

.table_inner_filter_select .active {
  font-weight: 900;
  background-color: #c1d82f4d;
}

.table_inner_filter_select .active:before {
  content: "\f00c";
  margin-right: 10px;
  font-weight: 900;
  font-family: "Line Awesome Free";
}

.table_inner_filter_form {
  width: 215px;
  padding: 5px 10px;
}

.table_inner_filter_form .header {
  background-color: rgba(245, 245, 250, 0.5);
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
}

.table_inner_filter_form input[type="text"] {
  height: 30px;
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
}

.table_inner_filter_form ::-webkit-input-placeholder {
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  opacity: 1;
  font-weight: 700;
}

.table_inner_filter_form :-ms-input-placeholder {
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  opacity: 1;
  font-weight: 700;
}

.table_inner_filter_form ::placeholder {
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  opacity: 1;
  font-weight: 700;
}

.table_inner_filter_form .footer {
  background-color: rgba(245, 245, 250, 0.5);
  padding: 5px 10px;
  text-align: right;
}

.table_inner_filter_form .footer .small-btn {
  padding: 3px 10px;
}

.ant-select-item-option-state {
  display: none;
}

.log_card {
  border: 1px solid #3e8883;
  border-radius: 4px;
  background-color: #f4f7f9;
  padding: 15px;
}

.log_card label {
  font-weight: bold;
  color: #22222a;
}

.twhite {
  background-color: #fff !important;
}

.p-30 {
  padding: 0 30px;
}

.log_payment_drawer .text_with_place {
  position: relative;
}

.log_payment_drawer .text_with_place .fix_isd_code {
  position: absolute;
  top: 26px;
  left: -7px;
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.text_with_place {
  position: relative;
}

.text_with_place .fix_isd_code {
  position: absolute;
  top: -4px;
  left: -8px;
  color: #1e2025;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.account_expired_card .breadcrumb {
  padding: 10px 0 10px 25px;
}

.account_expired_card .breadcrumb .las {
  font-size: 12px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  background: transparent !important;
  text-align: center;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-top: 2px solid #eeeeee !important;
}

.event-scroll {
  height: 100%;
  max-height: 400px;
  width: 100%;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  background: transparent;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: #1c1d21;
}

.bg-transparent {
  background: transparent !important;
}

.static_table {
  background-color: #fff;
}

.static_table thead tr th {
  border-top: 1px solid #f0f2f2;
  border-bottom: 1px solid #f0f2f2;
  background-color: rgba(245, 245, 250, 0.4);
}

.simple_select {
  min-width: 100px !important;
}

.simple_select .ant-select-selector {
  padding: 0 !important;
  height: 27px !important;
  background: transparent !important;
  font-size: 12px !important;
}

.calendar_header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.calendar_header .header_action {
  display: flex;
}

.calendar_header .header_action .ico-btn {
  border: 1px solid #ececf2;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  height: 46px;
  width: 45.29px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar_header .header_action .ico-btn:first-child {
  margin-right: 6px;
}

.calendar_header .header_action .ico-btn.batch_button {
  border-radius: 3px;
  color: #005d89;
  border-color: #005d89;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  width: 110px;
  margin-left: 15px;
}

.calendar_header .header_action .ico-btn.confirm_batch_button {
  border-radius: 3px;
  color: #fff;
  background-color: #ff681d;
  border-color: #ff681d;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  width: 110px;
  margin-left: 15px;
}

.calendar_header .header_action .add_icon {
  background-color: #ff681d;
  margin-left: 15px;
}

.calendar_header .header_action .complete_icon {
  background-color: #005d89;
  margin-left: 5px;
}

.calendar_header .header_action .cancel_icon {
  background-color: #e02020;
  margin-left: 5px;
}

.calendar_header .header_action .block_icon {
  color: #ffffff;
  border-radius: 3px;
  background-color: #e02020;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  width: auto;
  padding: 0 15px;
  margin-left: 15px;
}

.field_date_picker {
  background-color: #ffffff !important;
}

.field_date_picker input {
  color: #1e2025;
  font-size: 13px;
}

.field_date_picker::before {
  content: "Viewing Day : ";
  color: #1e2025;
  font-size: 13px;
  line-height: 25px;
  white-space: pre;
  font-weight: bold;
}

.p15 {
  padding: 15px;
}

.reschedule_box {
  border: 1px solid #ececf2;
  border-radius: 4px;
  background-color: #f4f7f9;
  padding: 15px;
}

.reschedule_box p {
  color: #1c1d2180;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

.reschedule_service_wrapper .footer {
  width: 420px !important;
}

.reschedule_service_wrapper h4 {
  margin-bottom: 10px;
}

.reschedule_service_wrapper .subject {
  color: #8181a5;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
}

.reschedule_service_wrapper .name {
  margin-bottom: 10px;
}

.reschedule_service_wrapper .service_list {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reschedule_service_wrapper .service_list li label {
  color: #22232a;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
  display: block;
  margin-bottom: 0;
}

.reschedule_service_wrapper .service_list li span {
  color: #252730;
  font-size: 13px;
  line-height: 21px;
}

.reschedule_service_wrapper .sub_section h4 {
  color: #22232a;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 0;
}

.reschedule_service_wrapper .sub_section .list {
  display: flex;
  justify-content: space-between;
}

.reschedule_service_wrapper .sub_section .list label {
  font-size: 13px;
  line-height: 21px;
}

.reschedule_service_wrapper .sub_section .list span {
  color: #252730;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
}

.fc-timegrid-event .fc-event-main {
  border-radius: 6px;
}

.yellow b {
  color: #ff681d;
}

.yellow span {
  color: #ff681d;
}

.green b {
  color: #3d7472;
}

.green span {
  color: #3d7472;
}

.light_green b {
  color: #adc12a;
}

.light_green span {
  color: #adc12a;
}

.disabledDates {
  background-color: #82849f;
  color: #ffffff;
  cursor: default;
}

.fc .fc-scrollgrid-liquid {
  border: none !important;
}

.fc-theme-standard th {
  background-color: #f5f5fa;
  border: none !important;
}

.fc .fc-col-header-cell-cushion {
  padding: 12px 0 !important;
  color: #8181a5;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
}

.fc-scrollgrid tbody .fc-scrollgrid-section:first-child {
  display: none;
}

.fc-scrollgrid .fc-scrollgrid-section:nth-child(2) {
  display: none;
}

.fc .fc-timegrid-now-indicator-line {
  height: 2px;
  background-color: #ff681d;
  border-color: var(--fc-now-indicator-color, #ff681d) !important;
}

.fc .fc-timegrid-now-indicator-arrow {
  background-color: #ff681d;
  border-color: var(--fc-now-indicator-color, #ff681d) !important;
  width: 100px;
  height: 2px;
  border-width: 0 !important;
  margin-top: 0 !important;
}

.fc .fc-timegrid-now-indicator-arrow:after {
  content: " ";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #ff681d;
  top: -4px;
  border-radius: 50%;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff !important;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: hidden !important;
}

.fc .fc-timegrid-slot-label {
  border: 1px solid transparent;
}

.fc .fc-scrollgrid {
  border: none !important;
}

.fc .fc-scrollgrid-section-header td {
  border: none;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  transform: translate(0, 7px);
  color: #8181a5;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.calendar_wrapper .calendar_header h5 {
  color: #1c1d21;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 0;
}

.block_day {
  background-color: rgba(224, 32, 32, 0.05) !important;
  color: #e02020 !important;
}

.block_day:before {
  background: #e02020 !important;
  left: 0% !important;
  right: 0% !important;
}

.block_day i {
  color: #e02020 !important;
  font-size: 16px;
  cursor: pointer;
}

.block_day_label {
  color: #e02020;
  background-color: rgba(224, 32, 32, 0.05) !important;
  font-size: 12px;
  font-weight: 900;
  padding: 5px 10px;
  line-height: 15px;
  transform: translateY(-2px);
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.day-name {
  position: relative;
}

.day-name:hover .time_tool_tip {
  display: block;
}

.day-name .time_tool_tip {
  display: none;
  min-width: 210px;
  max-width: 250px;
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(198, 198, 198, 0.5);
  position: absolute;
  z-index: 100;
  padding: 20px;
  text-align: left;
  right: 0;
  top: 100%;
}

.day-name .time_tool_tip label {
  color: #8181a5;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  margin-bottom: 0;
}

.day-name .time_tool_tip p {
  color: #1c1d21;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}

.ant-table-tbody .ant-table-row:last-child .day-name .time_tool_tip {
  bottom: 100%;
  top: auto;
}

.danger_quote {
  background-color: rgba(209, 38, 25, 0.2) !important;
  border-top-color: #d12619 !important;
}

.sprice {
  white-space: pre;
}

.stype {
  text-transform: capitalize;
}

.resource_tooltip_wrapper i {
  cursor: pointer;
}

.resource_tooltip_wrapper i:hover .custome_tooltip {
  display: block;
}

.resource_tooltip_wrapper .custome_tooltip {
  display: block;
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(198, 198, 198, 0.5);
  padding: 20px;
}

.resource_tooltip_wrapper .custome_tooltip .details {
  margin-bottom: 10px;
}

.resource_tooltip_wrapper .custome_tooltip .details label {
  color: #8181a5;
  font-size: 10px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  line-height: 12px;
  display: block;
  text-align: left;
}

.resource_tooltip_wrapper .custome_tooltip .details b {
  color: #1c1d21;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  line-height: 15px;
  display: block;
  text-align: left;
}

.event_item {
  display: flex;
  flex-direction: column;
}

.event_item b {
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.event_item span {
  font-size: 10px;
  line-height: 12px;
}

.event_details_wrapper {
  padding: 20px;
  max-width: 240px;
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(198, 198, 198, 0.5);
}

.event_details_wrapper .details {
  margin-bottom: 10px;
}

.event_details_wrapper .details label {
  color: #8181a5;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  display: block;
  margin-bottom: 0;
  text-transform: capitalize;
}

.event_details_wrapper .details span {
  color: #1c1d21;
  font-size: 12px;
  line-height: 15px;
  display: block;
  text-transform: capitalize;
}

.event_details_wrapper .details a {
  color: #1c1d21;
  font-size: 12px;
  line-height: 15px;
  display: block;
  text-transform: capitalize;
}

.event_details_wrapper .details .underline {
  text-decoration: underline;
}

.event_details_wrapper .footer {
  display: flex;
}

.event_details_wrapper .footer button {
  width: 100%;
  padding: 8px 10px;
  font-size: 13px;
}

.event_details_wrapper .footer button:first-child {
  color: #005d89;
  width: 114px;
  white-space: pre;
}

.ant-popover-arrow {
  display: none;
}

.single_Service_details_drawer .service_main_details {
  display: flex;
  margin-bottom: 20px;
}

.single_Service_details_drawer .service_main_details .left h4 {
  color: #22222a;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 0;
}

.single_Service_details_drawer .service_main_details .left span {
  color: #8181a5;
  font-size: 13px;
  font-weight: normal;
  line-height: 21px;
}

.single_Service_details_drawer .service_main_details .right {
  margin-left: 30px;
}

.single_Service_details_drawer .address_details h5 {
  padding-left: 0;
}

.single_Service_details_drawer .address_details h5::before {
  display: none;
}

.single_Service_details_drawer .address_details .active_service_list b,
.single_Service_details_drawer .address_details .active_service_list span {
  font-size: 13px;
}

.single_Service_details_drawer .sdetail .subs strong,
.single_Service_details_drawer .sdetail .subs p {
  font-size: 13px;
}

.new_err_msg {
  color: #e02020 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: flex-start;
  padding: 3px 0;
}

.new_err_msg.align-items-start i {
  margin-top: 2px;
}

.new_err_msg i {
  color: #e02020;
  font-size: 16px;
  padding-right: 5px;
  margin-top: 2px;
}

.disbale_btn {
  cursor: not-allowed;
  opacity: 0.4;
}

.StripeElement {
  border: none;
  border: 1px solid #f4f7f9;
  padding: 8px 16px;
  color: #1c1d21;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  height: auto;
  border-radius: 4px;
  background: #f4f7f9;
  line-height: 24px;
  transition: all 0.3s ease;
  background-color: #fff !important;
  margin-bottom: 10px !important;
}

.lh-21 {
  line-height: 21px;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.color-22232A {
  color: #22232a;
}

.color-252730 {
  color: #252730;
}

.color-005d89 {
  color: #005d89;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.btn-text {
  font-size: 7px;
  text-transform: uppercase;
  color: #fff;
  line-height: 8px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.dashboard_wrapper .details_section .right {
  width: 650px;
}

.chart_bottom ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.chart_bottom ul li {
  list-style-type: none;
  flex: auto;
  max-width: 25%;
}

.scheduled_ticket_section {
  display: flex;
}

.scheduled_ticket_section .scheduled {
  width: 100%;
  margin-right: 10px;
}

.scheduled_ticket_section .scheduled .ant-select {
  width: 150px;
}

.scheduled_ticket_section .ticket {
  width: 100%;
  margin-left: 10px;
}

.scheduled_ticket_section .ticket .ant-select {
  width: 150px;
}

.scheduled_ticket_section .low i {
  background: #3ba93f;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.scheduled_ticket_section .greenbox {
  border: 1px solid rgb(0, 93, 137);
  background-color: rgba(64, 121, 119, 0.05);
}

.scheduled_ticket_section .greenbox h2 {
  color: rgb(0, 93, 137);
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
}

.status_before {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.ant-picker-calendar-date-content {
  margin-right: -10px;
}

.scrollarea-content {
  padding-right: 10px !important;
}

.ml-10 {
  margin-left: 10px;
}

.align-items-center {
  align-items: center;
}

.font-24 {
  font-size: 24px;
}

.align-items-start {
  align-items: flex-start;
}

.lh-20 {
  line-height: 20px !important;
}

.text-nowrap {
  white-space: nowrap;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item {
  position: relative;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item:first-child:after {
  display: none;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item
  + .ant-select-selection-item:after {
  position: absolute;
  top: 0;
  left: 2px;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-item
  .ant-select-selection-item-content {
  padding-left: 6px;
  margin-right: 0;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-overflow {
  flex-wrap: nowrap;
  display: flex;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item
  + .ant-select-selection-overflow-item
  .ant-select-selection-item:before {
  content: "" !important;
}

.account_module_wrapper
  .status_type
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item
  + .ant-select-selection-overflow-item
  .ant-select-selection-item:after {
  content: ",";
  padding-right: 5px;
  margin-left: -3px;
  position: absolute;
  top: 0;
  left: 2px;
  display: inline-block !important;
}

.rating img.grayy {
  filter: grayscale(100%);
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td,
.ant-picker-week-panel-row:hover td {
  background-color: transparent;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner,
.ant-picker-week-panel-row
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-week-panel-row
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: #1c1d21;
}

.ant-picker-content th,
.ant-picker-content td {
  text-align: center;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(0, 0, 0, 0.25);
}

.ant-picker-week-panel-row
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner {
  color: #fff !important;
  border-radius: 100%;
}

.ant-picker-week-panel-row-selected .ant-picker-cell-in-view::before,
.ant-picker-week-panel-row:hover .ant-picker-cell-in-view::before {
  background-color: #f6f9e1;
}

.ant-picker-week-panel-row-selected .ant-picker-cell::before,
.ant-picker-week-panel-row:hover .ant-picker-cell::before {
  background-color: #f6f9e1;
}

.ant-picker-week-panel-row
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner {
  color: rgba(0, 0, 0) !important;
  border-radius: 100%;
}

.ant-picker-week-panel-row
  .ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-selected
  .ant-picker-cell-inner {
  color: #fff !important;
  border-radius: 100%;
}

.ant-picker-week-panel-row .ant-picker-cell-week,
.ant-picker-week-panel th:first-child {
  display: none !important;
}

.residential {
  border-color: #3d7573 !important;
  background-color: #d4e5e4 !important;
  color: #3d7573 !important;
  border-width: 0 !important;
  border-radius: 5px;
}

.residential .cancelled,
.residential .completed {
  border-color: rgba(61, 117, 115, 0.7) !important;
}

.fc-v-event .fc-event-main {
  color: inherit !important;
  padding: 5px !important;
}

.commercial {
  border-color: #fbbb65 !important;
  background-color: #feebd2 !important;
  color: #fbbb65 !important;
  border-width: 0 !important;
  border-radius: 5px;
}

.commercial .cancelled,
.commercial .completed {
  border-color: rgba(251, 187, 101, 0.7) !important;
}

.pws {
  background-color: #eff3d4 !important;
  border-color: #d3e180 !important;
  color: #afc330 !important;
  border-width: 0 !important;
  border-radius: 5px;
}

.pws .cancelled,
.pws .completed {
  border-color: rgba(211, 225, 128, 0.7) !important;
}

.completed,
.cancelled {
  opacity: 0.7;
  border-width: 2px !important;
}

.selected {
  border-width: 2px !important;
}

.event-info .ant-popover-inner-content {
  padding: 0px;
}

.event-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0px !important;
}

.searched {
  text-decoration: underline !important;
}

.filtered {
  opacity: 0.7;
}

.edit-hours-info {
  border: none;
  background: none;
  color: rgb(0, 93, 137);
}

.see-more {
  text-decoration: underline !important;
  border: none;
  background: none;
  font-weight: bold;
}

.batch-select {
  border-color: blue !important;
}

.view-breakdown {
  color: rgb(0, 93, 137);
  font-size: 13px;
  line-height: 21px;
  display: none;
  text-decoration: underline;
}

.ant-table-row:hover .view-breakdown {
  display: inline-block;
}

.fc-col-header tr {
  display: flex;
}

.fc-col-header .fc-col-header-cell.fc-resource {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.fc-col-header .fc-col-header-cell.fc-resource .la-info-circle:before {
  margin-left: 5px;
}

.fc-col-header .fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
  cursor: pointer;
}

.complaint-status {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 10px;
  display: inline-block;
}

.positive-status {
  background-color: #005d89;
}

.negative-status {
  background-color: red;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

.btn-resize {
  padding: 8px 18px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fc-col-header .fc-timegrid-axis {
  min-width: 40px;
}

.filter-resource {
  display: flex;
  flex: 1;
  min-height: 40px;
  align-items: center;
  padding: 10px;
  color: #a7a7c1;
}

.filter-resource > div {
  text-align: left;
}

.filter-resource .customer-name {
  flex: 20%;
}

.filter-resource .contact {
  flex: 16%;
}

.filter-resource .time {
  flex: 16%;
}

.filter-resource .service-address {
  flex: 20%;
}

.filter-resource .service-status {
  flex: 16%;
}

.filter-resource .journey-time {
  flex: 12%;
}

.d-flex .customer-name {
  flex: 20%;
}

.d-flex .contact {
  flex: 16%;
}

.d-flex .time {
  flex: 16%;
}

.d-flex .service-address {
  flex: 20%;
}

.d-flex .service-status {
  flex: 16%;
  text-transform: capitalize;
}

.d-flex .journey-time {
  flex: 12%;
}

.event-content-resize {
  flex: 1;
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 5px !important;
}

.field_search_bar .ant-select-single {
  color: #1c1d21;
}

.single-resource-label {
  display: flex;
}

div[data-value] {
  position: relative;
}

div[data-value]:after {
  content: attr(data-value) "%";
  position: absolute;
  top: 10px;
  /* Borders */
  left: 10px;
  /* Borders */
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

div[data-value] input {
  color: #fff;
  /* Optional bug avoid visual bugs */
}

.light-purple {
  color: #908fb2;
}

.filter_wrapper
  .ant-select-multiple:not(.ant-select-customize-input)
  .ant-select-selector,
.filter_wrapper
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 35px;
  background-color: #fff;
}

.sched-clean.ant-table-wrapper .ant-table-tbody td,
.sched-clean.ant-table-wrapper .ant-table-thead th {
  padding-left: 30px;
  padding-right: 30px;
}

.footer-availability.sticky-btn-grp {
  background-color: #fff;
  position: fixed;
  width: 100%;
  text-align: right;
  padding: 10px 30px 10px 100px;
  z-index: 9;
  left: 0;
  bottom: 0;
}

.badge-setting {
  position: relative;
}

.badge-grp {
  display: flex;
  justify-content: flex-start;
}

.setting-popover {
  display: none;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(198, 198, 198, 0.5);
  min-width: 187px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  z-index: 11;
}

.badge-setting:hover .setting-popover {
  display: block;
}

.badge-setting-icon {
  width: 20px;
  height: 20px;
  display: block;
  background-image: url(../Assets/images/Icon-Settings.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.badge-setting .btn {
  padding: 4px 13px;
}

.setting-popover .type {
  font-weight: 700;
  color: #1c1d21;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.setting-popover .type .spin-icon {
  font-size: 12px;
}

.setting-popover .spin-minus {
  margin-right: 14px;
}

.setting-popover .spin-plus {
  margin-left: 14px;
}

.flex-1 {
  flex: 1;
}

.gutter-10 .ant-select-selection-placeholder {
  color: #bfbfbf;
}

.dollar-sign {
  position: absolute;
  left: 16px;
  top: 50%;
  z-index: 1;
  font-weight: 700;
  transform: translateY(-50%);
}

.dollar-sign + .ant-input-number .ant-input-number-input {
  padding-left: 25px;
}

.ant-input-number {
  border: none;
  width: 100%;
}

.ant-input-number .ant-input-number-input:hover {
  border-color: rgb(0, 93, 137);
  box-shadow: none;
}

.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}

.add-service-btn {
  border: 1px solid #ececf2;
  padding: 10px 18px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.add-service-btn a {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.ord-serive-block {
  border: 1px solid #ececf2;
  border-radius: 3px;
}

.ord-serive-block .subs {
  padding: 10px;
  border-top: 1px solid #ececf2;
}

.ord-serive-block .ant-ico-del {
  color: #d12619;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}

.ord-serive-block .ant-ico-del .anticon.anticon-delete {
  margin-right: 8px;
}

.ord-serive-top {
  padding: 10px;
}

.mb-13 {
  margin-bottom: 13px;
}

.pr-5 {
  padding-right: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.serv-ord-total {
  border-top: 1px solid #e1e1e5;
  padding-top: 15px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
}

.cord-edit {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-weight: 700;
  font-size: 13px;
}

.service-textarea {
  height: 80px !important;
  background-color: #f4f7f9 !important;
}

.has_err_msg {
  margin-top: 5px;
  color: #1f1f25 !important;
  border: 2px solid #e02020;
  padding: 5px;
  color: #333;
  background: rgba(209, 38, 25, 0.1);
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.has_err_msg.align-items-start i {
  margin-top: 2px;
}

.has_err_msg i {
  color: #e02020;
  font-size: 16px;
  padding-right: 5px;
}

.has_success_msg {
  margin-top: 5px;
  border: 2px solid #3ba93f;
  padding: 5px;
  color: #1f1f25 !important;
  background: rgba(59, 169, 63, 0.1);
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.has_success_msg.align-items-start i {
  margin-top: 2px;
}

.has_success_msg i {
  color: #3ba940;
  font-size: 16px;
  padding-right: 5px;
}

.no-background {
  background-color: transparent !important;
}

.border-color {
  border: 1px solid #ebebeb !important;
}

.border-color input {
  border: none;
}

.custom-drop-down {
  margin: 0px;
  background: white;
}

.custom-drop-down .image-input {
  width: 170px !important;
  height: 42px !important;
}

.background-color {
  background-color: white !important;
}

.background-color input[type="text"] {
  background-color: white !important;
}

.ant-select-item-option[title="Forecast"],
.ant-select-item-option[title="Scheduled"],
.ant-select-item-option[title="Incomplete"],
.ant-select-item-option[title="Complete"],
.ant-select-item-option[title="Cancelled-Non-Billable"],
.ant-select-item-option[title="Cancelled - Billable"] {
  padding-left: 33px;
  font-size: 12px;
}

.ant-select-item-option[title="Forecast"]:before,
.ant-select-item-option[title="Scheduled"]:before,
.ant-select-item-option[title="Incomplete"]:before,
.ant-select-item-option[title="Complete"]:before,
.ant-select-item-option[title="Cancelled-Non-Billable"]:before,
.ant-select-item-option[title="Cancelled - Billable"]:before {
  content: "";
  position: absolute;
  left: 15px;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.ant-select-item-option[title="Forecast"]
  .ant-select-item-option-content:before,
.ant-select-item-option[title="Scheduled"]
  .ant-select-item-option-content:before,
.ant-select-item-option[title="Incomplete"]
  .ant-select-item-option-content:before,
.ant-select-item-option[title="Complete"]
  .ant-select-item-option-content:before,
.ant-select-item-option[title="Cancelled-Non-Billable"]
  .ant-select-item-option-content:before,
.ant-select-item-option[title="Cancelled - Billable"]
  .ant-select-item-option-content:before {
  display: none;
}

.ant-select-item-option[title="Forecast"]:before {
  background: #7a7a7a;
}

.ant-select-item-option[title="Scheduled"]:before {
  background: #6b81c1;
}

.ant-select-item-option[title="Complete"]:before {
  background: #005d89;
}

.ant-select-item-option[title="Incomplete"]:before {
  background: #f9a720;
}

.ant-select-item-option[title="Cancelled-Non-Billable"],
.ant-select-item-option[title="Cancelled - Billable"] {
  /*opacity: 0.5;*/
}

.ant-select-item-option[title="Cancelled-Non-Billable"]:before,
.ant-select-item-option[title="Cancelled - Billable"]:before {
  background: #d12619;
}

.custom-width {
  width: 170px !important;
}

.custom-input {
  max-width: 500px !important;
}

.max-width-84 {
  max-width: 84px !important;
}

.lh-16 {
  line-height: 16px;
}

.custom-text-muted {
  color: #22222a;
  font-size: 13px;
  opacity: 0.7 !important;
}

.custom-text-high-light {
  color: rgb(0, 93, 137);
}

.filter_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  background: #f1f1f5;
  align-items: center;
}

.filter_wrapper .left_side {
  display: flex;
}

.filter_wrapper .left_side .ant-select {
  color: #1e2025;
}

.filter_wrapper .left_side .ant-select .ant-select-selector {
  padding: 3px 11px;
  width: 230px;
}

.filter_wrapper_account_list .left_side .ant-select .ant-select-selector {
  width: 280px;
}

.filter_wrapper .right_side {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter_wrapper .form-group .fitem {
  margin-bottom: 0;
  margin: 0 0 10px 10px;
}

/* .filter_wrapper .form-group .fitem:first-child {
    margin-left: 0;
} */
.filter_wrapper .form-group .ant-select-single {
  min-width: 120px;
  max-width: 180px;
}

.filter_wrapper .ant-picker {
  height: 35px;
}

/*Tabs*/
.tabs li {
  /* margin: 0 0 0 10px; */
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px 21px;
  font-weight: 900;
  text-transform: uppercase;
  color: #2c3d53;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}

.tabs li.active:after,
.tabs li:hover:after,
.tabs li:focus:after {
  content: "";
  background-color: #005d89;
}

.tabs li {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.tabs li:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #005d89;
  height: 3px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.tabs li:hover:before,
.tabs li:focus:before,
.tabs li.active:before {
  left: 0;
  right: 0;
}

.tabs.dark {
  display: flex;
}

.tabs.dark li {
  background-color: #f6f8fa;
  border-radius: 0;
  color: #004566;
}

.tabs.dark li:first-child {
  margin-left: 0;
}

.tabs.white {
  display: flex;
}

.tabs.white li {
  background-color: #fff;
  border-radius: 0;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 12px 0;
  text-transform: none;
  margin-right: 20px;
}

.tabs.white li:first-child {
  margin-left: 0;
}

.tabs.mwhite {
  display: flex;
}

.tabs.mwhite li {
  border-radius: 0;
  color: #8181a5;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 10px;
  text-transform: none;
  margin-right: 2px;
  background-color: transparent;
  border: 1px solid transparent;
  margin-left: 2px;
}

.tabs.field-service li {
  border-radius: 0;
  color: #8181a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 10px;
  text-transform: none;
  margin-right: -3px;
  background-color: transparent;
  border: 1px solid gainsboro;
  margin-left: 2px;
}

.tabs.mwhite li:first-child {
  margin-left: 0;
}

.tabs.mwhite li:before,
.tabs.mwhite li:after {
  display: none;
}

.tabs.mwhite li:hover,
.tabs.mwhite li:focus,
.tabs.mwhite li.active {
  background-color: #fff;
  border: 1px solid #ececf2;
}

/*icon btns*/
.ico-btn {
  border: 1px solid #c4d6d6;
  border-radius: 3px;
  background-color: #fff;
  padding: 6px 9px;
  margin: 0 10px;
  cursor: pointer;
  display: block;
}

.ico-btn i {
  font-size: 19px;
  font-weight: bold;
  color: rgb(0, 93, 137);
}

.ico-btn:hover,
.ico-btn:focus {
  border-color: rgb(0, 93, 137);
}

.ico-btn:hover i,
.ico-btn:focus i {
  color-interpolation-filters: #000;
}

.btn-submit {
  margin-top: 15px;
  background: #fff;
  padding: 10px 20px;
  text-align: right;
}

/*Ant design form controls*/
.ant-select-single,
.ant-select-multiple {
  color: rgba(28, 29, 33, 0.5);
  font-weight: bold;
  width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  background-color: #f4f7f9;
  border: none;
  height: 42px;
  padding: 6px 11px;
}

.ant-select-single:not(.ant-select-disabled):focus .ant-select-selector,
.ant-select-multiple:not(.ant-select-disabled):focus .ant-select-selector {
  border: 1px solid rgb(0, 93, 137) !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-select-single:not(.ant-select-disabled):hover,
.ant-select-multiple:not(.ant-select-disabled):hover {
  border: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-select-single.ant-select-focused .ant-select-selector,
.ant-select-multiple.ant-select-focused .ant-select-selector {
  border: 1px solid rgb(0, 93, 137) !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-select-single.swhite .ant-select-selector,
.ant-select-multiple.swhite .ant-select-selector {
  background-color: #fff;
  border: none;
  padding: 6px 11px;
}

.ant-select-single.sorange .ant-select-selector,
.ant-select-multiple.sorange .ant-select-selector {
  color: #ec5301;
  background-color: transparent;
  border: #ec5301 1px solid;
  padding: 6px 11px;
}

.ant-select-single.sorange .ant-select-selector .ant-select-selection-item,
.ant-select-multiple.sorange .ant-select-selector .ant-select-selection-item {
  color: #ec5301;
}

.ant-select-single.sorange .ant-select-arrow,
.ant-select-multiple.sorange .ant-select-arrow {
  color: #fff;
}

.account_module_wrapper .ant-select.sorange .las,
.account_module_wrapper .ant-select.sorange .la,
.account_module_wrapper .ant-picker.sorange .las,
.account_module_wrapper .ant-picker.sorange .la {
  color: #fff;
}

.ant-select-single .ant-select-selection-item,
.ant-select-multiple .ant-select-selection-item {
  color: #1c1d21;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option:not(.ant-select-item-option-disabled):hover {
  background-color: rgba(193, 216, 47, 0.15) !important;
  color: #1c1d21 !important;
  font-weight: 600;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background-color: rgba(193, 216, 47, 0.15) !important;
  color: #1c1d21 !important;
  font-weight: 600;
}

.ant-select-selection-placeholder {
  color: #1c1d21;
}

.ant-select-dropdown.md-filter {
  width: 250px !important;
}

.ant-select-dropdown.sm-filter {
  width: 160px !important;
}

.ant-select-dropdown.xs-filter {
  width: 130px !important;
}

.ant-select-item-option-selected .ant-select-item-option-content {
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 18px;
}

.ant-select-item-option-selected .ant-select-item-option-content:before {
  content: "\f00c";
  margin-right: 10px;
  font-weight: 900;
  font-family: "Line Awesome Free";
}

.ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
  width: 100%;
  text-align: center;
}

/*left align icon*/
.datepicker .ant-picker-input > input {
  padding-left: 10px;
  order: 2;
}

.datepicker .ant-picker-input .ant-picker-suffix {
  margin-left: 0;
}

/*Ant checkbox*/
.ant-checkbox-inner {
  border: 1px solid #005d89;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #005d89;
  border-color: #005d89;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.aditem .fitem-check {
  font-weight: bold;
  color: #6f6f6f;
  margin-bottom: 2px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item:focus,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu-title:focus,
.aditem .fitem-check:hover,
.aditem .fitem-check:focus {
  background-color: rgba(193, 216, 47, 0.15);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1c1d21;
  background-color: rgba(193, 216, 47, 0.15);
}

.action-dropdown {
  padding: 4px 0;
}

.action-dropdown .aditem .fitem-check {
  padding: 5px 12px;
}

.action-dropdown .aditem .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  margin-bottom: 0;
}

/*Ant picker*/
.ant-picker {
  border-radius: 4px;
  background-color: #f4f7f9;
  border: 1px solid #f4f7f9;
  height: 42px;
  padding: 6px 11px;
  width: 100%;
}

.ant-picker:hover,
.ant-picker.ant-picker-focused {
  border: 1px solid rgb(0, 93, 137);
  box-shadow: none;
}

.ant-picker.swhite {
  background-color: #fff;
  border: none;
  padding: 6px 11px;
}

.ant-picker.sorange {
  color: #ec5301;
  background-color: transparent;
  border: #ec5301 1px solid;
  padding: 6px 11px;
}

.ant-picker.reverse .ant-picker-suffix {
  order: -1;
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-header {
  background-color: #f4f7f9;
  padding: 9px 8px;
}

.ant-picker-header .ant-picker-header-view button[type="button"] {
  color: #303130;
  font-weight: 700;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.ant-picker-range-separator + .ant-picker-input input {
  text-align: right;
}

.ant-picker-range .ant-picker-active-bar {
  background: transparent;
}

.ant-picker-input > input {
  color: #1c1d21;
  font-weight: 600;
  padding-right: 5px;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 20px;
  max-height: 50vh;
  overflow: auto;
}

.ant-picker-date-panel .ant-picker-content th {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.ant-picker-date-panel .ant-picker-content td {
  color: #1c1d21;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

.ant-picker-date-panel
  .ant-picker-content
  td.ant-picker-cell-disabled
  .ant-picker-cell-inner {
  color: #757575;
  opacity: 0.2;
}

.ant-picker-date-panel .ant-picker-content td.ant-picker-cell-disabled:before {
  background: transparent;
}

.ant-picker-date-panel
  .ant-picker-content
  td.ant-picker-cell-in-view.ant-picker-cell-in-range {
  color: #005d89;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 6px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: rgb(0, 93, 137);
}

.ant-picker-cell-in-view.ant-picker-cell-selected.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: rgb(0, 93, 137) !important;
  color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-selected.ant-picker-cell-today:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner:before {
  border: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner {
  background-color: #fff !important;
  color: #000;
}

.ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner:before {
  border: none;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background-color: rgba(41, 124, 120, 0.2);
}

.ant-picker-cell:before {
  height: 34px;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-height: 34px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  text-align: center;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background-color: rgb(0, 93, 137) !important;
  color: #fff;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before,
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  color: #6f6f6f;
  width: 9px;
  height: 9px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
  height: 34px;
  border-top: 1px dashed #005d89;
  border-bottom: 1px dashed #005d89;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
    .ant-picker-cell-range-hover-edge-end-near-range
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #005d89;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #005d89;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background-color: rgba(41, 124, 120, 0.2);
}

.ant-select-show-search.search-input .ant-select-arrow {
  font-size: 20px;
  font-weight: bold;
  color: #22232a;
  transform: rotate(-90deg);
}

.ant-select-show-search.search-input.ant-licon .ant-select-arrow {
  left: 10px;
  right: auto;
  top: auto;
  bottom: 10px;
}

.ant-select-show-search.search-input.ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 6px 11px 6px 40px;
}

.ant-select-show-search.search-input.ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  padding-left: 25px;
  height: 40px;
}

.ant-picker-today-btn {
  color: #005d89;
  font-weight: 600;
}

.ant-picker-today-btn:hover,
.ant-picker-today-btn:focus {
  color: #000;
}

.ant-picker-header .btn,
.ant-picker-header button[type="button"],
.ant-picker-header button[type="submit"],
.ant-picker-header .submit,
.ant-picker-header input[type="submit"],
.ant-picker-header input[type="button"],
.ant-picker-header input[type="reset"] {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-picker-header .ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-align: center !important;
}

.ant-picker-header .ant-picker-content th,
.ant-picker-header .ant-picker-content td {
  text-align: center;
}

.ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner {
  width: 95%;
  text-align: center;
}

.ant-picker-decade-panel .ant-picker-cell .ant-picker-cell-inner {
  width: auto;
  height: auto;
}

/*Ant table*/
.ant-table-tbody > tr.ant-table-row td:last-child .table_action_wrapper {
  opacity: 0;
}

.ant-table-tbody > tr.ant-table-row td:last-child .table_action_wrapper .las {
  border-radius: 3px;
  font-size: 20px;
  padding: 7px;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr.ant-table-row:focus > td {
  background-color: rgba(193, 216, 47, 0.15);
}

.ant-table-tbody > tr.ant-table-row:hover > td:last-child .table_action_wrapper,
.ant-table-tbody
  > tr.ant-table-row:focus
  > td:last-child
  .table_action_wrapper {
  opacity: 1;
}

.ant-table-tbody > tr.ant-table-row:hover > td:last-child .las:hover,
.ant-table-tbody > tr.ant-table-row:hover > td:last-child .las:focus,
.ant-table-tbody > tr.ant-table-row:focus > td:last-child .las:hover,
.ant-table-tbody > tr.ant-table-row:focus > td:last-child .las:focus {
  background-color: rgba(196, 214, 214, 0.2);
  color: #508483;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container:hover {
  background: transparent;
}

.ant-table-filter-trigger-container {
  top: 50%;
  transform: translateY(-50%);
  height: 21px;
  width: 15px;
}

.ant-dropdown-open i {
  color: #005d89;
}

.ant-table-wrapper .ant-table-tbody td {
  height: 50px;
  vertical-align: middle;
}

/*Pagination*/
.ant-pagination {
  margin: 10px 0;
  padding: 0 30px;
}

.ant-pagination li.ant-pagination-prev button,
.ant-pagination li.ant-pagination-next button {
  background: transparent;
  border-radius: 0;
  border: none;
  padding: 0 10px;
}

.ant-pagination li.ant-pagination-prev .anticon svg,
.ant-pagination li.ant-pagination-next .anticon svg {
  color: #6f6f6f;
  font-size: 13px;
  margin: 5px 0 0;
}

.ant-pagination .ant-pagination-item {
  border: none;
  border-radius: 6px;
  margin-right: 10px;
  background-color: transparent;
  font-family: "Lato", sans-serif;
}

.ant-pagination .ant-pagination-item a {
  color: #1c1d21 !important;
  font-weight: 700;
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active,
.ant-pagination .ant-pagination-item:hover,
.ant-pagination .ant-pagination-item:focus {
  background-color: rgb(0, 93, 137);
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active a,
.ant-pagination .ant-pagination-item:hover a,
.ant-pagination .ant-pagination-item:focus a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  margin-right: 50px;
}

.ant-pagination .ant-pagination-next {
  margin-left: 50px;
}

.ant-pagination .ant-pagination-item-link {
  cursor: pointer;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  height: 40px;
}

.ant-table-footer {
  padding: 25px 20px;
  background: #f1f1f5 !important;
}

/* .field_serve .ant-table-footer{
  width: 117%;
} */

.view_work_order_total {
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  float: right;
}

.view_work_order_total li {
  margin-right: 20px;
  position: relative;
  z-index: 9;
  font-weight: bold;
}

.pagination_page_size {
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: row;
  margin-top: -50px;
}

.pagination_page_size li {
  margin-right: 10px;
  position: relative;
  z-index: 9;
}

.pagination_page_size li span {
  color: #464657;
  font-size: 13px;
  font-weight: bold;
  padding: 8px 8px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
}

.pagination_page_size li span:hover,
.pagination_page_size li span:focus {
  background-color: rgb(0, 93, 137);
  color: #fff !important;
}

.pagination_page_size li span:hover a,
.pagination_page_size li span:focus a {
  color: #fff !important;
}

.pagination_page_size li .active {
  background-color: rgb(0, 93, 137);
  color: #fff;
}

/*Dropdown menu*/
.table_action_wrapper .table_Action_menu {
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(198, 198, 198, 0.5);
  min-width: 125px;
  right: 0 !important;
  left: auto !important;
  padding: 0;
}

.table_action_wrapper .table_Action_menu li {
  padding: 0;
  margin: 0;
}

.table_action_wrapper .table_Action_menu li span {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 10px 15px;
  display: block;
}

.table_action_wrapper .table_Action_menu li:hover span,
.table_action_wrapper .table_Action_menu li:focus span,
.table_action_wrapper .table_Action_menu li.active span {
  background-color: rgba(196, 214, 214, 0.2);
  color: #005d89;
  display: block;
}

.table_action_wrapper .table_Action_menu .las {
  padding: 5px;
}

.table_action_wrapper .table_Action_menu .las_success {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.active_user_list {
  margin-top: 20px;
}

.active_user_list .user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.active_user_list .user p {
  margin-bottom: 0;
  color: #22222a;
  font-weight: bold;
}

.active_user_list .user i {
  cursor: pointer;
  color: #22232a;
}

.active_user_list .user i:hover,
.active_user_list .user i:focus {
  color: #005d89;
}

.action-btn {
  margin-left: 10px;
}

.action-btn .btn.btn-bdr {
  color: #005d89;
  border-color: #005d89;
}

.action-btn .btn.btn-bdr.btn-danger {
  color: #a80e0e;
  border-color: #a80e0e;
}

.action-btn .btn.btn-bdr:hover,
.action-btn .btn.btn-bdr:focus {
  color: #000;
}

.settings-page
  .rcheck
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  width: 16px;
  height: 16px;
}

.settings-page .rcheck .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:after {
  width: 5px;
  height: 9px;
  right: 6px;
}

.settings-page .aside-area aside.white {
  border: none;
  background-color: transparent;
}

.specific-fitem {
  border-radius: 4px;
  background-color: #f4f7f9;
  border: 1px solid rgba(62, 136, 131, 0.5);
  padding: 15px 20px;
}

.phnum {
  position: relative;
}

.phnum span {
  position: absolute;
  left: 15px;
  top: 36px;
  font-size: 14px;
  font-weight: 700;
}

.phnum input[type="text"] {
  padding-left: 35px;
}

/*Hours of week*/
.hours-work-wrap .inline-check-list .fitem-check {
  display: flex;
}

.hours-work-wrap .ant-select-single {
  width: 105px;
  margin: 0 0 0 10px;
}

.hours-work-wrap .ant-select-single .ant-select-selector {
  height: 32px;
  padding: 0 11px;
}

.hours-work-wrap .ant-select-single .ant-select-selection-item {
  color: rgba(28, 29, 33, 0.5);
}

.hours-work-wrap .ant-picker {
  width: 105px;
  margin: 0 0 0 10px;
  height: 32px;
}

.hours-work-wrap .ant-picker.ant-picker-disabled {
  background: #fbfcfd;
  border-color: #fbfcfd;
}

.hours-work-wrap .ant-picker .ant-picker-input > input {
  color: rgba(28, 29, 33, 0.5);
  font-weight: bold;
  padding-right: 0;
}

.specific-fitem {
  border-radius: 4px;
  background-color: #f4f7f9;
  border: 1px solid rgba(62, 136, 131, 0.5);
  padding: 15px 20px;
}

/*Scrollbar*/
.scrollbar-container {
  height: calc(100vh - 260px) !important;
}

.side-nav .scrollarea .scrollbar-container.vertical {
  background: #cccccc1f !important;
  width: 7px !important;
  opacity: 1 !important;
}

.side-nav .scrollarea .scrollbar-container.vertical:hover {
  cursor: pointer;
  opacity: 0.6 !important;
}

.side-nav .scrollarea .scrollbar-container.vertical .scrollbar {
  background: rgba(245, 245, 250, 0.6) !important;
  width: 5px !important;
}

/*Ant drawer (right side)*/
.rdrawer {
  display: flex;
  flex-direction: column;
}

.rdrawer .card-head {
  padding: 25px 30px;
  border-bottom: 1px solid #dadada;
}

.rdrawer .card-head h4 {
  margin: 0;
}

.rdrawer .card-head h4 i {
  font-size: 20px;
  color: rgb(0, 93, 137);
  font-weight: bold;
}

.rdrawer .card-head .anticon-close {
  font-family: "Line Awesome Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 18px;
}

.rdrawer .card-head .anticon-close svg {
  display: none;
}

.rdrawer .card-head .anticon-close:before {
  color: black;
  height: 12px;
  width: 10px;
  display: block;
  content: "\f00d";
  font-size: 19px;
  font-weight: bold;
}

.rdrawer .card-row {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 65px;
}

.rdrawer .left-sec {
  margin-right: 0;
  padding: 30px;
}

.rdrawer .right-sec {
  padding: 30px;
  background-color: #fff;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04);
}

.rdrawer .right-sec .info_text {
  border-radius: 1px;
  background-color: rgba(255, 182, 77, 0.2);
  margin-bottom: 20px;
  padding: 15px 20px;
  border-top: 1px solid #ffb64d;
}

.rdrawer .right-sec .info_text p {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0;
}

.rdrawer .right-sec .file_upload_section {
  margin-bottom: 20px;
}

.rdrawer
  .right-sec
  .file_upload_section
  .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
}

.rdrawer .right-sec .file_upload_section .ant-upload-picture-card-wrapper {
  display: flex;
  width: auto;
}

.rdrawer
  .right-sec
  .file_upload_section
  .ant-upload.ant-upload-select-picture-card {
  height: 120px;
  width: 120px;
  background-color: #f4f7f9;
  border-color: #f4f7f9;
}

.rdrawer
  .right-sec
  .file_upload_section
  .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f4f7f9;
}

.rdrawer .right-sec .file_upload_section .upload_text i {
  color: rgb(0, 93, 137);
  font-size: 20px;
}

.rdrawer .right-sec .file_upload_section .upload_text p {
  color: rgb(0, 93, 137);
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
}

.rdrawer .right-sec .file_upload_section .uploded_img {
  position: relative;
}

.rdrawer .right-sec .file_upload_section .uploded_img .remvoe_img {
  color: red;
  position: absolute;
  font-weight: bold;
  cursor: pointer;
}

.rdrawer .right-sec .file_upload_section .uploded_img .user_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.rdrawer .staff_service_area {
  border: 1px solid #3e8883;
  padding: 18px 20px;
  border-radius: 4px;
  background-color: rgba(244, 247, 249, 0.5);
  margin-top: 10px;
}

.rdrawer .staff_service_area b {
  color: #1c1d21;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

.rdrawer .staff_service_area input[type="text"] {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #ffffff;
}

.rdrawer .staff_service_area .check_list {
  margin-top: 22px;
}

.rdrawer .zip_code_list ul {
  display: flex;
}

.rdrawer .zip_code_list ul li {
  flex-grow: initial;
  flex: auto;
  max-width: 100px;
}

.rdrawer .zip_code_list ul li i {
  color: #d12619;
}

.rdrawer .fitem-ricon .add_zipcode_icon {
  position: relative;
  right: auto;
  top: auto;
  cursor: pointer;
  background: #f4f7f9;
  padding: 0;
  font-size: 16px;
}

.rdrawer .footer {
  width: 100%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  left: auto;
  padding: 15px 30px 15px 15px;
  background-color: #f5f5fa;
  position: fixed;
  bottom: 0;
  z-index: 9;
  right: 0;
  /*width: 820px;*/
}

.rdrawer.group_wrapper .footer,
.rdrawer.filter_drawer_wrapper .footer {
  width: 400px;
}

.rdrawer.edit-service-drawer .footer {
  width: 525px;
}

.rdrawer.edit-service-drawer .lbltxt {
  color: #000;
  font-size: 11px;
}

.drawer-wrapper .ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.drawer-wrapper .ant-drawer-body {
  overflow: initial;
  margin: initial;
  padding: 0;
}

.icoimg {
  margin-left: 10px;
}

.icoimg_mis_type {
  margin-left: 2px;
}

/*Upload profile picture*/
.file_upload_section {
  margin-bottom: 20px;
}

.file_upload_section .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
}

.file_upload_section .ant-upload-picture-card-wrapper {
  display: flex;
  width: auto;
}

.file_upload_section .ant-upload.ant-upload-select-picture-card {
  height: 120px;
  width: 120px;
  background-color: #f4f7f9;
  border-color: #f4f7f9;
}

.file_upload_section .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f4f7f9;
}

.file_upload_section .upload_text i {
  color: rgb(0, 93, 137);
  font-size: 20px;
}

.file_upload_section .upload_text p {
  color: rgb(0, 93, 137);
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
}

.file_upload_section .uploded_img {
  position: relative;
}

.file_upload_section .uploded_img .remvoe_img {
  color: red;
  position: absolute;
  font-weight: bold;
  cursor: pointer;
}

.file_upload_section .uploded_img .user_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/*Group list*/
.ant-table-wrapper.group-list .ant-table-thead th.tname {
  max-width: 200px;
  width: 20%;
}

.ant-table-wrapper.group-list .ant-table-filter-trigger-container {
  right: 20px;
}

.ant-table-wrapper.customer-list .ant-table-filter-trigger-container {
  right: 10px;
}

.ant-table-wrapper.customer-list .ant-table-row {
  cursor: pointer;
}

.ant-table-wrapper .filterico .anticon-filter {
  width: 20px;
  height: 18px;
  line-height: 25px;
  font-family: "Line Awesome Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 12px;
}

.ant-table-wrapper .filterico .anticon-filter:before {
  content: "\f0b0";
  font-size: 19px;
  font-weight: bold;
  color: rgb(0, 93, 137);
  height: 12px;
  width: 10px;
  display: block;
}

.ant-table-wrapper .filterico .anticon-filter svg {
  display: none;
}

/*Residential Pages*/
.res-head {
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #e1e1e5;
}

.res-head .user-info,
.res-head .user-detail {
  flex: 1;
}

.res-head .user-info {
  max-width: 200px;
  border-right: 1px solid #d8d8d8;
  padding-right: 20px;
}

.res-head .user-info h3 {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.res-head .user-detail {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 10px;
}

.res-head .user-detail .dcell {
  flex: 1;
  max-width: 130px;
  padding: 0 10px;
}

.res-head .user-detail .dcell span {
  display: block;
  margin-bottom: 2px;
  font-size: 13px;
}

.res-head .user-detail .dcell span:first-child {
  font-size: 12px;
}

.res-head .user-detail .dcell:last-child span:last-child {
  color: #8e0a0a;
  font-weight: bold;
}

.res-body .card-row {
  display: flex;
}

.res-body .card-row .card-col {
  flex: 1;
  padding-right: 30px;
}

.res-body .card-row .card-col:last-child {
  padding-right: 0;
}

.res-body .card-row .card-col .card {
  margin-bottom: 10px;
}

.res-body .card-row .card-col .card.card-title {
  padding: 15px 30px;
}

.res-body .card-row .card-col .card.card-title h5 {
  margin-bottom: 0;
}

.res-body .card-body .label,
.res-body .card-body .value {
  flex: 1;
}

.res-body .card-body .label > b,
.res-body .card-body .label > span,
.res-body .card-body .label > div,
.res-body .card-body .value > b,
.res-body .card-body .value > span,
.res-body .card-body .value > div {
  display: block;
}

.res-body .card-body .label > b,
.res-body .card-body .value > b {
  margin-bottom: 5px;
}

.res-body .card-body .label {
  max-width: 115px;
}

.res-body .card-body .label.special-notes {
  max-width: none;
}

.res-body .card-body .value span {
  display: block;
}

.res-body .map {
  width: 100%;
  height: 120px;
}

.res-body .filter_wrapper .left_side .ant-select .ant-select-selector {
  padding: 3px 11px;
  width: auto;
}

.snum {
  color: rgb(0, 93, 137);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 10px;
  display: block;
}

.sadd {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  padding-right: 25px;
}

.sadd:before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: #448b06;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.sadd1 {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  padding-right: 25px;
}

.sadd1:before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: #a5a5a8;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.altude {
  color: #252730;
}

.count {
  height: 20px;
  min-width: 20px;
  color: #fff;
  margin-left: 15px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  border-radius: 2px;
  background-color: rgb(0, 93, 137);
  text-align: center;
  padding: 0 2px;
}

.active_service_list {
  /*margin-top: 10px;*/
}

.active_service_list b,
.active_service_list strong {
  display: block;
}

.total {
  margin: 13px 0 0;
  padding: 20px 15px;
  border-top: 1px solid #e1e1e5;
}

.total h5 {
  margin-bottom: 0;
}

.invoice-payment .smhead {
  padding: 10px 30px;
  display: block;
  line-height: 32px;
}

.invoice-payment .scell {
  border-bottom: 2px solid #f5f5fa;
  padding: 10px 30px;
  margin-bottom: 5px;
}

.invoice-payment .scell .sdate,
.invoice-payment .scell .sdetail {
  flex: 1;
}

.invoice-payment .scell .sdetail {
  text-align: right;
  max-width: 130px;
}

.invoice-payment .scell .amt {
  min-width: 65px;
  text-align: right;
  display: inline-block;
  max-width: 75px;
}

.invoice-payment .overdue {
  background-color: rgba(250, 233, 232, 0.5);
  position: relative;
}

.invoice-payment .overdue:before {
  content: "";
  background-color: rgba(209, 38, 25, 0.9);
  width: 3px;
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
}

.invoice-payment .status {
  margin-left: 10px;
}

.notes {
  color: #262630;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
  border-bottom: 1px solid #e1e1e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.notes span {
  word-break: break-word;
  width: 301px;
  height: 36px;
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #161514;
}

.notes small {
  color: rgb(0, 93, 137);
  font-size: 11px;
  font-weight: 500;
  display: block;
}

.notes:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.green-textarea {
  background-color: rgba(233, 241, 241, 0.2);
  position: relative;
  line-height: 0;
  border-top: 1px solid rgba(64, 121, 119, 0.3);
}

.green-textarea textarea {
  border-radius: 0;
}

.green-textarea .btn.btn-txt {
  position: absolute;
  bottom: 5px;
  right: 20px;
  background: #fff;
  text-align: right;
  padding: 8px 15px;
}

.green-textarea textarea::-webkit-input-placeholder {
  color: rgb(0, 93, 137);
  opacity: 1;
  font-weight: 700;
}

.green-textarea textarea:-moz-placeholder {
  color: rgb(0, 93, 137);
  opacity: 1;
  font-weight: 700;
}

.green-textarea textarea::-moz-placeholder {
  color: rgb(0, 93, 137);
  opacity: 1;
  font-weight: 700;
}

.green-textarea textarea:-ms-input-placeholder {
  color: rgb(0, 93, 137);
  opacity: 1;
  font-weight: 700;
}

.green-textarea textarea::-ms-input-placeholder {
  color: rgb(0, 93, 137);
  opacity: 1;
  font-weight: 700;
}

.cust-metric h2 sub {
  color: #2b2b38;
}

.cust-metric span {
  color: #373849;
  line-height: 17px;
  padding-right: 30px;
}

.comm-pref .fitem-check label {
  width: 50px !important;
  text-align: left;
}

.comm-pref
  .inline-check-list.rcheck
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  left: 0;
  right: auto;
}

.comm-pref
  .inline-check-list.sm-checks
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  left: 5px;
}

/*custom Sidebar*/
.side-drawer {
  border-left: 1px solid #e1e1e5;
  background: #fff;
  height: calc(100vh - 80px);
  position: absolute;
  right: 0;
  min-height: 100%;
  bottom: 0;
  top: 1px;
  width: 25%;
  z-index: 11;
}

.side-drawer .side-header {
  padding: 10px 20px;
}

.side-drawer .side-body {
  padding: 10px 20px;
}

.side-drawer .tabs {
  border-bottom: 1px solid #e1e1e5;
}

.side-drawer .reason .fitem {
  flex: 1;
}

.side-drawer .reason .rating {
  padding: 0 10px;
}

.side-drawer .reason .rating span {
  margin: 0 2px;
}

.side-drawer .reason .rating img {
  max-width: 20px;
  margin: 0 2px;
}

.side-drawer > .drawer-wrapper {
  border-left: 1px solid #e1e1e5;
  background: #fff;
}

.notelist img {
  max-width: 20px;
}

.notelist .callback {
  color: #262630;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 20px;
}

.notelist .call-record {
  color: rgb(0, 93, 137);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 21px;
  display: block;
}

.notelist .log-notes {
  margin-bottom: 20px;
}

/*Add new ticket*/
.create_ticket_drawer .ant-picker-suffix {
  color: #7a7a7a;
  font-size: 18px;
}

.create_ticket_drawer select::-webkit-input-placeholder,
.create_ticket_drawer textarea::-webkit-input-placeholder,
.create_ticket_drawer input::-webkit-input-placeholder {
  color: #1c1d21;
  opacity: 1;
  font-weight: 700;
}

.create_ticket_drawer select:-moz-placeholder,
.create_ticket_drawer textarea:-moz-placeholder,
.create_ticket_drawer input:-moz-placeholder {
  color: #1c1d21;
  opacity: 1;
  font-weight: 700;
}

.create_ticket_drawer select::-moz-placeholder,
.create_ticket_drawer textarea::-moz-placeholder,
.create_ticket_drawer input::-moz-placeholder {
  color: #1c1d21;
  opacity: 1;
  font-weight: 700;
}

.create_ticket_drawer select:-ms-input-placeholder,
.create_ticket_drawer textarea:-ms-input-placeholder,
.create_ticket_drawer input:-ms-input-placeholder {
  color: #1c1d21;
  opacity: 1;
  font-weight: 700;
}

.create_ticket_drawer select::-ms-input-placeholder,
.create_ticket_drawer textarea::-ms-input-placeholder,
.create_ticket_drawer input::-ms-input-placeholder {
  color: #1c1d21;
  opacity: 1;
  font-weight: 700;
}

/*Invoice*/
.invoice_table .ant-table-tbody > tr.ant-table-row:hover > td,
.invoice_table .ant-table-tbody > tr.ant-table-row:focus > td {
  background-color: rgba(209, 38, 25, 0.06);
}

.inleft {
  border: 1px solid #efeff8;
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
}

.inleft .danger {
  color: #d22a1e;
}

.tblock {
  flex: 1;
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #e7e7e7;
}

.tblock:last-child {
  border: none;
  padding-right: 0;
  margin-right: 0;
}

.tblock .trow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

/*Dogs*/
.dogs-block .profile-wrap {
  margin-right: 20px;
}

.dogs-block h5 {
  text-transform: capitalize;
  color: #22222a;
}

.dogs-block .dadd {
  color: #508483;
  font-weight: bold;
  margin-top: 5px;
}

.dogs-block .plain-btn {
  color: #508483;
  font-weight: bold;
}

.dogs-block .plain-btn.btn:hover,
.dogs-block .plain-btn.btn:focus {
  clear: #000;
}

.dogs-bar .inline-check-list {
  padding: 0 30px;
}

/*Residential*/
.article-block {
  background-color: #fff;
  display: flex;
  /* width: 70%; */
  flex-flow: row wrap;
}

.article-block > * {
  flex: 1 100%;
}

.article-block .aleft,
.article-block .aright,
.article-block .adetail {
  padding: 25px 30px;
}

.article-block .aleft {
  border-right: 2px solid rgba(216, 216, 216, 0.5);
  /* min-width: 360px; */
  width: auto;
}

.article-block .adetail {
  width: calc(100% - 360px);
}

.article-block .adetail.disabled {
  opacity: 0.6;
}

.fluid-block {
  /* display: flex; */
  margin-bottom: 65px;
  /* flex-flow: row wrap; */
  /* height: 800px */
}

.fluid-block-add-service {
  display: flex;
  margin-bottom: 65px;
  flex-flow: row wrap;
  height: 800px;
}

.fluid-block-add-service .aside-bar {
  width: 10%;
  background-color: #e4eaed;
  flex: auto;
  padding: 25px 20px;
}

.fluid-block-add-service .aside-bar.service-bar {
  max-width: 425px;
}

.fluid-block .aside-bar {
  width: 10%;
  background-color: #e4eaed;
  flex: auto;
  padding: 25px 20px;
}

.fluid-block .aside-bar.service-bar {
  max-width: 425px;
}

.acol {
  border-radius: 8px;
  background-color: #fff;
  padding: 25px 15px;
}

.acol p {
  margin-bottom: 10px;
}

.acol:last-child .fitem .half-fitem:first-child {
  max-width: 155px;
}

.acol:last-child .fitem .half-fitem:last-child {
  flex: 1;
}

.acol:last-child .fitem .half-fitem .ant-picker-input > input {
  padding-right: 2px;
}

.oplist {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  gap: 5px 5px;
}

.oplist h5 {
  font-weight: normal;
}

.gap-5 {
  gap: 5%;
}

.oplist.selected h5 {
  font-weight: bold;
}

.inline-checks {
  margin-right: 5px;
  margin-bottom: 10px;
}

.inline-checks.inline-blck {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 4px;
}

.inline-checks.inline-blck label {
  margin-bottom: 0;
}

.inline-checks label {
  border: 2px solid #eeeeee;
  border-radius: 3px;
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1c1d21;
  position: relative;
  cursor: pointer;
  min-width: 135px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.inline-checks label.active,
.inline-checks label:hover,
.inline-checks label:focus {
  border-color: #ff7734;
  background: #fff;
}

.inline-checks label input {
  opacity: 0;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.inline-checks .ant-picker {
  border: 2px solid #eeeeee;
  border-radius: 3px;
  background-color: #f6f8fa;
}

.inline-checks .ant-picker > input {
  font-size: 15px;
  color: #1c1d21;
}

.inline-checks .ant-picker input::-webkit-input-placeholder {
  opacity: 1;
  color: #1c1d21;
}

.inline-checks .ant-picker input:-moz-placeholder {
  opacity: 1;
  color: #1c1d21;
}

.inline-checks .ant-picker input::-moz-placeholder {
  opacity: 1;
  color: #1c1d21;
}

.inline-checks .ant-picker input:-ms-input-placeholder {
  opacity: 1;
  color: #1c1d21;
}

.inline-checks .ant-picker input::-ms-input-placeholder {
  opacity: 1;
  color: #1c1d21;
}

.frow {
  margin-bottom: 10px;
  font-weight: bold;
}

.frow h4 small {
  font-size: 13px;
  font-weight: normal;
  flex: 1;
  padding-left: 15px;
}

.frow.md-label .inline-checks label,
.frow.md-label .inline-checks .ant-picker {
  min-width: 220px;
}

.frow.sm-label .inline-checks label {
  min-width: 100px;
}

.highlight {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background-color: #f9ffd9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  margin: 15px -20px;
  padding: 20px;
}

.highlight .pricing {
  color: #204e56;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: right;
}

.wlight {
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  background-color: #f9ffd9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  margin: 15px -20px;
  padding: 20px;
}

.wlight .pricing {
  color: #204e56;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: right;
}

.ant-steps-item {
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 6px 10px 6px 25px !important;
  min-width: 100px;
  max-width: 25%;
  width: 25%;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  overflow: visible;
  display: block;
}

.ant-steps-item:before,
.ant-steps-item:after {
  content: " ";
  position: absolute;
  top: 0;
  right: -14px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #e1e1e5;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.ant-steps-item:before {
  right: auto;
  left: -2px;
  border-left: 14px solid #fff;
  z-index: 0;
}

.ant-steps-item.red {
  color: #fff;
  background: linear-gradient(270deg, #aac117 0%, #c1d82f 100%);
}

.ant-steps-item.red:after {
  border-left: 14px solid #abc318;
}

.ant-steps-item.red a {
  color: #fff;
}

.ant-steps-item.green {
  color: #fff;
  background: linear-gradient(270deg, #29b6e2 0%, #29b6e2 100%);
}

.ant-steps-item.green:after {
  border-left: 14px solid #29b6e2;
}

.ant-steps-item.green a {
  color: #fff;
}

.ant-steps-item.current {
  color: #fff;
  background: linear-gradient(270deg, #f7cd2f 0%, #fed641 100%);
}

.ant-steps-item.current:after {
  border-left: 14px solid #f6cd31;
}

.ant-steps-item.current a {
  color: #fff;
}

.ant-steps-item.grey {
  background-color: #e1e1e5;
}

.ant-steps-item:first-child {
  padding-left: 10px !important;
  margin-left: 0;
}

.ant-steps-item:first-child:before {
  display: none;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
  font-weight: 600;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000;
  font-weight: 600;
}

.ant-steps-small .ant-steps-item-icon {
  display: none;
}

.ant-steps-item-title:after {
  display: none;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: #000;
}

.txt-blck {
  width: 25%;
  color: #252730;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  max-width: 25%;
  background-color: #f4f7f9;
  padding: 5px 10px;
  flex: 1;
  border-right: 3px solid #fff;
}

.residential-wrapper .main .form-group .half-fitem .half-fitem {
  margin: 0 5px;
}

.call-history .ant-table-wrapper .ant-table-thead th:first-child {
  padding-left: 10px;
}

.call-history .ant-table-wrapper .ant-table-thead th:last-child {
  padding-right: 10px;
}

.call-history .ant-table-wrapper .ant-table-tbody td {
  padding: 7px 5px;
}

.call-history .ant-table-wrapper .ant-table-tbody td:first-child {
  padding-left: 10px;
}

.call-history .ant-table-wrapper .ant-table-tbody td:last-child {
  padding-right: 10px;
}

.call-history .fitem.half-fitem .tname {
  max-width: 160px;
  width: 15%;
  flex: auto;
}

.call-history .fitem.half-fitem .tadd {
  flex: 1;
}

.dogs-form .dog-col {
  max-width: 47%;
  margin-right: 20px;
  flex: auto;
}

.dogs-form .dog-col:nth-child(2n) {
  margin-right: 0;
}

/*Franchise mgmt*/
.overview_wrapper .main {
  order: 2;
  width: 74.5%;
}

.overview_wrapper .main.bg-transparent {
  background-color: transparent;
}

.overview_wrapper.franch-over .main {
  order: 1;
}

.overview_wrapper.franch-over aside {
  order: 2;
}

.overview_wrapper aside {
  margin-left: 0;
  padding-right: 30px;
  width: 25.5%;
  max-width: 100%;
}

.overview_wrapper aside .card.revenue .card-title {
  border-bottom: none;
}

.overview_wrapper aside .card.revenue .form-group {
  padding: 0 30px;
}

.overview_wrapper .tabs.mwhite li {
  color: #6f6f6f;
  font-size: 12px;
}

.overview_wrapper .tabs.mwhite li:hover,
.overview_wrapper .tabs.mwhite li:focus,
.overview_wrapper .tabs.mwhite li.active {
  color: #1c1d21;
}

.overview_wrapper .heading {
  padding: 0 25px;
  display: block;
}

.overview_wrapper .top_grid {
  flex-wrap: wrap;
}

.overview_wrapper .top_grid .card {
  margin-right: 15px;
  margin-bottom: 20px;
  flex: 1;
  max-width: 400px;
}

.overview_wrapper .middle_grid {
  margin-bottom: 20px;
}

.overview_wrapper .middle_grid .small_chart {
  max-width: 170px;
  width: 150px;
}

.overview_wrapper .bottom_grid {
  flex-wrap: wrap;
}

.overview_wrapper .bottom_grid .tabs.mwhite li:last-child {
  padding: 0;
}

.overview_wrapper .bottom_grid .tabs.mwhite li:last-child:hover,
.overview_wrapper .bottom_grid .tabs.mwhite li:last-child:focus {
  border: none;
}

.overview_wrapper .bottom_grid .ant-table-container {
  overflow: auto;
}

.overview_wrapper h2 {
  margin-bottom: 0;
}

.overview_wrapper h2 small {
  font-size: 13px;
  margin-left: 10px;
}

.overview_wrapper .small_chart {
  overflow: hidden;
}

.overview_wrapper .small_chart h2 small {
  margin-left: 10px;
}

.overview_wrapper .small_chart .chart {
  margin-top: -20px;
}

.overview_wrapper .contact_details {
  margin-top: 10px;
}

.overview_wrapper .contact_details label {
  color: #1c1d21;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  display: block;
  margin-bottom: 0;
}

.overview_wrapper .contact_details span {
  color: #1c1d21;
  font-size: 13px;
  line-height: 21px;
}

.overview_wrapper .revenue_details label {
  color: rgb(0, 93, 137);
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 0;
}

.overview_wrapper .revenue_details span {
  color: #373849;
  font-size: 13px;
  line-height: 17px;
}

.overview_wrapper .graph_count span {
  color: #00ad29;
  display: flex;
  font-weight: bold;
}

.overview_wrapper .graph_count span u {
  padding-left: 5px;
}

.overview_wrapper .table_with_chart .card-body {
  padding-bottom: 0;
}

.overview_wrapper .card_top_action {
  display: flex;
  justify-content: space-between;
}

.overview_wrapper .card_top_action li {
  padding: 5px 10px;
  color: #6f6f6f;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  align-self: center;
  cursor: pointer;
}

.overview_wrapper .card_top_action .active {
  border: 1px solid #efeff8;
  border-radius: 3px;
  background-color: #ffffff;
  font-weight: bold;
}

.overview_wrapper .chart_bottom_list {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.overview_wrapper .chart_bottom_list li {
  padding: 25px 0;
  flex-basis: 33%;
  text-align: center;
}

.overview_wrapper .chart_bottom_list li h5 {
  margin-bottom: 0;
}

.overview_wrapper .chart_bottom_list li:first-child {
  border-right: 1px solid #f6f6f6;
}

.overview_wrapper .chart_bottom_list li:last-child {
  border-left: 1px solid #f6f6f6;
}

.overview_wrapper .chart_details_list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 22px;
}

.overview_wrapper .chart_details_list li:before {
  position: absolute;
  left: 0;
  top: 5px;
  content: "";
  height: 12px;
  width: 12px;
  border: 4px solid rgb(0, 93, 137);
  display: block;
  border-radius: 100%;
  min-width: 12px;
  margin-right: 10px;
}

.overview_wrapper .chart_details_list li.green-1:before {
  border-color: rgb(0, 93, 137);
}

.overview_wrapper .chart_details_list li.light-green:before {
  border-color: #b3f3d9;
}

.overview_wrapper .chart_details_list li.orange-1:before {
  border-color: #ff681d;
}

.overview_wrapper .chart_details_list li.lime:before {
  border-color: #cde059;
}

.overview_wrapper .chart_details_list li.dark-green:before {
  border-color: #3ba93f;
}

.overview_wrapper .chart_details_list li.ripple-green:before {
  border-color: #abd3d2;
}

.overview_wrapper .chart_details_list li span {
  padding: 0 5px;
  font-size: 14px;
  line-height: 21px;
}

.overview_wrapper .chart_details_list li span:nth-child(1) {
  width: 35%;
  text-align: left;
  min-width: 162px;
}

.overview_wrapper .chart_details_list li span:nth-child(2) {
  font-weight: bold;
  min-width: 70px;
  text-align: right;
}

.overview_wrapper .chart_details_list li span:nth-child(3) {
  font-weight: bold;
  min-width: 70px;
  text-align: right;
}

.heading {
  font-size: 26px;
  font-weight: bold;
}

.heading label {
  color: #8181a5;
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.details_list {
  margin-bottom: 25px;
}

.details_list li {
  list-style-type: none;
  display: flex;
}

.details_list li label {
  color: #262630;
  margin-bottom: 0;
  flex: auto;
  max-width: 120px;
}

.details_list li span {
  color: #262630;
  font-size: 13px;
  flex: 1;
}

.franchisee .aside-area .main {
  border: none;
  background: none;
  width: 74.5%;
}

.franchisee .aside-area .main .card .card-body {
  padding: 15px 25px;
}

/*Accounting module*/
.account_module_wrapper .block-row {
  background-color: #f4f7f9;
  flex: 1;
}

.account_module_wrapper .block-row .filter_wrapper {
  background-color: #fafafa;
}

/* .account_module_wrapper .ant-table-content {
  overflow: auto;
} */

.account_module_wrapper aside {
  background-color: #fff;
  padding: 25px;
  max-width: 380px;
  width: 30%;
  flex: auto;
}

.account_module_wrapper aside.laside {
  margin-right: 0;
}

.account_module_wrapper .main {
  flex: 1;
  background-color: #f4f7f9;
  width: 100%;
}

.account_module_wrapper .ant-picker-range-separator input,
.account_module_wrapper .ant-picker-range-separator + .ant-picker-input input {
  padding: 0;
}

.account_module_wrapper .ant-picker-input-active ::-webkit-input-placeholder {
  /* Edge */
  color: #1e2025 !important;
  font-size: 13px !important;
  line-height: 25px !important;
  white-space: pre !important;
  font-weight: bold !important;
}

.account_module_wrapper .ant-picker-input-active :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1e2025 !important;
  font-size: 13px !important;
  line-height: 25px !important;
  white-space: pre !important;
  font-weight: bold !important;
}

.account_module_wrapper .ant-picker-input-active ::placeholder {
  color: #1e2025 !important;
  font-size: 13px !important;
  line-height: 25px !important;
  white-space: pre !important;
  font-weight: bold !important;
}

.account_module_wrapper .ant-picker-input > input {
  color: #1e2025 !important;
  font-size: 13px !important;
  line-height: 25px !important;
  white-space: pre !important;
  font-weight: bold !important;
  padding-right: 0;
}

.gray_box {
  border: 1px solid #dcdce6;
  border-radius: 5px;
  background-color: #f8f8fc;
  margin-bottom: 10px;
}

.gray_box .card-body,
.gray_box .card-footer {
  padding: 15px 25px;
}

.gray_box .card-footer .creatBatchInvoice {
  width: 100%;
}

.gray_box .label,
.gray_box .value {
  margin-bottom: 0;
}

.gray_box h6 {
  margin: 0;
}

.gray_box .value h6 {
  text-align: right;
  line-height: 24px;
}

.gray_box .label h6 {
  line-height: 24px;
}

.gray_box p {
  font-size: 12px;
}

.gray_box .card-footer {
  border-top: 1px solid #dcdce6;
}

.gray_box.green_box {
  border: 1px solid #3e8883;
  background-color: #f4f7f9;
}

.syncbook {
  margin-top: 150px;
  display: block;
  bottom: 0;
  position: absolute;
}

.status-blocks {
  display: flex;
  justify-content: flex-start;
}

.status-blocks p,
.status-blocks h2,
.status-blocks h4 {
  color: #fff;
  margin: 0;
}

.status-blocks p {
  font-weight: bold;
}

.status-blocks h2 {
  font-size: 26px;
  margin-right: 15px;
}

.status-blocks .stcell {
  padding: 20px 10px 10px;
  color: #fff;
  max-width: 250px;
  flex: 1;
}

.status-blocks .stcell.overdue {
  background-color: #ec5301;
}

.status-blocks .stcell.open {
  background-color: #9aa0ab;
}

.status-blocks .stcell.paid {
  background-color: #78c600;
}

.status-blocks .stcell .head {
  border-bottom: 0px;
  font-size: xx-large;
  color: white;
  line-height: 0;
  font-weight: 500;
  margin-bottom: 8px;
  margin-right: 15px;
}

/* .acc-wrap .fitem:first-child {
  max-width: 480px;
  flex: 1;
} */
.acc-wrap .fitem:last-child {
  /*flex: auto;*/
  max-width: 200px;
}

.acc-wrap.filter_wrapper .form-group .ant-select-single {
  max-width: 240px;
}

.acc-wrap.filter_wrapper
  .form-group
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 25px;
}

.forecast-block {
  border: 1px solid #dcdce5;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 35px;
}

.frtable {
  border: 1px solid #dcdce5;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 30px;
  margin-top: 5px;
}

.frtable p {
  font-weight: bold;
  font-size: 14px;
}

.frtable .frtop {
  display: flex;
}

.frtable .frtop .frleft {
  max-width: 49%;
}

.width-49 {
  width: 49%;
}

.width-47 {
  width: 47%;
}

.frtable .frtop .fright {
  max-width: 59%;
}

.frtable .frtop .flex-1 {
  padding-bottom: 0;
}

.frtable .frtop .frright,
.frtable .frbody .frright {
  flex: 1;
}

.frtable .frtop .frright .flex .flex-1,
.frtable .frbody .frright .flex .flex-1 {
  padding: 0 15px;
}

.frtable .frtop .frright .flex .flex-1:first-child,
.frtable .frbody .frright .flex .flex-1:first-child {
  position: relative;
}

.frtable .frtop .frright .flex .flex-1:first-child:before,
.frtable .frtop .frright .flex .flex-1:first-child:after,
.frtable .frbody .frright .flex .flex-1:first-child:before,
.frtable .frbody .frright .flex .flex-1:first-child:after {
  content: "";
  background-color: #e1e5e9;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
}

.frtable .frtop .frright .flex .flex-1:first-child:before,
.frtable .frbody .frright .flex .flex-1:first-child:before {
  left: 0;
}

.frtable .frtop .frright .flex .flex-1:first-child:after,
.frtable .frbody .frright .flex .flex-1:first-child:after {
  right: 0;
}

.frtable .frleft {
  flex: auto;
  max-width: 50%;
}

.frtable .frleft ul {
  padding-left: 30px;
}

.frtable .frright {
  flex: 1;
  max-width: 50%;
}

.frtable .frhead {
  display: flex;
  background-color: #f4f7f9;
  padding: 10px 20px;
}

.frtable .frhead h4 {
  margin: 0;
}

.frtable .frhead .flex-1 {
  text-align: right;
  padding: 0 15px;
}

.frtable .frbody {
  display: flex;
  padding: 0 20px;
}

.frtable .frbody p {
  margin-top: 10px;
  margin-bottom: 0;
}

.frtable .frbody ul {
  padding-bottom: 30px;
  padding-top: 10px;
}

.frtable .frbody ul li {
  font-weight: bold;
}

.frtable .frbody .frright ul li {
  font-weight: normal;
}

.frtable .frbody .frright .flex .flex-1 {
  padding-bottom: 30px;
  text-align: right;
}

.frtable .frfoot {
  display: flex;
  background-color: #1c1d21;
  padding: 10px 25px;
}

.frtable .frfoot h4 {
  color: #fff;
  margin: 0;
}

.frtable .frfoot .flex-1 {
  text-align: right;
}

.frtable
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.frtable
  .ant-select-multiple:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f4f4f4;
}

.frfoott {
  display: flex;
  background-color: #1c1d21;
  padding: 10px 50px;
}

.frfoott h4 {
  color: #fff;
  margin: 0;
}

.frfoott .flex-1 {
  text-align: right;
}

.frfoott .frleft {
  flex: auto;
  max-width: 50%;
}

.frfoott .frright {
  flex: auto;
  max-width: 50%;
}

.comm-pref label {
  color: #1c1d21;
}

.sales-item .breadcrumb {
  padding: 10px 25px;
}

.sales-item .frtable .frtop .frright .flex .flex-1:first-child:before,
.sales-item .frtable .frtop .frright .flex .flex-1:first-child:after,
.sales-item .frtable .frbody .frright .flex .flex-1:first-child:before,
.sales-item .frtable .frbody .frright .flex .flex-1:first-child:after {
  display: none;
}

.sales-item .frtable .frleft {
  max-width: 60%;
}

.sales-item .frtable .frright {
  max-width: 40%;
}

.sales-item .block-row {
  max-width: 675px;
}

.sales-item .frtable .frhead .flex-1:last-child,
.sales-item .frtable .frbody .frright .flex .flex-1:last-child {
  max-width: 130px;
  flex: auto;
  width: 130px;
}

.sales-item .filter_wrapper .form-group .ant-select-single {
  width: 220px;
  max-width: 220px;
}

.sales-item .ant-select-single .ant-select-selector .ant-select-selection-item,
.sales-item
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 25px;
}

.rev-sales.sales-item .frtable .frleft,
.rev-sales.sales-item .frtable .frfoot .fleft {
  max-width: 195px;
  flex: auto;
  width: 195px;
}

.rev-sales.sales-item .frtable .fright .flex-1,
.rev-sales.sales-item .frtable .frfoot .fright .flex-1 {
  max-width: 160px;
  flex: auto;
  width: 160px;
  text-align: left;
  padding: 0 15px;
  margin-top: 15px;
}

.rev-sales.sales-item .frtable .fright .flex-1:last-child,
.rev-sales.sales-item .frtable .frfoot .fright .flex-1:last-child {
  text-align: right;
}

.rev-sales.sales-item .frtable .frhead .fright .flex-1 {
  margin-top: 0;
}

.rev-sales.sales-item .frtable .frfoot .fright .flex-1 {
  margin-top: 0 !important;
}

.pac-container {
  z-index: 10000 !important;
}

.leftpicker {
  left: 1000px !important;
}

.pos-relative {
  position: relative;
}

.only-icon-datepick {
  position: absolute;
  right: 1px;
  top: 1px;
  border: none;
}

.only-icon-datepick .ant-picker {
  border: none;
  cursor: pointer;
}

@media (max-width: 1399px) {
  .res-body .card-row .card-col {
    padding-right: 15px;
  }

  .res-body .card .card-title {
    padding: 10px;
  }

  .res-body .card .card-title .btn,
  .res-body .card .card-title button[type="button"],
  .res-body .card .card-title button[type="submit"],
  .res-body .card .card-title .submit,
  .res-body .card .card-title input[type="submit"],
  .res-body .card .card-title input[type="button"],
  .res-body .card .card-title input[type="reset"] {
    padding: 8px 13px;
  }

  .res-body .card .card-body {
    padding: 20px 10px;
  }

  .res-body .invoice-payment .smhead,
  .res-body .invoice-payment .scell {
    padding: 10px;
  }

  .ant-steps-item {
    max-width: 115px;
  }

  .proposal-head .steps .grey.ant-steps-item {
    max-width: 210px;
  }

  .tbl-block {
    width: 1399px;
    overflow: auto;
  }

  .table-block .div-table {
    margin-bottom: 25px;
  }
}

.table-block {
  max-width: 1075px;
  overflow: auto;
}

.table-block .div-table {
  display: table;
}

.my_services {
  max-width: 70%;
  flex: 1;
  margin-left: 15px;
  background: #fff;
}

.my_services thead.ant-table-thead {
  display: none;
}

.table-header {
  padding: 15px;
}

.sorting .wid {
  width: 75px;
}

.sorting .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.sorting
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-right: 0;
}

.sorting .ant-select-single.ant-select-focused .ant-select-selector {
  border: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.sorting .ant-select-single.swhite .ant-select-selector {
  height: 30px !important;
  padding-top: 0;
  padding-bottom: 0;
}

.sorting .ant-select-arrow {
  margin-top: -8px;
}

.sorting .ant-select-arrow img {
  vertical-align: middle;
}

.sorting .ant-select-single .ant-select-selector .ant-select-selection-item,
.sorting
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 30px;
}

.brd {
  border-top: 20px solid #f4f7f9;
}

.accounting.ant-table table {
  border-radius: 0px;
  table-layout: auto;
}

/* .account_module_wrapper .accounting .ant-table-content{
  overflow-x:hidden;
} */

.accounting.tablebody {
  white-space: nowrap;
  width: 110%;
}

.accounting.tablebody .ant-table-thead > tr > th {
  vertical-align: middle;
}

.accounting.tablebody .ant-table-column-sorter {
  margin-left: 0px;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 5px;
  width: 4%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(2) {
  padding-left: 5px;
  width: 12%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(3) {
  padding-left: 20px;
  width: 8%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(4) {
  padding-left: 6px;
  width: 16%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 6px;
  width: 15%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(6) {
  padding-left: 8px;
  width: 14%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(7) {
  padding-left: 5px;
  width: 16%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(8) {
  padding-left: 5px;
  width: 12%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(9) {
  padding-left: 5px;
  width: 10%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(10) {
  padding-left: 5px;
  width: 6%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(11) {
  padding-left: 5px;
  width: 6%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(12) {
  padding-left: 5px;
  width: 10%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(13) {
  padding-left: 5px;
  width: 10%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:nth-child(14) {
  padding-left: 5px;
  width: 10%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-thead > tr > th:last-child {
  padding-left: 5px;
  width: 6%;
  max-width: 100%;
}

.accounting.tablebody .ant-table-tbody > tr {
  position: relative;
}

.accounting.tablebody .ant-table-tbody > tr > td {
  vertical-align: middle;
  border-bottom: none;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(1) {
  padding-left: 5px;
  width: 3%;
  max-width: 10px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(2) {
  padding-left: 5px;
  width: 10%;
  max-width: 15px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(3) {
  padding-left: 2px;
  width: 8%;
  max-width: 105px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(4) {
  padding-left: 2px;
  width: 13%;
  max-width: 25px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 5px;
  width: 13%;
  max-width: 50px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(6) {
  padding-left: 8px;
  width: 11%;
  max-width: 5px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(7) {
  padding-left: 5px;
  width: 12%;
  max-width: 5px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(8) {
  padding-left: 5px;
  width: 9%;
  max-width: 50px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(9) {
  padding-left: 5px;
  width: 7%;
  max-width: 100px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(10) {
  padding-left: 5px;
  width: 6%;
  max-width: 60px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(11) {
  padding-left: 5px;
  width: 6%;
  max-width: 100px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(12) {
  padding-left: 5px;
  width: 9%;
  max-width: 100px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(13) {
  padding-left: 5px;
  width: 8%;
  max-width: 55px;
}

.accounting.tablebody .ant-table-tbody > tr > td:nth-child(14) {
  padding-left: 5px;
  width: 10%;
  max-width: 95px;
}

.accounting.tablebody .ant-table-tbody > tr > td:last-child {
  width: 6%;
  max-width: 20px;
}

.service_descriptionTable_table_url.ant-table table {
  border-radius: 0px;
  table-layout: auto;
}

.service_descriptionTable_table_url.tablebody {
  white-space: nowrap;
  width: 100%;
}

.service_descriptionTable_table_url.tablebody .ant-table-thead > tr > th {
  vertical-align: middle;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(1) {
  padding: 15px 10px;
  width: 5%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(2) {
  padding: 15px 10px;
  width: 6%;
  max-width: 15px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(3) {
  padding: 15px 10px;
  width: 15%;
  max-width: 15px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(4) {
  padding: 15px 10px;
  width: 4%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(5) {
  padding-left: 10px;
  width: 4%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(6) {
  padding-left: 10px;
  width: 3%;
  max-width: 5px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(7) {
  padding-left: 5px;
  width: 4%;
  max-width: 5px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-thead
  > tr
  > th:last-child {
  width: 4%;
  max-width: 15px;
}

.service_descriptionTable_table_url.tablebody .ant-table-tbody > tr {
  position: relative;
}

.service_descriptionTable_table_url.tablebody .ant-table-tbody > tr > td {
  vertical-align: middle;
  border-bottom: none;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(1) {
  padding-left: 5px;
  width: 5%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(2) {
  padding-left: 0px;
  width: 6%;
  max-width: 15px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(3) {
  padding-left: 2px;
  width: 8%;
  max-width: 15px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(4) {
  padding-left: 15px;
  width: 4%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(5) {
  padding-left: 10px;
  width: 4%;
  max-width: 10px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(6) {
  padding-left: 10px;
  width: 3%;
  max-width: 5px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:nth-child(7) {
  padding-left: 5px;
  width: 4%;
  max-width: 5px;
}

.service_descriptionTable_table_url.tablebody
  .ant-table-tbody
  > tr
  > td:last-child {
  width: 4%;
  max-width: 15px;
}

.service_history_table.tablebody .time {
  white-space: nowrap;
}

.service_history_table.tablebody .ant-table-thead > tr > th {
  vertical-align: middle;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 15px;
  width: 20%;
  max-width: 130px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(2) {
  width: 12%;
  max-width: 80px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(3) {
  width: 10%;
  max-width: 90px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(4) {
  width: 15%;
  max-width: 60px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(5) {
  width: 10%;
  max-width: 80px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:nth-child(6) {
  width: 20%;
  max-width: 150px;
}

.service_history_table.tablebody .ant-table-thead > tr > th:last-child {
  width: 15%;
  max-width: 90px;
}

.service_history_table.tablebody .ant-table-tbody > tr {
  position: relative;
}

.service_history_table.tablebody .ant-table-tbody > tr > td {
  vertical-align: middle;
  border-bottom: none;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(1) {
  padding-left: 15px;
  width: 20%;
  max-width: 130px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(2) {
  width: 12%;
  max-width: 80px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(3) {
  width: 10%;
  max-width: 90px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(4) {
  width: 15%;
  max-width: 60px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(5) {
  width: 10%;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(6) {
  width: 20%;
  max-width: 150px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:last-child {
  width: 15%;
  max-width: 90px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td:nth-child(3) span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  display: block;
}

.service_history_table.tablebody .ant-table-tbody > tr > td .note figure {
  margin-left: 10px;
}

.service_history_table.tablebody .ant-table-tbody > tr > td .msg {
  display: none;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled {
  background-color: rgba(209, 38, 25, 0.06);
  position: relative;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td {
  vertical-align: top;
  height: 80px;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td .msg {
  position: absolute;
  left: 30px;
  width: 700px;
  top: 45px;
  color: #d12619;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td .status {
  color: #d12619;
  font-weight: 700;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td .policy {
  font-weight: 700;
}

.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td:hover,
.service_history_table.tablebody .ant-table-tbody > tr.cancelled > td:focus {
  background-color: rgba(209, 38, 25, 0.06);
}

.service_history_table.tablebody .ant-table-tbody > tr.ant-table-row:hover td {
  background: rgba(64, 121, 119, 0.06);
}

.service_history_table.tablebody .ant-table-tbody > tr .ticket {
  background: rgba(209, 38, 25, 0.1);
  color: #d12619;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
}

.service_history_table .ant-table-pagination.ant-pagination {
  margin: 0;
  width: 100%;
  text-align: right;
  background: #f4f7f9;
  padding: 15px 10px;
}

.allservice.flist,
.inactive-services.flist {
  padding: 25px;
}

.allservice .hrline,
.inactive-services .hrline {
  display: block;
  padding: 0 25px;
}

.singleservice {
  padding: 25px;
}

.singleservice .card {
  max-width: 360px;
}

.singleservice .sdetail {
  margin-bottom: 10px;
}

.singleservice .subs b {
  margin-right: 10px;
}

.pause-serv {
  background: #f4f7f9;
  padding: 10px 30px;
}

.hrline {
  position: relative;
  text-align: center;
  display: block;
  padding: 0 25px;
}

.hrline:before,
.hrline:after {
  content: "";
  background: #e1e1e5;
  width: 42%;
  height: 1px;
  display: flex;
  position: absolute;
  align-items: center;
  top: 10px;
  bottom: 0;
  left: 25px;
  right: 0;
}

.hrline:after {
  right: 25px;
  left: auto;
}

.hrline small {
  background-color: #f4f7f9;
  display: inline-block;
  color: rgba(37, 39, 48, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 0 15px;
  z-index: 9;
}

.inactive-services .sadd:before {
  background-color: #e1e1e5;
}

/*customer portal - commercials*/
.permission label {
  color: #1c1d21;
}

.permission .inline-check-list label {
  width: 50px !important;
  text-align: left;
}

.res-body .hide-lg {
  display: flex;
}

.res-body .show-lg {
  display: none;
}

.remove-copupon {
  color: #1c1d21;
  font-weight: 500;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.remove-copupon img {
  max-width: 15px;
  margin-right: 5px;
}

.blockedueser .anticon-stop {
  color: #d12619;
  font-size: 16px;
}

.blockedueser .bico {
  margin-right: 10px;
}

.rdrawer.filter_drawer_wrapper.updateco .footer {
  width: 1170px;
}

.rdrawer.filter_drawer_wrapper.updateco
  .inline-check-list.sm-checks
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  right: 6px;
  left: auto;
}

.prime-info
  .inline-check-list.sm-checks
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  left: auto;
  right: 6px;
}

.proposal-head {
  display: flex;
  margin-bottom: 10px;
}

.proposal-head .pleft,
.proposal-head .pright {
  background: #fff;
  padding: 30px;
}

.proposal-head .pleft {
  margin-right: 30px;
}

.proposal-head .steps .ant-steps-item {
  max-width: 210px;
}

.proposal-head .pright {
  max-width: 40%;
}

.proposal-head .ant-avatar {
  margin-right: 10px;
}

.pdetails {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.pdetails .pcell {
  flex: auto;
  padding-right: 10px;
}

.pdetails:first-child .pcell {
  max-width: 33.33%;
}

.btngrp {
  padding: 10px 15px;
}

.tbl-block {
  margin-bottom: 15px;
}

.tbl-block .tbl-head {
  padding: 15px 25px;
  display: flex;
  background-color: rgba(245, 245, 250, 0.4);
  border-bottom: 1px solid #f0f2f2;
}

.tbl-block .tbl-head .tbl-cell {
  margin-bottom: 0;
  color: #6b6b6b;
  font-size: 12px;
  font-weight: bold;
  padding: 0 5px;
}

.tbl-block.propose-tbl-block .tbl-head {
  border-top: 1px solid #f0f2f2;
  padding: 6px 20px;
}

.tbl-block.propose-tbl-block .tbl-head .tbl-cell sup {
  font-size: 13px;
  color: #d12619;
  top: -2px;
}

.tbl-block.propose-tbl-block .tbl-head .tbl-cell:last-child,
.tbl-block.propose-tbl-block .tbl-body .tbl-cell:last-child {
  min-width: 25px;
  max-width: 25px;
}

.tbl-block.propose-tbl-block .ant-ico-del {
  margin-top: 10px !important;
}

.tbl-block.propose-tbl-block .ant-ico-del path {
  fill: #848484;
}

.tbl-block.propose-tbl-block .tbl-body {
  padding: 7px 20px;
  align-items: flex-start;
}

.tbl-block.propose-tbl-block .tbl-body .frm-ctrl .bdr-control {
  height: 40px;
  background-color: #f4f7f9;
  padding: 6px 16px;
  border: none;
  min-height: 40px;
  vertical-align: middle;
}

.tbl-block.propose-tbl-block
  .tbl-body
  .tbl-cell
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.tbl-block.propose-tbl-block
  .tbl-body
  .tbl-cell
  .ant-select-multiple:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f4f7f9;
  border-radius: 4px;
  height: 40px;
}

.tbl-block.propose-tbl-block .tbl-body .tbl-cell.ttl label {
  margin: 0;
  padding: 0;
}

.tbl-block.propose-tbl-block .tbl-cell {
  margin: 0;
}

.tbl-block.propose-tbl-block .tbl-cell .fitem {
  margin: 0;
}

.tbl-block.propose-tbl-block .tbl-cell.item {
  min-width: 230px;
  max-width: 230px;
}

.tbl-block.propose-tbl-block .tbl-cell.model,
.tbl-block.propose-tbl-block .tbl-cell.reg {
  min-width: 180px;
  max-width: 180px;
}

.tbl-block.propose-tbl-block .tbl-cell.desc {
  min-width: 478px;
}

.tbl-block.propose-tbl-block .tbl-cell.qty,
.tbl-block.propose-tbl-block .tbl-cell.disc,
.tbl-block.propose-tbl-block .tbl-cell.ratee,
.tbl-block.propose-tbl-block .tbl-cell.sh {
  min-width: 110px;
  max-width: 110px;
}

.tbl-block.propose-tbl-block .tbl-cell.ttl {
  min-width: 120px;
  max-width: 160px;
  padding-top: 10px;
}

.tbl-block.propose-tbl-block .tbl-cell.cord {
  min-width: 150px;
  max-width: 150px;
}

.tbl-block.propose-tbl-block .tbl-cell.cord input {
  padding-right: 70px;
}

.tbl-block.propose-tbl-block .tbl-cell.cord button {
  background-color: transparent;
  border: none;
  padding: 4px 8px;
  position: absolute;
  right: 0;
  top: 8px;
  color: #005d89;
  font-weight: 700;
  font-size: 13px;
}

.tbl-block .tbl-body {
  padding: 15px 25px;
  display: flex;
  align-items: flex-start;
}

.tbl-block .tbl-body .flex label,
.tbl-block .tbl-body .form-group .half-fitem .half-fitem.flex label,
.form-group .half-fitem .tbl-block .tbl-body .half-fitem.flex label {
  margin: 0 5px;
}

.tbl-block .tbl-body .flex .ant-select-selection-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 57px;
}

.tbl-block .tbl-body .tbl-cell {
  padding: 0 5px;
}

.tbl-block .tbl-body .tbl-cell.ttl label {
  color: #1c1d21;
  font-weight: 600;
}

.tbl-block .tbl-body .tbl-cell.rate {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  background-color: rgba(240, 240, 243, 0.3);
  padding: 7px;
}

.tbl-block
  .tbl-body
  .tbl-cell
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.tbl-block
  .tbl-body
  .tbl-cell
  .ant-select-multiple:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f4f4f4;
  border-radius: 0;
}

.tbl-block .tbl-body .frm-ctrl .bdr-control {
  padding: 3px 10px;
  font-size: 12px;
  height: 28px;
}

.tbl-block .tbl-body .frm-ctrl .fitem-check {
  width: 30px;
}

.tbl-block
  .tbl-body
  .frm-ctrl
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:before {
  width: 15px;
  height: 15px;
}

.tbl-block
  .tbl-body
  .frm-ctrl
  .fitem-check
  input.fitem-ck-input
  ~ .fitem-ck-txt:after {
  width: 5px;
  height: 9px;
  left: 5px;
  top: 4px;
}

.tbl-block .tbl-body .frm-ctrl .fitem-ck-txt {
  color: #6b6b6b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  top: -10px;
  position: relative;
  padding-left: 20px;
}

.tbl-block .tbl-body .frm-ctrl.rate .xs-control {
  width: 55px;
}

.tbl-block .btngrp {
  padding: 15px 25px;
}

.survey {
  padding: 25px 30px;
  display: flex;
  align-items: flex-start;
}

.survey label {
  color: #1c1d21;
  font-weight: bold;
  margin-bottom: 10px;
}

.survey .form-group {
  max-width: 465px;
  margin-right: 30px;
}

.bdr-wrap {
  border: 2px dashed #ececf2;
  border-radius: 5px;
  background-color: rgba(240, 240, 243, 0.15);
  justify-content: center;
  height: 235px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bdr-wrap .anticon-environment {
  color: #f9a720;
  font-size: 24px;
  line-height: 24px;
}

.bdr-wrap h4 {
  margin: 10px 0;
}

.avatar-uploader {
  border: 2px dashed #ececf2;
  border-radius: 5px;
  background-color: rgba(240, 240, 243, 0.15);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 17px 15px;
  cursor: pointer;
}

.avatar-uploader .anticon svg {
  display: inline-block;
  color: #f9a720;
  font-size: 20px;
}

.avatar-uploader .ant-upload p {
  color: #262630;
}

.fileprev {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.ant-upload.ant-upload-select {
  text-align: center;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 0;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #f9a720;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #f9a720;
  font-size: 24px;
  line-height: 24px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}

.htmeditor {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 20px;
}

.htmeditor .btn-grp {
  padding: 0 15px;
}

.ant-upload.ant-upload-drag {
  padding: 10px;
}

.fgrp {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.fgrp h6 {
  margin-bottom: 0;
}

.fgrp input {
  padding: 0;
  margin-left: 30px;
}

.quill {
  padding: 10px 0;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 215px;
}

.sign {
  padding: 0 15px 20px;
}

.sign span {
  opacity: 0.8;
  margin-bottom: 10px;
}

.sign p {
  margin-top: 0;
  line-height: 18px;
}

.cover-letters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: 15px;
}

.cover-letters .cvrow {
  flex: auto;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
  margin: 0 15px 10px 0;
  display: flex;
  justify-content: flex-start;
  height: 85px;
  max-width: 50%;
}

.cover-letters .cvrow:nth-child(even) {
  margin-right: 0;
}

.cover-letters .cvrow:first-child {
  margin-left: 0;
}

.cover-letters .cvrow figure {
  height: 60px;
  width: 50px;
  border: 1px solid #d6d6d6;
  border-radius: 1px;
  background-color: #fff;
  margin-bottom: 0;
}

.cover-letters .cvrow .inline-check-list.sm-checks,
.cover-letters .cvrow figure,
.cover-letters .cvrow .filedetail {
  display: inline-block;
  vertical-align: top;
}

.cover-letters .cvrow figure img {
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cover-letters .cvrow .filedetail {
  padding-left: 10px;
}

.cover-letters .cvrow .filedetail h4 {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}

.cover-letters .cvrow .action-link a {
  margin-right: 20px;
  font-size: 13px;
  font-weight: bold;
}

.cover-letters .cvrow.upld-wrap {
  border: none;
  padding: 0;
}

.upld-wrap {
  border: none;
  padding: 0;
  max-width: 100%;
}

.upld-wrap > span {
  width: 100%;
}

.upld-wrap .ant-btn.ant-upload-list-item-card-actions-btn {
  background: none;
  border: none;
}

.ant-modal {
  top: 50px !important;
}

.ant-modal.md-modal {
  width: 725px !important;
}

.ant-modal.lg-modal {
  width: 1015px !important;
}

.ant-modal .ant-modal-header {
  padding-bottom: 20px;
}

.ant-modal #map {
  width: 100% !important;
}

.ant-modal #pac-input {
  width: 390px;
  margin: 10px 0;
}

.ant-modal #location-map {
  width: 100% !important;
  height: 350px !important;
}

.upld-agreement .avatar-uploader {
  margin-bottom: 20px;
  padding: 25px 15px;
}

.upld-agreement .cover-letters {
  margin-left: 0;
}

.upld-agreement .cover-letters .cvrow {
  margin-right: 0;
  max-width: 100%;
}

.pagi-block a {
  color: #6f6f6f;
}

/*Kanban board*/
.kanban-row {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
}

.kanban-row .inner-kncard {
  border: 1px solid #efeff8;
  border-radius: 10px;
  background-color: #fff;
  max-width: 20%;
  flex: 1;
  margin: 5px;
  width: 20%;
  min-width: 290px;
}

.kanban-row .inner-kncard.ant-card-bordered {
  border: 1px solid #efeff8;
  margin-bottom: 10px;
}

.kanban-row .inner-kncard.ant-card-bordered.current {
  border: 2px dashed #f9a720 !important;
  border-radius: 10px;
  background-color: rgba(249, 167, 32, 0.05) !important;
}

.kanban-row .inner-kncard.ant-card-bordered.current .ant-card-body > label {
  color: #f9a720;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.kanban-row .inner-kncard:first-child {
  margin-left: 0;
}

.kanban-row .inner-kncard:last-child {
  margin-right: 0;
}

.kanban-row .inner-kncard .ant-card-body {
  padding: 15px;
}

.kanban-row .inner-kncard .ant-card-body label {
  font-weight: bold;
  margin-bottom: 20px;
}

.kanban-row .inner-kncard .ant-card-body .form-group label {
  margin-bottom: 0;
}

.kanban-row .inner-kncard .ant-picker:hover,
.kanban-row .inner-kncard .ant-picker.ant-picker-focused {
  background: rgba(41, 124, 120, 0.2);
  color: #005d89;
  padding: 4px;
}

.kanban-row .inner-kncard .ant-picker:hover .ant-picker-input > input,
.kanban-row
  .inner-kncard
  .ant-picker.ant-picker-focused
  .ant-picker-input
  > input {
  color: #005d89;
  font-weight: 600;
  padding-right: 0;
}

.kanban-row h6 {
  margin-bottom: 5px;
}

.kanban-row .add-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kanban-row .add-date p {
  cursor: pointer;
}

.kanban-row .add-date:hover,
.kanban-row .add-date:focus {
  color: rgb(0, 93, 137);
}

.kanban-row .add-date:hover p,
.kanban-row .add-date:focus p {
  color: rgb(0, 93, 137);
}

.kanban-row .utility {
  color: #8c8c8c;
}

.link {
  display: block;
  text-decoration: underline;
  color: rgb(0, 93, 137);
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.link:hover,
.link:focus {
  color: #000;
}

@media (max-width: 1399px) {
  .res-body .hide-lg {
    display: none;
  }

  .res-body .show-lg {
    display: flex;
  }

  .res-body .card-row .card-col {
    padding-right: 20px;
  }

  .res-body .card .card-title {
    padding: 10px;
  }

  .res-body .card .card-title .btn,
  .res-body .card .card-title button[type="button"],
  .res-body .card .card-title button[type="submit"],
  .res-body .card .card-title .submit,
  .res-body .card .card-title input[type="submit"],
  .res-body .card .card-title input[type="button"],
  .res-body .card .card-title input[type="reset"] {
    padding: 8px 13px;
  }

  .res-body .card .card-body {
    padding: 20px 10px;
  }

  .res-body .invoice-payment .smhead,
  .res-body .invoice-payment .scell {
    padding: 10px;
  }

  .ant-steps-item {
    max-width: 115px;
  }

  .steps .ant-steps-item {
    max-width: 210px;
  }

  .steps .btn {
    padding: 8px 10px;
  }

  .flist .card {
    max-width: 370px;
    width: 49%;
  }

  .proposal-head .pleft,
  .proposal-head .pright {
    padding: 20px;
  }

  .proposal-head .pleft {
    margin-right: 15px;
  }

  .proposal-head .pleft {
    max-width: 60%;
  }

  .ant-steps-item-content {
    width: 100%;
  }

  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    float: left;
  }

  .tbl-cell {
    margin: 0 5px;
    min-width: 200px;
  }
}

.tbl-block {
  max-width: 100%;
  overflow: auto;
}

.tbl-block .tbl-head,
.tbl-block .tbl-body {
  width: 1760px;
}

.tbl-block .tbl-head .tbl-cell:last-child,
.tbl-block .tbl-body .tbl-cell:last-child {
  flex: auto;
  min-width: 55px;
}

.tbl-cell {
  flex: 1;
}

.tbl-cell.prs {
  min-width: 200px;
}

.tbl-cell.item {
  min-width: 200px;
}

.tbl-cell.model {
  min-width: 200px;
}

.tbl-cell.reg {
  min-width: 200px;
}

.tbl-cell.desc {
  min-width: 200px;
}

.tbl-cell.disc {
  min-width: 100px;
}

.tbl-cell.qty {
  min-width: 100px;
}

.tbl-cell.rate {
  min-width: 130px;
}

.tbl-cell.ttl {
  min-width: 100px;
}

.tbl-cell.sh {
  min-width: 130px;
}

.tbl-cell .ant-ico-del {
  max-width: 55px;
  min-width: 55px;
  text-align: right;
  padding: 0 15px;
  font-size: 18px;
}

.react-pdf__Page__canvas {
  max-height: 450px !important;
  overflow: auto;
  width: 100% !important;
}

/*invoices*/
.res-body .trans {
  transform: translate(0, 0);
}

.invoice-container {
  border: 1px solid #efeff8;
  background-color: #fff;
  padding: 30px 25px;
}

.invoice-container .sticky-btn-grp {
  background-color: #3d3d3d;
  position: fixed;
  width: 100%;
  text-align: right;
  padding: 10px 30px 10px 100px;
  z-index: 9;
  left: 0;
  bottom: 0;
}

.invoice-container label {
  color: #1c1d21;
}

.invoice-container .links {
  color: #fff;
}

.invoice-container .links span {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
}

.invoice-container .links span:after {
  position: absolute;
  content: "|";
  right: -10px;
}

.invoice-container .links span:last-child:after {
  display: none;
}

.div-table {
  margin-bottom: 25px;
}

.view-edit .divhead .dcell,
.view-edit .divrow:not(.dfoot) .dcell,
.view-edit .divhead .dcell.sdesc {
  min-width: 200px;
}

.view-edit .divrow.dfoot {
  justify-content: flex-start;
}

.divhead {
  background-color: #f1f1f5;
  min-height: 30px;
}

.divhead .dcell {
  color: #555555;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  font-size: 12px;
  padding: 0 10px;
}

.divhead {
  align-items: center;
}

.divrow {
  align-items: flex-start;
}

.divhead,
.divrow {
  display: flex;
}

.divhead .dcell,
.divrow .dcell {
  flex: 1;
  min-width: 180px;
  width: 10%;
  max-width: 180px;
}

.divhead .dcell.no-min,
.divrow .dcell.no-min {
  min-width: 10px;
  max-width: 50px;
}

.divhead .dcell.sno,
.divrow .dcell.sno {
  padding-left: 25px;
}

.divhead .dcell:last-child,
.divrow .dcell:last-child {
  padding-right: 15px;
}

.divhead .dcell.sdesc,
.divrow .dcell.sdesc {
  width: 300px;
  min-width: 300px !important;
}

.divhead .dcell.stax,
.divrow .dcell.stax {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 260px;
  /* width: 30%; */
  min-width: 260px;
}

.divhead .dcell.stax .form-control,
.divrow .dcell.stax .form-control {
  height: 29px;
  width: 70px;
}

.divhead .dcell .sprice,
.divrow .dcell .sprice {
  margin-right: 25px;
  font-weight: bold;
}

.divhead .dcell.sno,
.divrow .dcell.sno {
  font-weight: bold;
}

.divhead .dcell.serviceno,
.divrow .dcell.serviceno {
  font-weight: bold;
  padding-left: 25px;
}

.divhead .dcell .la-trash-alt,
.divrow .dcell .la-trash-alt {
  margin-left: 10px;
}

.divhead .dcell .inline-check-list,
.divrow .dcell .inline-check-list {
  display: flex;
  align-items: center;
}

.divrow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 180px;
}

.divrow .dcell {
  padding: 10px;
}

.divrow:first-child {
  margin-left: 0;
}

.divrow.dfoot {
  align-items: flex-start;
  justify-content: flex-start;
}

.divrow.dfoot .dcell {
  padding: 0;
  max-width: 50%;
  align-items: flex-start;
  align-items: flex-start;
}

.divrow.dfoot .dcell:first-child {
  max-width: 305px;
  padding-right: 30px;
}

.padding-r-remove {
  padding-right: 0 !important;
}

.divrow.dfoot .dcell .btn-group {
  padding: 10px 0 15px;
  text-align: left;
}

.divrow.dfoot .price-block {
  padding-right: 30px;
}

.divrow.dfoot .price-block,
.divrow.dfoot .pricing {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  white-space: nowrap;
  padding-top: 10px;
}

.divrow.dfoot .price-block span,
.divrow.dfoot .pricing span {
  line-height: 30px;
}

.divrow.dfoot .pricing {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  padding: 10px 20px;
}

.edit-service-drawer
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.edit-service-drawer
  .ant-select-multiple:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  min-height: 42px;
}

.position-relative {
  position: relative;
}

.date-width {
  max-width: 183px;
}

.account_module_wrapper .divrow.dfoot .pricing {
  padding: 10px 15px;
}

.view-edit .divrow.dfoot .pricing {
  padding: 10px 35px;
}

@media (max-width: 1200px) {
  .container {
    /* width: 1300px; */
    overflow-x: auto;
  }
}

.ant-table table {
  table-layout: auto !important;
}

.fixed_per_sign span {
  right: 8px !important;
  font-size: 11px !important;
  top: 6px !important;
  padding: 0 !important;
}

.tax_control {
  font-size: 11px !important;
  padding: 5px 10px !important;
}

.cursor_pointer {
  cursor: pointer;
}

.popup-width {
  width: 200px;
}

/* Removing partial selection from checkbox */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #fff !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border: 1px solid #005d89 !important;
}

.field-fitem-ricon {
  margin-right: 50px;
}

.field_search_bar {
  position: relative;
}

.field_search_bar .fitem-ricon {
  position: absolute;
  right: 25px;
  top: 11px;
  transform: rotate(278deg);
}

.select-multiple .fitem-ricon {
  right: 10px;
}

.select-multiple .ant-select-selector {
  height: auto !important;
  min-height: 42px;
  padding-right: 25px !important;
}

.new-cust {
  padding-bottom: 5px;
}

.franch-dash {
  display: flex;
  justify-content: space-between;
}

.dcell.stax .dcell.no-min.no-wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.franch-card h3 {
  margin-bottom: 9px;
}

.com-block {
  margin-bottom: 20px;
}

.com-block h6 {
  margin-bottom: 10px;
}

.custom-switch label {
  width: 44px;
  height: 24px;
  border-radius: 22px;
  background-color: rgba(122, 124, 125, 0.1);
  position: relative;
  margin: 0;
  cursor: pointer;
}

.custom-switch input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-switch input:checked ~ .switch-circle {
  transform: translateX(20px);
  background-color: #005d89;
}

.switch-circle {
  width: 24px;
  height: 24px;
  background-color: #929495;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
}

.switch-lable {
  font-size: 13px;
}

.franch-card {
  min-height: 63vh;
}

.service-order-card {
  width: 100%;
  max-width: 393px;
  min-width: 393px;
}

.service-order-card .card-body {
  padding: 25px 20px;
}

.service-status {
  padding: 15px 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.active-service {
  border: 1px solid rgba(68, 139, 6, 0.3);
}

.paused-service {
  border: 1px solid rgba(249, 167, 32, 0.3);
}

.paused-service .sadd:before {
  background-color: #f9a720;
}

.inactive-service {
  border: 1px solid rgba(165, 165, 168, 0.3);
}

.inactive-service .sadd:before {
  background-color: #a5a5a8;
}

.fs-15 {
  font-size: 15px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.service-status .snum {
  margin-right: 10px;
}

.service-status .subs > .flex,
.service-status .form-group .half-fitem .subs > .half-fitem.flex,
.form-group .half-fitem .service-status .subs > .half-fitem.flex {
  justify-content: flex-start;
}

.service-status-head {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 15px;
  display: block;
}

.work-orders-table {
  width: 100%;
  background-color: #fff;
}

.pool-characteristics {
  width: 100%;
  background-color: #fff;
  padding-bottom: 10px;
  min-width: 1000px;
  overflow: auto;
}

.pool-characteristics-toolbar {
  padding-left: 30px;
  padding-right: 30px;
}

.poolCharactersticsModal {
  min-width: 80% !important;
}

.mr-10 {
  margin-right: 10px;
}

.sorting-btn-group .btn {
  padding-left: 10px;
  padding-right: 10px;
}

.sorting-btn-group .btn.flex img,
.sorting-btn-group .form-group .half-fitem .btn.half-fitem.flex img,
.form-group .half-fitem .sorting-btn-group .btn.half-fitem.flex img {
  margin-right: 6px;
}

.sorting-btn-group .btn.btn-history {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #efeff8;
}

.sorting-btn-group .btn.btn-upcoming {
  background-color: transparent;
  color: #1f1f25;
  border: 1px solid #efeff8;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #22232a;
  opacity: 0.5;
}

.work-orders-toolbar {
  padding-left: 30px;
  padding-right: 30px;
}

.sorting-fields {
  padding-bottom: 4px;
}

.table-header.sorting-btn-group {
  padding: 25px 0 14px;
}

.date-range-field {
  min-width: 328px;
  max-width: 328px;
}

.service-order-field {
  width: 100%;
}

.service-order-field .image-input {
  width: 100%;
}

.work-order-status .field-dropdown {
  width: 315px !important;
  display: block;
  overflow: auto;
  min-height: 200px;
}

/* .work-order-status input[type=text] {
  line-height: 25px;
} */

.work-order-status img {
  /* right: 25px !important; */
  padding: 15px !important;
  background-color: white;
}

/* .work-order-status{
  width: 315px!important;
}
.work-order-status .field-dropdown-body{
  overflow: hidden;
} */

.field-dropdown {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(198, 198, 198, 0.5);
  border: 1px solid #efeff8;
  border-radius: 3px;
  z-index: 11;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 4px;
}

.field-dropdown.right {
  left: auto;
  right: 0;
}

.field-dropdown-wo {
  padding: 15px;
  overflow: auto;
}

.field-dropdown-wo .fitem-check + .fitem-check {
  margin-top: 13px;
}

.field-dropdown-wo .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  color: #1e2025;
}

.min-height-auto {
  min-height: auto !important;
}

.field-dropdown-body {
  padding: 15px;
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
}

.field-dropdown-body .fitem-check + .fitem-check {
  margin-top: 13px;
}

.field-dropdown-body .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  color: #1e2025;
}

.field-dropdown-footer {
  padding: 15px;
  border-top: 1px solid #efeff8;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.fcheck-span {
  display: block;
  padding-left: 28px;
  color: #1e2025;
  opacity: 0.7;
  font-size: 12px;
  line-height: 15px;
}

.service-arrow {
  position: absolute;
  right: -6px;
  top: 0;
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 11;
}

.service-arrow img {
  width: 4px;
}

.dots-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  padding-left: 10px;
  padding-right: 10px;
}

.dots-button span {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: #fff;
}

.dots-button span + span {
  margin-top: 2px;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.d-flex {
  display: flex !important;
}

.d-flex.ai-center,
.form-group .half-fitem .d-flex.half-fitem.ai-center {
  align-items: center;
}

.service-type-title {
  color: #6f6f6f;
  font-size: 13px;
  line-height: 18px;
  display: block;
}

.inline-check-list .fitem-ck-txt {
  color: #22232a;
}

.service-type-body {
  font-size: 13px;
  line-height: 18px;
  color: #22232a;
}

.side-drawer-collapsed {
  border-left: 1px solid #e1e1e5;
  background: #fff;
  height: calc(100vh - 80px);
  position: absolute;
  right: 0;
  min-height: 100%;
  bottom: 0;
  top: 1px;
  width: 60px;
}

.expand-arrow {
  border-bottom: 1px solid #efeff8;
  height: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-icons-vert {
  padding: 20px 6px;
}

.service-icons-vert li {
  width: 48px;
  height: 48px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.service-icons-vert li.active {
  background-color: rgba(41, 124, 120, 0.05);
}

.service-icons-vert li.active .normal {
  display: none;
}

.service-icons-vert li.active .hover {
  display: block;
}

.service-icons-vert li .hover {
  display: none;
}

.service-icons-vert li img {
  width: 100%;
  max-width: 16px;
  max-height: 16px;
}

.side-drawer.rdrawer-expanded {
  width: 425px;
}

.side-drawer.rdrawer-expanded .drawer-wrapper {
  height: 100%;
}

.side-drawer.rdrawer-expanded .rdrawer {
  height: 100%;
}

.side-drawer.rdrawer-expanded .rdrawer .card-row {
  flex: 1 1;
  flex-direction: column;
  padding: 0;
}

.side-drawer.rdrawer-expanded .rdrawer .footer {
  width: auto;
  position: relative;
}

.side-drawer.rdrawer-expanded .side-header {
  border-bottom: 1px solid #efeff8;
  padding: 0 19px 0;
}

.side-drawer.rdrawer-expanded .tabs {
  border: none;
}

.side-drawer.rdrawer-expanded .tabs li:last-child {
  margin-left: auto;
  margin-right: 0;
}

.side-drawer.rdrawer-expanded .tabs li:last-child:before {
  display: none;
}

.side-drawer.rdrawer-expanded .tabs li span {
  display: flex;
  align-items: center;
}

.side-drawer.rdrawer-expanded .tabs li span img {
  width: 100%;
  max-width: 14px;
  max-height: 14px;
  margin-right: 8px;
}

.side-drawer.rdrawer-expanded .tabs.white li {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: normal;
  white-space: nowrap;
}

.side-drawer.rdrawer-expanded .tabs.white li.active {
  font-weight: 700;
}

.w-100 {
  width: 100%;
}

.textarea-gray {
  background-color: #f4f7f9 !important;
}

.order-alert-warning {
  padding: 19px 15px;
  background-color: rgba(249, 167, 32, 0.1);
  border: 1px solid #f9a720;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 13px;
  line-height: 16px;
}

.order-alert-warning p {
  margin-bottom: 10px;
}

.alert-dateblock span {
  font-weight: 700;
  display: block;
}

.alert-cation-btn a {
  padding: 4px 8px;
  color: #005d89;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.alert-cation-btn a + a {
  margin-left: 10px;
}

.alert-cation-btn a img {
  margin-right: 6px;
}

.color-1F1F25 {
  color: #1f1f25 !important;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-15 {
  opacity: 1.5;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.no-service img {
  margin-bottom: 10px;
}

.no-service h5 {
  margin-bottom: 10px;
  color: #1f1f25;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.no-service p {
  color: #1f1f25;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
}

.view-all-btn {
  padding: 6px 13px !important;
}

.danger-status {
  background-color: #fae9e8 !important;
  color: #d12619 !important;
}

.warning-status {
  background-color: #fef6e8 !important;
  color: #f9a720 !important;
}

.complete-status {
  background-color: #ecf3e6 !important;
  color: #448b06 !important;
}

.incomplete-status {
  background-color: #fae9e8 !important;
  color: #d12619 !important;
}

.billable {
  color: #ffbf00 !important;
  background-color: #fffaeb !important;
  text-align: center;
  font-weight: 600;
}

.warning-status.drk {
  background-color: #fef6e8 !important;
  color: #f9a720 !important;
}

.success-status {
  background-color: #e9f1f1 !important;
  color: #005d89 !important;
}

.success-status.drk {
  background-color: #ecf3e6 !important;
  color: #448b06 !important;
}

.info-status {
  background-color: #ecf3e6 !important;
  color: #448b06 !important;
}

.gray-status {
  background-color: #f5f5f5 !important;
  color: #7a7a7a !important;
}

.blue-status {
  background-color: #f5f5f5 !important;
  color: #6b81c1 !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-pagination.mini .ant-pagination-item {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active,
.ant-pagination .ant-pagination-item:hover,
.ant-pagination .ant-pagination-item:focus {
  background-color: rgb(0, 93, 137) !important;
}

.backbtn {
  font-size: 14px;
  line-height: 17px;
  color: #22222a;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backbtn img {
  width: 5px;
  margin-right: 10px;
}

.field-group {
  margin-bottom: 23px;
}

.field-group label sup {
  color: #d12619;
  top: 0;
}

.field-group input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 0;
}

.field-group input[type="checkbox"]:checked ~ .switch-circle {
  transform: translateX(20px);
  background-color: #005d89;
}

.switch-circle {
  width: 24px;
  height: 24px;
  background-color: #929495;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
}

.switch-lable {
  font-size: 13px;
}

.franch-card {
  min-height: 63vh;
}

.service-order-card {
  width: 100%;
  max-width: 393px;
  min-width: 393px;
}

.service-order-card .card-body {
  padding: 25px 20px;
}

.service-status {
  padding: 15px 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.active-service {
  border: 1px solid rgba(68, 139, 6, 0.3);
}

.paused-service {
  border: 1px solid rgba(249, 167, 32, 0.3);
}

.paused-service .sadd:before {
  background-color: #f9a720;
}

.fs-15 {
  font-size: 15px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.service-status .snum {
  margin-right: 10px;
}

.service-status .subs > .flex,
.service-status .form-group .half-fitem .subs > .half-fitem.flex,
.form-group .half-fitem .service-status .subs > .half-fitem.flex {
  justify-content: flex-start;
}

label sup {
  color: #d12619;
  top: 0;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.btn.btn-2px-border {
  border: 2px solid #eeeeee;
  background-color: #f6f8fa;
  margin-bottom: 4px;
  margin-right: 4px;
  font-weight: normal;
}

.btn.btn-2px-border.btn-warning {
  border-color: #ff681d;
  background-color: #fff;
}

.btn.btn-2px-border + .btn-2px-border {
  margin-left: 0;
}

h4 sup {
  color: #d12619;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  top: -5px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.summary-label {
  margin-bottom: 4px;
  line-height: 16px;
  color: #252730;
  opacity: 0.5;
}

.summary-detail {
  color: #252730;
  line-height: 16px;
}

.summary-total {
  padding-top: 20px;
  font-size: 18px;
  line-height: 21px;
  color: #22232a;
  font-weight: 700;
}

.alternate-order {
  background-color: #f5f5f5;
  color: #7a7a7a;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  border-radius: 3px;
  padding: 5px 8px;
  min-width: 145px;
  display: flex;
  align-items: center;
}

.alternate-order img {
  margin-right: 8px;
}

.status-d-arrow {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-refresh {
  margin-left: 4px;
  min-width: 26px;
  min-height: 26px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-dropdown:hover .status-dropdownmenu {
  display: block;
}

.status-dropdownmenu {
  display: none;
  z-index: 11;
  min-width: 146px;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 7px 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
}

.status-dropdownmenu li {
  list-style: none;
  padding: 0;
}

.status-dropdownmenu li a {
  padding: 7px 20px;
  color: #1f1f25;
  font-size: 12px;
  line-height: 16px;
  display: block;
}

.awo-status {
  background-color: #f5f5f5;
  background-image: url(../Assets/images/build.svg);
  background-position: 8px center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 8px 5px 29px;
  color: #7a7a7a;
  font-size: 12px;
  line-height: 15px;
}

.ics-status {
  background-color: #f0f2f8;
  background-image: url(../Assets/images/info-icon-blue.svg);
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 13px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 8px 5px 29px;
  color: #6b81c1;
  font-size: 12px;
  line-height: 15px;
}

.addmore-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #005d89 !important;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.view-only {
  cursor: not-allowed !important;
  color: gray !important;
}

.card-head {
  padding: 12px 30px;
  border-bottom: 1px solid #e1e1e5;
}

.card-head h5 {
  margin-bottom: 0;
}

.pause-card .card-body {
  padding: 15px 30px;
}

.pause-heading a {
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin-bottom: 4px;
  color: rgb(0, 93, 137);
  font-weight: 700;
}

.pause-heading span {
  font-size: 13px;
  line-height: 16px;
  color: #1f1f25;
  display: block;
}

.pause-list-block {
  padding-bottom: 10px;
}

.pause-list-block + .pause-list-block {
  border-top: 1px solid #e1e1e5;
  padding-top: 10px;
}

.pause-list-block .btn-upcoming {
  padding: 6px 8px;
  background-color: #f5f5f5 !important;
  color: #7a7a7a;
  font-size: 10px;
  line-height: 12px;
  border-radius: 3px;
  font-weight: 900;
  border-color: #f5f5f5 !important;
  margin-left: 10px;
}

.pause-list-block .btn-inprogress {
  padding: 6px 8px;
  background-color: #e9f1f1 !important;
  color: #005d89;
  font-size: 10px;
  line-height: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 900;
  border-color: #e9f1f1 !important;
  margin-left: 10px;
}

.pause-dropdown {
  margin-left: 10px;
}

.pause-dropdown .dots-button {
  background-color: transparent;
  /*padding: 0;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;*/
  border-radius: 3px;
  border: 1px solid #ececf2;
}

.pause-dropdown .dots-button span {
  background-color: #848484;
}

.pause-dropdown:hover .pause-dropdownmenu {
  display: block;
}

.pause-dropdownmenu {
  display: none;
  z-index: 11;
  min-width: 118px;
  position: absolute;
  right: 0;
  top: 0%;
  padding: 7px 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
}

.pause-dropdownmenu li {
  list-style: none;
  padding: 0;
}

.pause-dropdownmenu li a {
  padding: 7px 20px;
  color: #1f1f25;
  font-size: 12px;
  line-height: 16px;
  display: block;
  font-weight: 700;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(1) {
  width: 12%;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(2) {
  width: 30%;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(3) {
  width: 17%;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(4) {
  width: 17%;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(5) {
  width: 17%;
}

.work-orders-table
  .service_history_table.tablebody
  .ant-table-thead
  > tr
  > th:nth-child(6) {
  width: 10%;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ch-block {
  margin-bottom: 20px;
}

.ch-block small {
  font-size: 12px;
}

.call-history-wrap {
  padding-top: 20px;
  position: relative;
}

.call-history-wrap:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  height: 1px;
  background-color: #e1e1e5;
}

.last-clean-btn.btn {
  border: 2px solid #eeeeee;
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1c1d21 !important;
  width: 33.33%;
}

.last-clean-btn.btn + .btn {
  margin-left: 4px !important;
}

.last-clean-btn.btn.btn-warning {
  border-color: #ff681d;
  background-color: #fff;
  font-weight: 600;
  margin: 0;
}

.disabled-row {
  opacity: 0.5;
  color: #22222a;
  /* background-color: #dcdcdc; */
  pointer-events: none;
}

.resident-sm-row .oplist {
  flex-wrap: nowrap;
}

.resident-sm-row .oplist .inline-checks {
  flex: 1;
}

.resident-sm-row h4 {
  margin-bottom: 10px;
}

.resident-sm-row .billing-payfull-wrap .oplist {
  flex: nowrap;
}

.resident-sm-row .billing-payfull-wrap .oplist .inline-checks {
  flex: 1;
}

.resident-sm-row .billing-payfull-wrap .oplist .inline-checks label {
  min-width: 10px;
}

.resident-sm-row .billing-payfull-wrap .pi-block {
  max-width: 450px;
}

.resident-sm-row
  .billing-payfull-wrap
  .ant-select-selection-overflow-item
  .ant-checkbox-wrapper.hidden {
  display: none;
}

.resident-sm-row .ant-select-selection-item-content .hidden .ant-checkbox {
  display: none !important;
}

.resident-sm-row .ant-select-dropdown .ant-checkbox-inner {
  border: none;
  background: #f4f7f9;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  color: #fff;
}

.resident-sm-row
  .ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-checkbox-inner {
  background: #005d89;
}

.resident-sm-row
  .ant-select-dropdown
  .ant-select-item-option-selected
  .ant-select-item-option-content:before {
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 17px;
  font-size: 11px;
  color: #fff;
}

.resident-sm-row
  .ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.resident-sm-row
  .ant-select-dropdown
  .ant-select-item-option:not(.ant-select-item-option-disabled):hover {
  background: transparent !important;
}

.resident-sm-row .ant-select-dropdown .ant-checkbox-wrapper.hidden {
  display: inline-flex;
}

.resident-sm-row .custom-preferred-days {
  width: 445px !important;
}

.resident-sm-row .custom-preferred-days .field-dropdown-body {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 20px;
}

.resident-sm-row .custom-preferred-days .field-dropdown-body .fitem-check {
  width: 50%;
  margin-top: 5px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ch-block {
  margin-bottom: 20px;
}

.ch-block small {
  font-size: 12px;
}

.text-wrap {
  word-wrap: break-word;
}

.call-history-wrap {
  padding-top: 20px;
  position: relative;
}

.call-history-wrap:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  height: 1px;
  background-color: #e1e1e5;
}

.last-clean-btn.btn {
  border: 2px solid #eeeeee;
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1c1d21 !important;
  width: 33.33%;
}

.last-clean-btn.btn + .btn {
  margin-left: 4px !important;
}

.custom-preferred-days-dropdown .cpreferred-input {
  position: relative;
}

.custom-preferred-days-dropdown .cpreferred-input .lbl-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px 19px;
  color: #303035;
}

.custom-preferred-days-dropdown {
  width: 445px !important;
}

.add-cord-modal .ant-modal-header {
  padding-bottom: 8px;
}

.add-cord-modal .ant-modal-title {
  font-size: 18px;
  margin-bottom: 0;
}

.add-cord-row {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
}

.add-cord-col-left {
  max-width: 27%;
  width: 100%;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
}

.add-cord-col-right {
  max-width: 73%;
  width: 100%;
  padding: 0 6px;
}

.add-cord-search {
  position: relative;
}

.add-cord-search #pacord-input {
  height: 42px;
  background-color: #f4f7f9;
}

.add-cord-search .btn {
  width: 32px;
  height: 32px;
  padding: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.cordnate-list {
  border: 1px solid #ececf2;
  border-radius: 3px;
  margin-top: 10px;
  flex: 1 1;
  padding: 10px;
}

.cordnate-list .no-cord-add {
  padding: 0;
}

.no-cord-add {
  display: flex;
  align-items: center;
}

.no-cord-add svg {
  width: 30px;
  height: 30px;
}

.no-cord-add svg path {
  fill: #f9a720;
}

.cordnate-blck {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f7f9;
  border-radius: 4px;
}

.cordnate-blck + .cordnate-blck {
  padding-top: 10px;
}

.has_err_msg {
  margin-top: 5px;
  color: #1f1f25 !important;
  border: 2px solid #e02020;
  padding: 5px;
  color: #333;
  background: rgba(209, 38, 25, 0.1);
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.has_err_msg.align-items-start i {
  margin-top: 2px;
}

.has_err_msg i {
  color: #e02020;
  font-size: 16px;
  padding-right: 5px;
}

.has_success_msg {
  margin-top: 5px;
  border: 2px solid #3ba93f;
  padding: 5px;
  color: #1f1f25 !important;
  background: rgba(59, 169, 63, 0.1);
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.has_success_msg.align-items-start i {
  margin-top: 2px;
}

.has_success_msg i {
  color: #3ba940;
  font-size: 16px;
  padding-right: 5px;
}

.p-10 {
  padding: 10px !important;
}

.custom-height-optimo-routing {
  min-height: 130px !important;
}

.width-50 {
  width: 50% !important;
}

.custom-height {
  min-height: 130px !important;
}

.hide-tab {
  display: none !important;
}

.text-danger {
  color: red !important;
}

.custom-tootip .ant-tooltip-inner {
  background: white;
  color: #7a7a7a;
  font-size: 12px;
}

.max-w-328 {
  max-width: 328px !important;
}

.custom-text-muted-schedulling-error {
  color: #22222a;
  font-size: 13px;
  opacity: 0.7 !important;
}

.selected-drop-down-value {
  padding: 12px 24px 12px 16px;
  cursor: pointer;
}

.selected-value-filter {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.noWorkOrder {
  height: 30px;
  width: 30px;
  border: 3px solid #f9a720;
  border-radius: 100%;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noWorkOrder > span {
  display: inline-block;
  transform: rotate(-90deg);
}

.noWorkOrder path {
  fill: #f9a720;
}

.noWorkOrder svg {
  height: 17px;
  width: 17px;
}

.stat-input.image-input input {
  padding-right: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.stat-input.image-input img {
  top: 50%;
  transform: translateY(-50%);
}

.btn-disabled {
  /* pointer-events: none !important;
  cursor: not-allowed !important; */
}

.cust-select-option {
  font-size: 12px;
  line-height: 15px;
  color: #1f1f25 !important;
  font-weight: bold;
}

.ant-modal-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  /* height: 420px; */
  width: 360px;
  margin-top: 60%;
}

.ant-modal-content .ant-modal-close {
  background-color: transparent;
  border: none;
  padding: 0;
}

.ant-modal-content .ant-modal-close .ant-modal-close-x {
  text-align: left;
  font-size: 30px;
}

.ant-modal-content .ant-modal-close .ant-modal-close-icon {
  font-family: "Line Awesome Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 18px;
}

.ant-modal-content .ant-modal-close .ant-modal-close-icon svg {
  display: none;
}

.ant-modal-content .ant-modal-close .ant-modal-close-icon:before {
  color: black;
  height: 12px;
  width: 10px;
  display: block;
  content: "\f00d";
  font-size: 19px;
  font-weight: bold;
}

.ant-modal-header {
  padding: 25px 30px 10px;
  border-bottom: none;
  color: #1c1d21;
  border-radius: 0;
}

.ant-modal-title {
  font-size: 16px;
  line-height: 19px;
  color: #1c1d21;
  font-weight: bold;
}

.ant-modal-body {
  padding: 0 30px 25px;
}

@media (max-width: 600px) {
  .ant-modal-body {
    padding: 0 28px 25px;
  }
}

.customer-lead-modal .ant-modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.ant-modal-footer {
  padding: 20px 30px;
  border-radius: 0 0 4px 4px;
  background-color: #e8edea;
}

.ant-modal-footer .btn {
  padding: 8px 15px;
}

.lh-24 {
  line-height: 24px;
}

.fo-side .gray_box p {
  line-height: 24px;
  font-size: 14px;
}

.fo-side .gray_box.teal_box {
  border: 1px solid #007e79;
  background-color: #fff;
}

.fo-side .gray_box.yellow_box {
  border: 1px solid #ff9800;
  background-color: #fff;
}

.fo-side .gray_box.ltgreen_box {
  border: 1px solid #a7c300;
  background-color: #fff;
}

.justify-content-end {
  justify-content: end !important;
  justify-content: flex-end !important;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-start {
  justify-content: start !important;
  justify-content: flex-start !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.mr-15 {
  margin-right: 15px;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.working-day {
  justify-content: start !important;
  justify-content: flex-start !important;
}

.working-day li {
  width: 40px;
  margin-right: 5px;
  margin-bottom: 0;
}

.working-day li .day-name {
  font-size: 11px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  display: block;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  background-color: #005d89;
  margin: 0 auto 10px;
  color: #fff;
}

.working-day li .day-name.doff {
  background-color: #f0f0f3;
  color: rgba(28, 29, 33, 0.4);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.work-time {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 4px;
  position: relative;
}

.work-time:after {
  content: "";
  position: absolute;
  top: 19px;
  left: 4px;
  right: 4px;
  height: 1px;
  background-color: #e5e5e5;
}

.work-time .offtext {
  font-size: 11px;
  line-height: 13px;
  color: rgba(96, 96, 96, 0.4);
  text-align: center;
  display: block !important;
}

.work-time .ant-picker {
  min-width: 32px;
  height: auto;
  padding: 0;
  border-radius: 0;
  border: none;
  display: flex;
  background-color: transparent;
}

.work-time .ant-picker:first-child {
  padding-bottom: 4px;
}

.work-time .ant-picker.ant-picker-disabled .ant-picker-input input {
  color: rgba(28, 29, 33, 0.4);
  text-align: center;
}

.work-time .ant-picker-suffix {
  display: none;
}

.account_module_wrapper {
  min-height: calc(100vh - 60px);
}

.account_module_wrapper .work-time .ant-picker-input > input {
  font-size: 7px !important;
  color: #606060 !important;
  height: 13px !important;
}

.check-header.fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  font-size: 16px;
  line-height: 24px;
}

.mb-5 {
  margin-bottom: 5px;
}

.check-body {
  padding-top: 15px;
}

.check-body .fitem-check input.fitem-ck-input ~ .fitem-ck-txt {
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #1c1d21;
}

.check-body .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  width: 15px;
  height: 15px;
  left: 2px;
  top: 2px;
}

.check-body .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:after {
  width: 4px;
  height: 8px;
  left: 7px;
  top: 5px;
}

.teal_box {
  border: 1px solid #007e79;
  background-color: #fff;
}

.teal_box .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  background-color: #007e79;
  border-color: #007e79;
}

.yellow_box .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  background-color: #ff9800;
  border-color: #ff9800;
}

.ltgreen_box .fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  background-color: #a7c300;
  border-color: #a7c300;
}

.calendar_pop .ant-modal-body {
  padding: 30px;
  font-size: 13px;
  line-height: 21px;
}

.choose-option-wrap {
  border-top: 1px solid rgba(151, 151, 151, 0.4);
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
}

.choose-option-wrap .btn {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  width: 100%;
}

.choose-option-wrap p {
  margin-bottom: 10px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.row.gutter-10 {
  margin-right: -5px;
  margin-left: -5px;
}

.row.gutter-10 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.col-4,
.col-6,
.col-12,
.col-7,
.col-5 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.col-7 {
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-5 {
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.cust-textarea {
  background-color: #f4f7f9;
  border: 1px solid #ececf2;
  padding: 15px;
  font-size: 14px;
  line-height: 17px;
  height: 147px;
}

.side-status-block {
  padding: 9px 20px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
}

.side-status-block.red-status {
  background-color: #ec5301;
}

.side-status-block.grey-status {
  background-color: #9aa0ab;
}

.side-status-block.green-status {
  background-color: #78c600;
}

.side-status-block .number {
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  margin-right: 10px;
}

.fs-13 {
  font-size: 13px;
  line-height: 21px;
}

.next-service-list {
  padding-bottom: 20px;
  border-bottom: 1px solid #e1e1e5;
}

.next-service-list li {
  width: 25%;
  padding-right: 5px;
  padding-bottom: 0;
}

.subacribe-total {
  border-top: 1px solid #e1e1e5;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.user-photo {
  width: 72px;
  height: 72px;
  min-width: 72px;
  border-radius: 100%;
  overflow: hidden;
}

.user-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-cont-detail {
  padding-bottom: 15px;
}

.user-cont-detail p {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.user-cont-detail p strong {
  min-width: 87px;
  padding-right: 10px;
}

.dash-list li {
  position: relative;
  padding-left: 10px;
  padding-bottom: 0;
}

.dash-list li:before {
  content: "-";
  position: absolute;
  left: 0;
  top: 0;
}

.timing-bloc {
  max-width: 200px;
}

.text-underline {
  text-decoration: underline;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.edit-hours .fitem-check {
  margin-top: 7px;
}

.service-check-block {
  border-top: 1px solid #979797;
}

.service-check-block a {
  color: #1f1f25;
}

.cust-radio.fitem-check input.fitem-ck-input ~ .fitem-ck-txt:before {
  border-radius: 100%;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.cust-radio.fitem-check input.fitem-ck-input ~ .fitem-ck-txt:after {
  left: 6px;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: none;
  background-color: #fff;
}

.layout-plan-left {
  width: 100%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  padding-right: 40px;
  position: relative;
}

.layout-plan-left:after {
  content: "";
  position: absolute;
  right: 0;
  top: 28px;
  bottom: 50px;
  width: 1px;
  background-color: rgba(131, 120, 120, 0.4);
}

.layout-plan-right {
  width: 100%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 40px;
}

.porttrait-block,
.landscape-block {
  padding-bottom: 30px;
}

.porttrait {
  width: 140px;
  height: 189px;
  background-color: #d8d8d8;
  margin-bottom: 20px;
}

.landscape {
  width: 189px;
  height: 140px;
  background-color: #d8d8d8;
  margin-bottom: 20px;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.format-radio {
  padding-bottom: 30px;
}

.format-radio .fitem-check + .fitem-check {
  margin-left: 30px !important;
}

.preview-pdf {
  border: 1px solid rgba(151, 151, 151, 0.4);
  padding: 5px 10px;
}

.helper-text {
  font-size: 12px;
  color: #555555;
  margin-bottom: 5px;
  line-height: 18px;
  font-weight: 700;
}

.border-around {
  border: 1px solid #efeff8;
}

.inner-table.table thead th {
  border-bottom: none;
}

.inner-table.table th:first-child,
.inner-table.table td:first-child {
  border-right: 1px solid #efeff8;
  min-width: 217px;
}

.inner-table-second.table thead th {
  border-bottom: none;
}

.inner-table-second.table th:nth-child(2),
.inner-table-second.table td:nth-child(2) {
  border-right: 1px solid #efeff8;
  min-width: 217px;
}

.inner-table-multiple.table thead th {
  border-bottom: none;
}

.inner-table-multiple.table th:nth-child(3),
.inner-table-multiple.table td:nth-child(3) {
  border-right: 1px solid #efeff8;
}

.inner-table-multiple.table th:nth-child(5),
.inner-table-multiple.table td:nth-child(5) {
  border-right: 1px solid #efeff8;
}

.inner-table-multiple.table th:nth-child(7),
.inner-table-multiple.table td:nth-child(7) {
  border-right: 1px solid #efeff8;
}

.ccr-block h6 {
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
}

.emoji-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
}

.min-w-85 {
  min-width: 85px;
}

.franch-min-w {
  min-width: 250px;
  position: relative;
}

.franch-min-w:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  background-color: #efeff8;
  top: -20px;
  bottom: -20px;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.left-outer {
  width: 56%;
  padding-right: 10px;
}

.right-outer {
  width: 44% !important;
  padding-left: 10px;
}

/*.w-1596{width: 1596px;}*/
.chart_bottom-50 {
  margin-left: -8px;
  margin-right: -8px;
}

.chart_bottom-50 li {
  padding-left: 8px;
  padding-right: 8px;
  max-width: 50% !important;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.chart_bottom-50 .chart {
  width: 123px;
}

.fs-13 {
  font-size: 13px !important;
}

.graph_count {
  font-size: 13px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.table-pl-space.ant-table-wrapper .ant-table-tbody td:first-child,
.table-pl-space
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  padding-left: 20px;
}

.table-pl-space.ant-table-wrapper .ant-table-tbody td:last-child,
.table-pl-space
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  padding-right: 20px;
}

.h-650 {
  min-height: 650px;
}

.flex-fill {
  flex: 1 1 auto;
}

.overview_wrapper .middle_grid .small_chart.w-auto {
  width: auto;
  max-width: none;
}

.max-51 {
  max-width: 51px;
}

.lh-17 {
  line-height: 17px !important;
}

.only-icon-datepick input {
  display: none;
}

.only-icon-datepick .ant-picker-suffix {
  margin-left: 0;
}

.only-icon-datepick .ant-picker {
  height: 40px;
  width: 40px;
}

.donat-block {
  max-width: 262px;
  width: 262px;
}

.mb-11 {
  margin-bottom: 11px;
}

.chck-blocks {
  margin-bottom: 15px;
}

.chck-blocks .teal_box {
  background-color: #f4f7f9;
}

.chck-blocks .teal_box .card-body {
  padding: 15px;
}

.chck-blocks .ant-checkbox-wrapper {
  font-weight: 700;
  color: #6f6f6f;
}

.chck-blocks .teal_box .ant-checkbox-wrapper {
  color: #1c1d21;
}

.chck-blocks .teal_box .field-lbl {
  color: #22222a;
}

/*.chck-blocks .licon{max-width: 175px;}*/
.pl-25 {
  padding-left: 25px;
}

.mb-16 {
  margin-bottom: 16px;
}

.color-22222A {
  color: #22222a !important;
}

.pt-11 {
  padding-top: 11px;
}

.lh-18 {
  line-height: 18px;
}

.overview_wrapper .tabs.mwhite li {
  transform: none;
  border-radius: 3px;
}

.data-bottom-space {
  padding-bottom: 100px;
}

.col-69-31 .inline-col-3 {
  width: 31.5%;
}

.col-69-31 .inline-col-7 {
  width: 68.5%;
}

.leadstable .ant-table {
  overflow: auto;
}

.leadstable .ant-table-wrapper .ant-table-thead th.tdate {
  max-width: 100%;
}

.pr-30 {
  padding-right: 30px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.w-65 {
  width: 65.5%;
}

.w-35 {
  width: 34.5%;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.h-100 {
  height: 100% !important;
}

.flex-0-auto {
  flex: 0 0 auto !important;
}

.yellow_border_box {
  border: 1px solid #f9a720;
  background-color: #fffefb;
}

.yellow_border_box a {
  color: #201edd;
}

.fs-14 {
  font-size: 14px !important;
}

.ant-table-filter-dropdown-btns button + button {
  margin-left: 10px;
}

.filt-shift-left.ant-table-wrapper.customer-list
  .ant-table-filter-trigger-container {
  right: 75px;
}

.pt-40 {
  padding-top: 40px !important;
}

.zip-blocks {
  border: 1px solid #efeff8;
  padding: 8px 16px;
  border-radius: 4px;
}

.zip-blocks h6 {
  margin-bottom: 10px;
  font-size: 12px;
}

.zip-list {
  margin: 0;
}

.zip-list li {
  width: 33.33%;
  padding: 4px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
}

.zip-list li:nth-child(1n + 4) {
  border-bottom: none;
}

.zip-list li .anticon-close-circle,
.zip-list li .anticon-plus-circle {
  margin-left: 6px;
  color: #555555;
  opacity: 0.8;
}

.zip-list li .anticon-plus-circle.green-text {
  color: #5d8503;
}

.spin-icon {
  width: 12px;
  height: 12px;
  line-height: 10px;
  font-weight: bold;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  cursor: pointer;
}

.spin-minus {
  margin-right: 10px;
}

.spin-plus {
  margin-left: 10px;
}

.manage_zipcode {
  border-right: 1px solid #f6f8f8 !important;
}

.int-clean-tabs {
  background-color: #f1f1f5;
  padding: 15px 31px 15px 22px;
  margin: -25px -25px 0;
}

.initial_cleaning_wrapper .calendar_wrapper {
  margin-right: 0 !important;
}

.fully-filled-services {
  background-color: rgba(207, 0, 0, 0.2);
  color: #cf0000;
}

.empty-services {
  background-color: #6dc018;
  color: #ffffff;
}

.partly-filled-services {
  background-color: #ee9d18;
  color: #ffffff;
}

.fill-badge {
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  border-radius: 11px;
  width: 76px;
  text-align: center;
}

.maintenance-container {
  padding: 25px 30px;
  width: 70%;
  background: white;
}

.maintenance-title h2 {
  font-size: 1.5em;
  font-weight: 300;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 0.5em;
  margin: 0 0 1em;
  letter-spacing: -0.5px;
}

.techFormWO {
  font-size: 1.2em;
  font-weight: bolder;
  color: #000;
  background-color: rgba(255, 182, 77, 0.2);
  border: solid 0.5px #ff681d;
  padding: 5px 20px;
  border-radius: 5px;
}

.select_frequecy_btn {
  font-size: 1.2em;
  font-weight: bolder;
  color: #000;
  background-color: rgba(255, 182, 77, 0.2);
  border: solid 0.5px #ff681d;
  padding: 5px 20px;
  border-radius: 3px;
}

.fitem .half-fitem input {
  width: 90%;
  margin-left: 0px;
}

.Oval {
  width: 16px;
  height: 16px;
  margin: 2px 5px 1px 0;
  padding: 4px;
  border: solid 1px #ff9030;
}

.content-area {
  width: 1700px;
}

.leadstblviewbyworkorder .ant-table-thead tr .ant-table-selection-column div {
  display: none;
}

.view-by-work-order-status {
  width: 315px !important;
}

.Welcome-to-PoolOps-S {
  width: 430px;
  height: 72px;
  margin: 0 0 5px;
  font-family: Raleway;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #1c1d21;
}

.Description-Copy {
  width: 360px;
  height: 17px;
  margin: 5px 70px 28px 0;
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #6f6f6f;
}

.Field-title {
  width: 360px;
  height: 21px;
  margin: 0 0 2px;
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  color: #6f6f6f;
}

.Remember-me {
  width: 85px;
  height: 16px;
  margin: 2px 0 2px 12px;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1d21;
}

.calendarViewAddBtn {
  position: absolute;
  background: #ff671d !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.calendarViewAddBtnMobile {
  width: 50px;
  width: auto;
  position: relative;
  left: 2px;
  bottom: 3px;
  height: 40px;
  font-size: 25px !important;
}
.downArrowIco{display: none;}
.dashboard-ca .arrowButton{
  display: inline-block;
  height: 37px;
  border-left: 2px solid #fff;
  margin-left: 13px;
}
.dashboard-ca .downArrowIco{
  position: relative;
  left: 10px;
  display:inline-block;
}
@media (max-width: 600px) {
  .buttonText {
    position: relative;
    bottom: 4px;
    right: 8px;
    width: 0px;
  }
}

.franchise-wraper .price_catlog_filter {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .price_catlog_add {
    position: inherit;
    height: 42px;
  }

  .width-mobile-0 {
    width: 0;
  }

  .d-mobile-none {
    display: none !important;
  }

  .filter-wraper .franchise-search-wrapper {
    margin-top: 0px;
    margin-left: 0px;
  }

  .filter-wraper .manage-btn {
    padding-top: 26px;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 600px) {
  .search-btn {
    left: "13rem";
  }

  .right_side .price_catlog_add {
    display: none;
  }

  .desktop-price-catlog-filters {
    display: flex;
    width: 70%;
  }
}

.colorForMobile {
  background-color: rgb(0, 93, 137);
  height: 60px;
  padding: 0px;
  position: fixed;
  width: auto;
  bottom: 0;
  right: 0;
  padding-left: 9px;
}

.list-view-mobile-double {
  padding-top: 10px;
}

.statusSelectorDesktop {
  display: flex;
  justify-content: end;
}

.calendarViewAddBtnDesktop {
  width: 104px;
  position: relative;
}

.calendarViewAddBtn + .list-group {
  position: absolute;
  display: block;
  top: 55px;
  width: 150px;
  right: 0px;
  margin-top: -9px;
  margin-left: 4px;
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.calendarViewAddBtn + .list-group.list-view-mobile {
  top: -150px !important;
  left: -111px !important;
}

.calendarViewAddBtn + .list-group .list-group-item {
  background: white;
  border-radius: 2px;
  line-height: 35px;
  text-align: left;
  padding-left: 15px;
  padding-bottom: 0px;
}

.calendarViewAddBtn + .list-group .list-group-item:hover {
  background: #e5e4e4;
  cursor: pointer;
}

.addNewPop > .ant-modal-content > .ant-modal-close {
  display: none;
}

.jobPopCustomer > .customerDetails > .address > label,
.jobPopCustomer > .customerDetails > .email-id > label {
  font-size: 11px;
  font-weight: normal;
  width: 100%;
}

.jobPopDateTime,
.jobPopJobName,
.jobPopDesc {
  margin-top: 10px;
}

.jobPopFrequencyForTask {
  height: 110px;
  margin-top: 0px;
}

.jobPopDateTime > label {
  width: 50%;
}

.jobPopDateTime > label + sup {
  color: red;
}

.jobPopJobName > label + sup {
  color: red;
}

.btnAddListSubmit:disabled {
  cursor: auto;
  opacity: 0.8;
}

@media all and (min-width: 600px) {
  .article-block .aleft {
    flex: 1;
  }

  .article-block .adetail {
    flex: 2;
  }

  .container {
    margin: 0;
  }

  .page-header {
    display: none;
  }

  .side-nav-res {
    display: none;
  }

  .filter_wrapper .right_side .fitem .dis_res {
    display: none;
  }

  .container .left-section {
    display: flex;
    padding-bottom: 64px;
  }

  .container .background {
    background-color: white;
  }

  .wrap-padding {
    padding: 1em;
    width: 70%;
  }

  .wrap-border-left {
    border-left: 1px solid rgb(28, 29, 33, 0.1);
  }

  .add-item-button {
    text-align: right;
    display: grid;
    align-items: center;
  }

  .right-section {
    background-color: #e4eaed;
    min-width: 425px;
    padding: 2em;
    width: 30%;
  }

  .fix-btn-grp-wo {
    background-color: #212121;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: right;
    padding: 15px 30px;
    z-index: 9;
  }

  .upload-image-section {
    width: 60%;
  }

  /* min-width: 600px */
  .sign-image-section {
    min-height: 200px;
    min-width: 450px;
  }

  .form-input {
    width: 95% !important;
    margin-right: 12px;
  }
}

.download-mpr {
  text-align: end;
  margin: 0px 0px 8px;
}

.user-status-res {
  position: absolute;
  top: -12px;
}

.user-status-res .astatus {
  z-index: 8;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  background-color: #3ba93f;
  position: absolute;
  border-radius: 100%;
  right: -5px;
  bottom: 0;
}

@media all and (max-width: 600px) {
  .side-nav {
    display: none;
  }

  .side-nav-res {
    width: 100%;
  }

  .side-nav-res .ant-menu {
    width: 100%;
  }

  .user-status .astatus {
    z-index: 8;
    /* right: 55px; */
  }

  .ant-page-header-content {
    padding-top: 0px;
  }

  /* .side-nav .ant-menu:hover {
    width: 100%;
  } */
  /* .side-nav-res .btm-menu {
    display: none;
  } */
  .hidebreak {
    display: none;
  }

  .content-area {
    padding-left: 0;
  }

  .fluid {
    position: absolute;
  }

  .article-block .aleft {
    border-right: none;
    border-bottom: 2px solid rgba(216, 216, 216, 0.5);
  }

  .page-header {
    flex: 1;
  }

  .content-area {
    width: auto;
  }

  /* Modified madhu's changes */
  .filter_wrapper .left_side .fitem {
    display: none;
  }

  .filter_wrapper {
    display: block;
    padding: 10px;
  }

  .filter_wrapper .left_side .tabs {
    width: 100%;
    display: flex;
  }

  .filter_wrapper .right_side {
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
  }

  .filter_wrapper .right_side .ico-btn {
    width: auto;
  }

  .ant-table-pagination-right {
    justify-content: flex-start;
  }

  .pagination_page_size {
    margin: 0;
  }

  .price_catalog_pagination {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .filter_wrapper .right_side .fitem .dis {
    display: none;
  }

  .filter_wrapper .right_side .fitem .dis_res {
    display: block;
  }

  .filter_wrapper .right_side .fitem {
    width: 55%;
  }

  .filter_wrapper .right_side .fitem .ant-select-single {
    width: -webkit-fill-available !important;
  }

  .filter_wrapper .right_side .link-button {
    width: auto;
    margin-top: 0px;
  }

  .tabs li {
    /* width: auto;
    padding: 4px; */
    width: 40%;
    padding: 11px 14px;
    align-items: center;
    display: flex;
  }

  .search_bar .fitem-rinner .licon {
    flex: auto;
  }

  .auth-page {
    flex-direction: column;
  }

  .auth-page .auth-container {
    max-width: 85%;
  }

  .Welcome-to-PoolOps-S {
    width: auto;
    height: auto;
    margin: 0;
  }

  .container .left-section {
    display: inline;
  }

  .container .background {
    background-color: #f1f1f5;
  }

  .wrap-padding {
    padding: 1em;
  }

  .add-item-button {
    text-align: center;
    display: grid;
    align-items: center;
    background: antiquewhite;
    margin: 4px 0px;
  }

  .right-section {
    background-color: #e4eaed;
    padding: 2em 2em 6em 2em;
  }

  .fix-btn-grp-wo {
    background-color: #212121;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 15px 30px;
    z-index: 9;
    display: flex;
  }

  .fix-btn-grp-wo button {
    font-size: 13px;
    left: 153px;
    position: relative;
  }

  .upload-image-section {
    width: 100%;
  }

  /* .contractChangeDateOwner {
    width: 50% !important
  } */
  .sign-image-section {
    min-height: 200px;
    min-width: 100%;
  }

  .form-input {
    width: 90% !important;
    margin-right: 12px;
  }
}

/*side nav res*/
.side-nav-res {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 99;
  transform: translate(0, 0);
}

.side-nav-res .ant-menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.side-nav-res .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.side-nav-res .ant-menu .ant-menu-item {
  margin: 5px 0 0;
  padding: 0 15px !important;
  height: auto;
}

.side-nav-res .ant-menu .ant-menu-item.ant-menu-submenu > a {
  position: relative;
  display: block;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  > a
  .ant-menu-submenu-arrow {
  color: #fff;
  /* opacity: 0; */
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: 0;
  transition: none;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  > a
  .ant-menu-submenu-arrow:after {
  display: none;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu.has-open
  > a
  .ant-menu-submenu-arrow {
  transform: rotate(180deg);
  margin-top: -7px;
}

.side-nav-res .ant-menu .ant-menu-item.ant-menu-submenu.has-open .ant-menu-sub {
  opacity: 1;
  display: block;
  background: transparent;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu.has-open
  .ant-menu-sub
  .ant-menu-item.active
  .mitem {
  background: rgba(255, 255, 255, 0.1);
}

.side-nav-res .ant-menu .ant-menu-item.ant-menu-submenu .ant-menu-sub {
  opacity: 0;
  display: none;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item {
  padding-left: 42px !important;
  padding-right: 0 !important;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item
  a {
  display: block;
}

.side-nav-res
  .ant-menu
  .ant-menu-item.ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item
  .mitem {
  background: transparent;
  font-size: 12px;
}

.side-nav-res .ant-menu .ant-menu-item img {
  margin: 0 25px 0 0;
}

.side-nav-res .ant-menu .ant-menu-item:hover:not(.opitem) .mitem,
.side-nav-res .ant-menu .ant-menu-item:focus:not(.opitem) .mitem,
.side-nav-res .ant-menu .ant-menu-item.active:not(.opitem) .mitem {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-nav-res .ant-menu .ant-menu-item .mitem,
.side-nav-res .ant-menu .ant-menu-item.btn-sec > i {
  line-height: 18px;
  height: auto;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  font-weight: 700;
  padding: 12px 10px !important;
  border-radius: 4px;
  display: block;
}

.side-nav-res .ant-menu .ant-menu-item .mitem span,
.side-nav-res .ant-menu .ant-menu-item.btn-sec > i span {
  opacity: 1;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec {
  margin: 40px 0;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec > i {
  font-size: 20px;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec .btn {
  display: none;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec .btn.btn-warning {
  padding: 0 0 0 9px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec .btn.btn-warning > span {
  opacity: 0;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec .btn.btn-warning .dwnld {
  border-radius: 4px 0 0 4px;
  background: rgba(0, 0, 0, 0.1);
  padding: 13px 8px;
}

.side-nav-res .ant-menu .ant-menu-item.btn-sec .btn.btn-warning i {
  font-size: 20px;
}

.side-nav-res .ant-menu .ant-menu-item.opitem {
  background: rgba(245, 245, 250, 0.1);
  margin: 0;
}

.side-nav-res .ant-menu .ant-menu-item.opitem .mitem {
  display: flex;
  align-items: center;
}

.side-nav-res .ant-menu .ant-menu-item.opitem .mitem .userimg {
  height: 30px;
  width: 30px;
  margin: 0;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  max-width: 30px;
  max-height: 30px;
}

.side-nav-res .ant-menu .ant-menu-item.opitem:first-child .mitem {
  padding: 12px 10px !important;
}

.side-nav-res .ant-menu .plain-btn {
  margin-bottom: 0 !important;
  padding: 20px;
  display: flex;
  align-items: center;
  background: transparent;
}

.side-nav-res .ant-menu .plain-btn img + span + img {
  margin-left: 25px;
}

.side-nav-res .ant-menu .plain-btn:after {
  display: none;
}

.side-nav-res .ant-menu .ant-menu-item:active,
.side-nav .ant-menu .ant-menu-submenu-title:active {
  background: transparent;
}

/* .side-nav-res .ant-menu {
  width: 250px;
} */
.side-nav-res .ant-menu > .plain-btn img + span + img {
  display: block;
}

.side-nav-res .ant-menu ul .ant-menu-item .ant-menu-submenu-arrow {
  opacity: 1;
}

/* .side-nav-res .ant-menu ul .ant-menu-item .mitem span {
  opacity: 1;
} */
.side-nav-res .ant-menu ul .ant-menu-item.btn-sec > i {
  display: none;
}

.side-nav-res .ant-menu ul .ant-menu-item.btn-sec .btn {
  display: flex;
}

/* .side-nav-res .ant-menu ul .ant-menu-item.btn-sec .btn.btn-warning span {
  opacity: 1;
} */
.side-nav-res .ant-menu-inline .ant-menu-selected:after,
.side-nav-res .ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}

/* .side-nav-res .ant-menu-inline-collapsed.ant-menu .ant-menu-item .mitem span {
  opacity: 0;
  vertical-align: top;
} */
/* .side-nav-res .ant-menu-inline-collapsed > .plain-btn img + span + img {
  display: none;
} */
.side-nav-res
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.side-nav-res
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0;
}

.side-nav-res
  .ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0;
}

.side-nav-res hr {
  /* margin: 30px 0;
  border: none;
  background: #d8d8d8;
  height: 1px; */
  /* display: none; */
}

.side-nav-res .vertical-scroll {
  height: calc(100vh - 205px);
}

.side-nav-res .btm-menu-header {
  flex: 0 auto;
}

.side-nav-res .btm-menu-content {
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.side-nav-res .btm-menu {
  flex: 0 auto;
  width: 100%;
}

/* .oplist {
    justify-content: center;
  } */
/* .auth-page {
  flex-direction: column;
}

.auth-page .auth-container {
  max-width: 85%;
}

.Welcome-to-PoolOps-S {
  width: auto;
  height: auto;
  margin: 0;
} */

.private_account_notes_text_area {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.btn-cancel {
  margin-right: 12px;
}

.btn-cancel-bg {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #efeff8;
}

.btn-cancel-bg:hover {
  border: 1px solid rgb(0, 93, 137) !important;
  color: #000 !important;
}

.btn-updt {
  background-color: #ff681d;
  color: #ffffff;
  border: 1px solid #ff681d;
}

.btn-updt:hover {
  background-color: transparent;
  border: 1px solid #ff681d !important;
  color: #000 !important;
}

.btn.btn-close {
  background-color: #fff !important;
  color: #297c78 !important;
}

.btn.btn-close:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.btn.btn-edit-pc {
  font-size: 1.2em;
  font-weight: bolder;
  color: #000;
  background-color: rgba(255, 182, 77, 0.2);
  border: solid 0.5px #ff681d;
  padding: 5px 20px;
  border-radius: 5px;    
  height: 36px;
}

.long-description {
  white-space: nowrap;
  width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .long-description:hover {
  overflow: visible;
} */
.add_line_modal .ant-modal-content {
  border: 1px solid #989c9f;
  border-radius: 0px;
}

.exclamation_icon {
  color: #ff681d;
  font-weight: 600;
}

.rowC {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.float-child {
  width: 20%;
  float: left;
  padding: 20px;
}

.float-child-left {
  float: left;
  padding: 20px;
}

.float-child-right {
  float: right;
  padding: 20px;
}

.float-right-franchise {
  width: 70%;
  float: right;
  padding: 20px;
}

.accouting-area .content-area {
  width: 100% !important;
}

.colDesign {
  width: 100px;
  height: 5px;
}

.inputDesign {
  width: "100px";
  height: "10px";
  margin-left: "55px";
}

.classToggleBtn {
  padding-left: "0px";
  padding-right: "0px";
  border-radius: "25px";
  border: "1px solid rgb(0, 93, 137)";
}

.ant-input-affix-wrapper {
  background: #f4f7f9;
  border: 1px solid #f4f7f9;
  padding: 8px 16px;
}

.ant-input-affix-wrapper:hover {
  border-color: transparent;
  box-shadow: none !important;
  z-index: 1;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: transparent;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: rgb(0, 93, 137) !important;
  box-shadow: none !important;
  z-index: 1;
}

.switch-nonactive {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 25px !important;
  border: 1px solid #90979e !important;
  background: #90979e !important;
}

.switch-active {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 25px !important;
  border: 1px solid rgb(0, 93, 137) !important;
  background: rgb(0, 93, 137) !important;
}

.disableddiv {
  pointer-events: none;
  opacity: 0.4;
}

.pulsitem {
  display: grid;
  margin: 2%;
  width: 25%;
  background-color: #ebf0ec;
  padding: 30px;
  border: transparent;
}

.pulsem-notifi-resid {
  font-size: 20px;
  line-height: 19px;
}

.toggle1-res-cus {
  margin-left: 45px;
}

.pulscheck {
  display: grid;
  margin-top: 30px;
}

.toggle2-res-cus {
  margin-left: 22px;
}

.check1-res-cus {
  margin-top: 10px;
}

.check2-res-cus {
  margin-top: 10px;
}

.space1-btw-box {
  margin-left: 35px;
}

.space2-btw-box {
  margin-left: 35px;
}

.btn-space-tog {
  margin-top: 30px;
}

.pre-btn-spc {
  margin-top: 20px;
}

.btn btn-brand btn-bdr {
  margin-top: 20px;
}

.tecpulsenote {
  display: grid;
  margin: 13px;
  margin-left: 3px;
}

.checkcssgap {
  margin-top: 10px;
}

.btn-check-box {
  margin-left: 10px;
}

.linebtncss {
  margin: 6px 38px;
}

.testclass {
  margin-left: 10px;
}

.ant-spin-dot-item {
  background-color: #999;
}

.ant-spin-text {
  color: #999;
}

.repairCodesSpin,
.searchableCustomerNameSpin {
  margin: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}

.tec-re-asign {
  display: grid;
  margin: 0%;
  width: 33%;
  background-color: white;
  padding: 30px;
  border: transparent;
}

.bul-res-tec {
  margin-left: -43px;
  margin-top: -20px;
}

.res-fr-tech-in {
  background-color: #ebf0ec;
  border: transparent;
  margin-left: -43px;
  margin-top: -20px;
  margin-top: 10px;
  margin-bottom: 20px !important;
}

.tec-in-with {
  margin-top: 0px;
  margin-left: 60px;
}

.sec-part-service {
  /* display: flex; */
  /* margin-left: 36%; */
  /* margin-right: 30%; */
  /* margin-bottom: 20%; */
  background-color: white;
  padding: 30px;
  border: transparent;
  margin: 40px;
  width: 300%;
  text-align: left;
  /* width: 68%; */
}

.tec-re-btn {
  background-color: white;
  padding: 30px;
  border: transparent;
  margin: 41px;
  width: 100%;
  text-align: left;
  min-width: 800px;
}

.res-tec-fr h4 {
  margin-bottom: 0px;
  font-size: 15px;
  color: #6c6c6c;
}
.res-tec-fr sup{
  position: relative;
  left: 3px;
  top: -3px;
}
.res-tec-fr label {
  margin-bottom: 0px;
}
.bul-res-tec{
  font-size: 20px;
}

.btn_contact_support {
  position: fixed;
  top: 25%;
  right: -45px;
  z-index: 100;
  background-color: rgb(0, 93, 137);
  color: #fff;
  border: 1px solid rgb(0, 93, 137);
  border-radius: 3px;
  padding: 7px 14px;
  transform: rotate(270deg);
  font-weight: bold;
}

.btn_contact_support:hover {
  background-color: rgb(28, 108, 148);
}

.support_btn_text {
  font-size: 18px;
}

.support_btn_icon{
  margin-right: 8px;
  transform: rotate(90deg);
  width: 18px;
}

.ant-table-wrapper .ant-table-thead td.tlead-customer-rep {
  max-width: 300px;
  min-width: 230px;
  width: 15%;
}

.ant-table-wrapper .ant-table-tbody td.tlead-customer-rep {
  max-width: 300px;
  min-width: 230px;
  width: 15%;
}

.wrapper-pointer-not-allowed {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.todayButton {
  width: 100px;
  height: 35px;
  color: #000 !important;
  background-color: #f4f7f9 !important;
  border: 1px solid #d9d9d9 !important;
  border: 1px solid #ff7f40 !important;
}

.btn-view-mode {
  width: 100px;
  height: 35px;
  color: #000 !important;
  background-color: #f4f7f9 !important;
  border: 1px solid #d9d9d9 !important;
}

.btn-view-mode.active {
  border: 1px solid #ff7f40 !important;
}

.schedule_line_modal .ant-select-selector {
  background-color: #f4f7f9 !important;
  border: 1px solid #d9d9d9 !important;
}

.account_module_wrapper h2 {
  margin-bottom: 30px;
  position: relative;
}

.account_module_wrapper h1 {
  top: 40px;
}

.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.datepicker-techglance .ant-picker-input > input {
  display: none;
}

.tech_select .ant-select-selection-overflow {
  flex-wrap: nowrap;
  display: flex;
}

.tech_select .ant-select-selector {
  display: flex;
  overflow: hidden;
}

.ant-table-wrapper .ant-table-thead td.tchemical-rep {
  font-size: 14px;
}

.ant-table-wrapper .ant-table-thead th.tchemical-grp-header-rep {
  background-color: #e9e9e9;
  border-right-width: 4px !important;
}

.report-topmenu_title {
  max-width: 40%;
  min-width: 25%;
  display: flex;
}

.report-chemical-topmenu_title {
  max-width: 40%;
  min-width: 10%;
  display: flex;
}

.report-topmenu_title h2 {
  display: contents;
}

.report-chemical-topmenu_title h2 {
  display: contents;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: pointer;
  margin-top: 7px;
}

.retailSaleModal {
  margin-top: -150px;
}

.addCustomerRetailSaleModal {
  margin-top: -200px;
}

.poolName {
  display: flex;
  gap: 10px;
}

.price-catalog-table-wraper {
  margin: 0 8px;
}

.search_box .search-btn {
  width: 100%;
  height: 42px;
  background-color: #fff;
  border: none;
}

.manage-btn {
  padding-right: 3%;
  display: flex;
  align-items: center;
}

.filter-wraper {
  margin: 8px 0px 0px 8px;
  display: flex;
  justify-content: space-between;
}

.franchise-wraper {
  display: flex;
  gap: 2%;
  width: 80%;
  align-items: center;
}

.franchise-search-wrapper {
  display: flex;
  margin-left: 2%;
  width: 80%;
  margin-top: 24px;
}

.search-box-wrapper {
  width: 100%;
}

.delete-btn-color {
  color: red;
}

.account-filter {
  padding-block: 15px;
  margin: 0px !important;
  background: #eff0f2;
}

.account-clear-all-filter {
  padding-right: 20px !important;
  text-align: end;
}

.account-date {
  border: 1px solid #dcdce6;
  border-radius: 5px;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.task-tile-chip {
  background: #fff;
  color: #000;
  border-radius: 2px;
  position: relative;
  height: 30px;
  width: 70px;
}

.task-tile-chip > img {
  margin-right: 10px;
  position: relative;
  top: 5px;
  left: 5px;
}

.modal-btn-footer {
  display: flex;
  justify-content: flex-end;
}

.h-42 {
  height: 42px !important;
}

.blink-label {
  color: #ff0909;
  font-weight: 400;
}

.blink-label-anim {
  animation: blink-animation 0.5s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

button[type="button"].ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 25px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button[type="button"].ant-switch.ant-switch-checked {
  background-color: rgb(0, 93, 137);
}

.disabled-cataloge,
.fw-bold .ant-select-selection-item {
  font-weight: bold;
}

.ant-input-affix-wrapper-disabled {
  color: black;
}

.ant-input[disabled] {
  color: black;
}
.input-color {
  background-color: #f4f7f9;
}
.serviceDuration {
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .serviceDurationInput {
    padding-bottom: 20px;
  }
}
.so-heading {
  padding-top: 10px;
}
.ant-dropdown {
  z-index: 9999999999 !important;
}

.btn-update-invoice {
  padding: 20px 30px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .serviceDurationInput {
    padding-bottom: 10px;
  }
  .recurrence-text {
    text-align: center;
  }
  .cal-listview .calendarViewAddBtn+.list-group.list-view-mobile {
    top: -150px !important;
    left: unset !important;
    right: 5px !important;
    margin-top: 0px;
  }
}

.disabled-product-input input,
.disabled-input input,
.disabled-labortime input,
.estimated-price input {
  opacity: none !important;
  color: #0000007a !important;
  background: #f4f7f9 !important;
  border: 1px solid #f4f7f9 !important;
}

.no-tick
  .ant-select-item-option-selected
  .ant-select-item-option-content:before {
  content: none; /* Remove the tick mark */
  font-weight: 900;
  font-family: "Line Awesome Free";
}

/* .half-fitem .fitem .no-scroll .ant-select-selection-overflow {
  overflow-x: auto;
  width: 367.06px;
  flex-wrap: nowrap;
}

.half-fitem .fitem .no-scroll .ant-select-selection-overflow::-webkit-scrollbar  {
  display: none;
}  */


.report-long-topmenu_title {
  max-width: 40%;
  min-width: 15%;
  display: flex;
}
.report-long-topmenu_title h2 {
  display: contents;
}
.franchise_proposal {
  padding: inherit;
  display: flex;
  justify-content: space-between;

}

.btn-mail-css {
  padding: 10px 30px;
  background: #fff;
  border-bottom: 2px solid #e1e1e5;
  align-items: center;
  width: 93%
}

.css-pro-fra {
  padding: 10px 25px;
  background: #fff;
  border-bottom: 2px solid #e1e1e5;
  align-items: center;
  width: 96%;

}

.far_bd {
  width: 93%;
  color: #1c1d21;
  resize: none;
  height: 100%;

}

.fra_pro {
  width: 50%;

}

.fra_pro_one{
  width: 50%;
  float: "right" 
}

.btn-fra-pro {
  color:#4bbadd;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-transform: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 30px;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
}

.franchise_proposal textarea {
  background-color: white !important;
  height: 85%;
  border: none;
}
.order3 .card-title .private-title{
  display: flex;
  flex-wrap: nowrap;
} 
.order3 .card-title .private-desc{
  width: 140px;
  text-align: right;
  color: gray;
  font-size: 12px;
}
.special-class .special-desc{
  text-align: right;
  color: gray;
  font-size: 12px;
  margin-bottom: 0;
}

.content-area .res-body .card-body .special-class.text-area{
  margin-bottom: 20px;
  display: flex;
}
.flex-justify-between-align-center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stax-no-datepicker-header .ant-picker-header{
  display: none;
}
.stax-no-datepicker-header .ant-picker-content{
  margin-top: 10px;
}
.stax-datepicker-year td{
  max-height: 34px;
}
.stax-datepicker-year .ant-picker-cell:before{
  top: 30%;
}
.stax-log-modal .ant-btn.ant-btn-primary{
  background-color: #ff681d;
  color: #fff;
  border-color: #ff681d;
}
.stax-log-modal .ant-btn.ant-btn-primary:hover{
  background-color: #fff;
  color: #000;
}

.pool_characterstics_modal .anticon-exclamation-circle{
  position: relative;
  top: 1px;
  margin-right: 5px;
  font-size: 18px;
  color:  #ffa700;
}
.chemical_button.disabled {
  color:#9494a5;
}