.payment_header {
    width: 100%;
    background-color: white;
}

.container-payment{
    padding-top: 85px;
    width: 60%;
    margin: auto;
}

.container-payment-mobile{
    padding-top: 85px;
}

.payment-asp-logo {
    width: 100%;
    height: 58px;
    margin: 20px 70px 35px 80px;
}

.payment-asp-logo-mobile{
    width: 30%;
    margin: 20px;
}

.block-row-mobile{
    padding: 5px 15px 15px 15px;
}

.payment-header-bg{
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, #0158af, #0158af), linear-gradient(to left, #297c78, #34615e);
}

.payment-header-bg-mobile{
    width: 100%;
    height: 30px;
    background-image: linear-gradient(to bottom, #0158af, #0158af), linear-gradient(to left, #297c78, #34615e);
  }
  
.payment-white-container
{
    padding: 23px 41px 30px 44px;
    border-radius: 8px;
    box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
}

.payment-white-container-mobile
{
    padding: 23px 11px 30px 11px;
    border-radius: 8px;
    box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
}

.payment-success-section
{
    margin-top: 10%;
    text-align: center;
}

.payment-success-message
{
    color:#3BA93F;
    margin: 2%;
}

.payment-section
{
    position: relative;
    margin: 0 0 22px;
    padding: 8px 19px 18px 10px;
    border-radius: 8px;
    border: solid 1px #e6e6e6;
    background-color: rgba(244, 247, 249, 0.24);
}

.payment-section .credit-card
{
    position: relative;
    margin: 0 0 22px;
    padding: 8px 19px 18px 10px;
    border-radius: 8px;
    border: solid 1px #e6e6e6;
    background-color: rgba(244, 247, 249, 0.24);
}

.payment-title
{   
    color: #005d89;
}

.payment-title-section
{
    margin: 0 0 0 11px;
    color: #005d89;
}

.payment-content-section
{
    margin: 0 0 4px 11px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #303130;
}

.payment-icon
{
    float: right;
    position: relative;
    color: #303130;
    font-size: 25px
}

.cc-img{
    width: 150px;
}

.cc-img-mobile{
    width: 130px;
}

.checkbox_wrapper {
    display: flex;
}

.checkbox_wrapper input[type="checkbox"] {
    width: 17px;        
}

.checkbox_wrapper label {
    color: #303130;
    font-size: 11px;
    line-height: 15px;
}