.date-calendar-plot .ant-picker-input>input {
    display: none;
}
  
.date-calendar-plot.ant-picker {
    height: 35px;
}
  
.plot-selections {
    padding: 10px;
    background: #fff;
    border-bottom: 1px solid #E1E1E5;
    width: calc(100% - 5px);
}

.filters-box{
    display: flex;
    align-items: center;
    background: #FFF;
    border-radius: 5px;    
    height: 42px;
  }

.filters-selected{
    padding: 0 15px;    
}

.week-picker .ant-picker-input input {
    content: "Week of: ";
}

.map-container{
    width: 100%;
    height: 100%;
}

.plot-jobs{
    position: fixed;
    padding: 20px;
    margin: 10px 0px;
    background: #fff;
    border-bottom: 1px solid #E1E1E5;
    width: calc(100% - 145px);
    height: 70%;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option:not(.ant-select-item-option-disabled):hover
{
    background-color: rgba(3, 88, 152, 0.15) !important;
    color: #1C1D21 !important;
    font-weight: 600;
}

.ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest .ant-select-selection-item .ant-select-selection-item-content {
    visibility: hidden;
}

.ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest .ant-select-selection-item .ant-select-selection-item-content:before {
    visibility: visible;
    content: '+...';
}

.tech_select[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

.tech_select[data-title]:after {
    content: attr(data-title);
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0,0,0,.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    position: absolute;
    right: 5px;
    bottom: -30px;    
    visibility: hidden;
}

.service_type_select[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.service_type_select[data-title]:after {
  content: attr(data-title);
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0,0,0,.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
  position: absolute;
  right: 5px;
  bottom: -30px;    
  visibility: hidden;
}

.service_type_select .ant-select-selection-overflow {
    position: fixed;
    flex-wrap: nowrap;
    display: flex;
    padding-left: 95px;
    overflow: hidden;
    width: 13%;
    max-width: 13%;
  }
  
  .service_type_select .ant-select-selector {
    display: flex;
    overflow: hidden;
  }
  
  .service_type_select .ant-select-selector::before{
    visibility: visible;
    content: 'Service Type: ';
  }

  .date_picker_footer{
    text-align: right;
    margin-bottom: 10px;
  }

  button.gm-ui-hover-effect {
    visibility: hidden;
  }

  .gm-style .gm-style-iw-c{
    padding: 0px;
    overflow: hidden;
  }
  
  .gm-style-iw {
    width: 300px;
    max-height: 500px; 
    max-width: 500px; 
    width: 300px;
  }
  .gm-style-iw-d{
    padding: 0px;
    overflow: auto !important;
  }
  
  .gm-style-iw .md-tooltip-info{
    padding-bottom: 20px;
  }

  .gm-style .gm-style-iw {
    font-size: 16px;
 }