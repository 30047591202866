.calendar-week
  .mbsc-ios.mbsc-calendar-width-md
  .mbsc-calendar-day-labels
  .mbsc-calendar-day-inner {
  min-height: inherit;
}

.calendar-week .mbsc-calendar-table {
  min-height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: 88vh;
}

.calendar-week .mbsc-calendar-body-inner > .mbsc-calendar-slide {
  position: relative;
}

.calendar-month .mbsc-calendar-body-inner > .mbsc-calendar-slide {
  position: relative;
}

.calendar-week .mbsc-calendar-labels {
  position: relative;
}

.calendar-week .mbsc-calendar-week-days {
  position: sticky;
  width: 100%;
  top: 0px;
}

.calendar-week .mbsc-calendar-table::-webkit-scrollbar {
  width: 4px;
}

.calendar-week .mbsc-calendar-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.calendar-week .mbsc-calendar-table::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 2px;
}

.calendar-month
  .mbsc-ios.mbsc-calendar-width-md
  .mbsc-calendar-day-labels
  .mbsc-calendar-day-inner {
  min-height: 233px;
  padding-bottom: 10px;
}

.calendar-day .mbsc-ios.mbsc-schedule-event-background {
  opacity: 1;
  color: #fff;
}

.calendar-day .mbsc-schedule-event * {
  color: #fff !important;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  height: 3em;
}

.calendar-label {
  color: #fff;
  height: 3em;
}

.calendar-label {
  color: #fff;
  border-radius: 2px;
  padding: 5px 10px;
  height: 100%;
}

.calendar-month .duration-15,
.calendar-month .duration-30,
.calendar-month .duration-45,
.calendar-month .duration-60,
.calendar-month .duration-90,
.calendar-month .duration-120,
.calendar-month .duration-150,
.calendar-month .duration-180,
.calendar-month .duration-210,
.calendar-month .duration-240 {
  height: 95px;
}

.duration-15 {
  height: 100px;
}

.duration-30 {
  height: 125px;
}

.duration-45 {
  height: 150px;
}

.duration-60 {
  height: 200px;
}

.duration-90 {
  height: 250px;
}

.duration-120 {
  height: 300px;
}

.duration-150 {
  height: 350px;
}

.duration-180 {
  height: 400px;
}

.duration-210 {
  height: 450px;
}

.duration-240 {
  height: 500px;
}

.calendar-label .title {
  font-weight: bolder;
  font-size: 14px;
  white-space: normal;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.calendar-label .service-type {
  font-weight: bolder;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.calendar-label .subtitle {
  font-size: 15px;
  text-transform: capitalize;
}

.calendar-label .subtitle.time {
  font-size: 15px;
  text-transform: capitalize;
  padding-bottom: 7px;
  flex: 0 0 70%;
}

.eventDatePicker {
  padding: 0 !important;
}

.calendar-label .mbsc-font-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
}

.calendar-month .mbsc-calendar-text {
  height: 80px !important;
}

.calendar-week .mbsc-calendar-text {
  height: 80px !important;
}

.calendar-month .mbsc-calendar-text:has(.duration-15),
.calendar-month .mbsc-calendar-text:has(.duration-30),
.calendar-month .mbsc-calendar-text:has(.duration-45),
.calendar-month .mbsc-calendar-text:has(.duration-60),
.calendar-month .mbsc-calendar-text:has(.duration-90),
.calendar-month .mbsc-calendar-text:has(.duration-120),
.calendar-month .mbsc-calendar-text:has(.duration-150),
.calendar-month .mbsc-calendar-text:has(.duration-180),
.calendar-month .mbsc-calendar-text:has(.duration-210),
.calendar-month .mbsc-calendar-text:has(.duration-240) {
  height: 95px !important;
}

.mbsc-calendar-text:has(.duration-15) {
  height: 80px !important;
}

.mbsc-calendar-text:has(.duration-30) {
  height: 105px !important;
}

.mbsc-calendar-text:has(.duration-45) {
  height: 130px !important;
}

.mbsc-calendar-text:has(.duration-60) {
  height: 180px !important;
}

.mbsc-calendar-text:has(.duration-90) {
  height: 230px !important;
}

.mbsc-calendar-text:has(.duration-120) {
  height: 280px !important;
}

.mbsc-calendar-text:has(.duration-150) {
  height: 330px !important;
}

.mbsc-calendar-text:has(.duration-180) {
  height: 380px !important;
}

.mbsc-calendar-text:has(.duration-210) {
  height: 430px !important;
}

.mbsc-calendar-text:has(.duration-240) {
  height: 480px !important;
}

.calendar-ca.content-area {
  width: 100% !important;
}

.calendar-ca .ant-checkbox-inner {
  border-radius: 3px;
}

.status_scheduled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #509dc2;
  border-color: #509dc2;
}

.status_forecast .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7b7a7a;
  border-color: #7b7a7a;
}

.status_incomplete .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ca605a;
  border-color: #ca605a;
}

.status_complete .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6bad64;
  border-color: #6bad64;
}

.status_cancelled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f8ad33;
  border-color: #f8ad33;
}

.ant-select-selector {
  background-color: #fff;
}

.calendar-ca .statusSelector {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0 15px;
  border: 1px solid #edeeee;
  height: 42px;
}

.calendar-ca .statusSelectorTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px 0 0;
}

.calendar-ca .ant-checkbox-group {
  width: 100%;
}

.calendar-ca .ant-radio-button-wrapper {
  background-color: #fff;
  font-weight: bold;
  color: #000;
  border-color: #ff8b47 !important;
  height: 42px;
  width: 100px;
  text-align: center;
}

.calendar-ca .ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
}

.calendar-ca .ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}

.calendar-ca .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #fff0e8;
  font-weight: bold;
  color: #000;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000;
  background: #fff0e8;
  border-color: #ffd7b6;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within,
.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-color: #ff7f40;
}

.ant-radio-button-wrapper:not(:first-child)::before,
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: #ff7f40;
}

.ant-radio-button-wrapper span {
  vertical-align: middle;
  line-height: 42px;
  font-weight: bolder;
}
.ant-radio-button-wrapper-disabled {
  color: #828282!important;
}

button.mbsc-calendar-button.mbsc-calendar-button-today.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat {
  height: 42px;
  border-radius: 6px;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #ff7f40 !important;
  background-color: #fff !important;
  width: 100px;
  font-weight: bold;
  margin-right: 20px;
  font-size: 15px;
}

.mbsc-calendar-button.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat {
  background-color: #fff !important;
  border: none !important;
}

.md-custom-header-button.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-outline {
  display: inline-block !important;
  border: 0px;
}

.md-custom-header-nav .mbsc-ios {
  display: inline-block;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-title {
  font-size: 20px !important;
}

.mbsc-calendar-header.mbsc-ios .mbsc-calendar-controls.mbsc-ios {
  background: #f7f7f7 !important;
  border-color: #edeeee;
  border-radius: 4px 4px 0 0;
  padding: 15px 15px;
}

.mbsc-calendar-body {
  border-color: #edeeee;
  border-radius: 0 0 4px 4px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
}

@media (max-width: 768px) {
  .mbsc-calendar-header.mbsc-ios .mbsc-calendar-controls.mbsc-ios {
    padding: 0px !important;
  }
  .calendar-week .mbsc-calendar-body {
    height: 80px;
  }
}

@media (max-width: 402px) {
  .mbsc-calendar-header.mbsc-ios .mbsc-calendar-controls.mbsc-ios {
    margin-top: unset;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
}

.md-label-content {
  color: #fff;
  text-transform: capitalize;
  font-size: 15px;
}

/*Mobile first Calendar CSS */
/*.md-tooltip {
  height: 75%;
}*/
.mbsc-ios.mbsc-popup-body {
  background: #fff !important;
  position: static;
}

.md-tooltip .mbsc-popup-content {
  padding: 0;
}

@media (max-width: 402px) {
  .md-tooltip {
    height: 80%;
  }
}

.button-row {
  padding-top: 30px;
  margin-bottom: -45px;
  padding-right: 30px;
  display: row;
  gap: 0px 70px;
}

.button-start-disabled {
  background-color: #929292 !important;
  cursor: not-allowed !important;
  opacity: 0.4;
  width: 10.5em;
}

.button-start-enabled {
  width: 10.5em;
}

.md-tooltip {
  font-size: 15px;
  font-weight: 600;
}

.md-tooltip-header {
  padding: 12px 16px;
  color: #eee;
}

.md-tooltip-info {
  padding: 16px 16px 60px 16px;
  position: relative;
  line-height: 32px;
}

.md-tooltip-header {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.md-tooltip-header .mbsc-font-icon {
  cursor: pointer;
  font-size: 12px;
}

.md-tooltip-time {
  display: flex;
  justify-content: space-between;
}

.md-tooltip-actions .mbsc-font-icon {
  margin: 0 5px;
  cursor: pointer;
}

.md-tooltip-title {
  font-weight: bold;
  padding-right: 10px;
}

.md-tooltip-text {
  font-weight: 300;
}

.md-tooltip-status {
  display: flex;
  flex-direction: row;
}

.md-tooltip-notes {
  display: flex;
  flex-direction: column;
}

.md-tooltip-status {
  text-transform: capitalize;
}

.md-tooltip-view-button {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.md-tooltip-delete-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.schedule_line_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.schedule_line_modal .ant-modal-footer .ant-btn-primary:hover {
  background: #ff681d;
  border-color: #ff681d;
}

.schedule_line_modal .ant-modal-footer .ant-btn-default {
  background-color: #fff;
  border: 1px solid #efeff8;
  color: #6f6f6f;
}

.selectorSpin {
  margin: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}

.ant-empty-img-default {
  width: auto;
}

.calendar-ca .subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
}

.calendar-ca span.ant-select-selection-search,
.schedule_line_modal span.ant-select-selection-search {
  top: unset !important;
  bottom: unset !important;
}

.schedule_delete_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.reassignEventDatePicker .ant-picker-date-panel .ant-picker-body,
.datepicker-calendar-popup .ant-picker-date-panel .ant-picker-body {
  padding: 12px;
}

.datepicker-calendar .ant-picker-input > input {
  display: none;
}

.ant-picker.datepicker-calendar {
  background: #fff !important;
  border-color: #ff681d;
}

.ant-btn.ant-btn-primary:disabled {
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.md-tooltip-name {
  text-decoration: underline;
}

.schedule_line_modal .ant-alert-message {
  color: #2988c2;
  font-size: 12px;
}

.mbsc-popup-wrapper {
  z-index: 99999 !important;
}

.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-day.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-marks.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-week-day.mbsc-ltr {
  text-align: center !important;
}

.calendarDateHeader {
  text-align: center;
  color: #000;
  font-weight: bolder;
  font-size: 30px;
  font-family: Lato, sans-serif;
}

.ant-checkbox + span {
  padding-right: 5px;
  padding-left: 3px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  line-height: 1.6em !important;
  margin-bottom: 2.2em !important;
}

.calendar-month .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  line-height: 1.6em !important;
  margin-bottom: 0.2em !important;
}

.ant-card-extra > .chemical_header_date {
  font-weight: bold;
}

.chemical_button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #3fbee5;
}

.middle-section-no-data {
  border: none;
}

@media (max-width: 1600px) {
  .calendar-label-icons {
    justify-content: left;
  }
}

.mbsc-ios.mbsc-schedule-date-header {
  visibility: hidden;
}

.calendar-filter-franchise .ant-select-selection-placeholder::before {
  content: "Franchise: ";
}

.calendar-filter-franchise .ant-select-selection-item::before {
  content: "Franchise: ";
}

.calendar-filter-tech .ant-select-selection-placeholder::before {
  content: "Technician: ";
}

.calendar-filter-tech .ant-select-selection-item::before {
  content: "Technician: ";
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
  font-weight: bold;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background: rgb(0, 93, 137) !important;
  border-color: rgb(0, 93, 137) !important;
}

.calendar-month .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
  display: flex;
  justify-content: center;
}

.mbsc-selected {
  background-color: #fff0e8 !important;
}

.schedule_wo_modal .ant-modal-content {
  height: 100% !important;
  margin-top: 0% !important;
  width: 100% !important;
}

.chemical_data_modal .ant-modal-content {
  height: 100% !important;
  margin-top: 0% !important;
  width: 100% !important;
}

.ant-modal.schedule_wo_modal {
  width: 70% !important;
}

.ant-modal.chemical_data_modal {
  width: 40% !important;
}

.chemical_data_modal .ant-modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
}

.chemical_row {
  width: 50%;
  float: left;
  padding: 10px 5px;
}

.calendar-location {
  padding-top: 10px;
  width: 70%;
}

.phone-icon {
  width: 25px;
  margin-right: 10px;
}

.mbsc-calendar-cell.mbsc-disabled {
  cursor: pointer !important;
}

.link-title {
  text-decoration: underline;
  cursor: pointer;
}

.mbsc-ios.mbsc-event-end {
  display: none;
}

.mbsc-ios.mbsc-event.mbsc-list-item {
  padding: 2px 0;
}

.custom-event-list {
  width: 100%;
}

.mbsc-popover-list {
  padding: 10px 5px;
}

.schedule_line_modal .ant-checkbox-wrapper span {
  text-transform: none;
  padding-left: 10px;
}

.schedule_line_modal .ant-checkbox-wrapper .ant-checkbox {
  padding-left: 0px;
}

.schedule_line_modal .ant-select-selector {
  background-color: #f4f7f9 !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-popover-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px 0 0;
}

.md-custom-header-button img {
  margin-top: 10px;
}

.md-custom-header-button img {
  margin-top: 10px;
}

.md-custom-header-date {
  display: inline-block;
  padding: 0 20px;
}

.schedule_pc_modal .pool-characteristics {
  margin: 0;
  width: 100%;
}

.schedule_pc_modal .pool-characteristics-toolbar,
.schedule_pc_modal .singleservice {
  padding: 0;
}

.schedule_pc_modal .ant-modal-content {
  height: 100%;
  width: 100%;
  margin-top: 0px;
}

.schedule_pc_modal button.btn.btn-border {
  background: #ff681d;
  color: #fff;
}

.schedule_opt_modal .ant-modal-content {
  height: 100%;
  width: 100%;
  margin-top: 0px;
}

.schedule_opt_modal .ant-modal-footer .ant-btn-primary {
  background: #ff681d;
  border-color: #ff681d;
}

.schedule_opt_modal .ant-modal-footer .ant-btn-primary:hover {
  background: #ff681d;
  border-color: #ff681d;
}

.schedule_opt_modal .ant-modal-footer .ant-btn-default {
  background-color: #fff;
  border: 1px solid #efeff8;
  color: #6f6f6f;
}

/* .mbsc-disabled .mbsc-calendar-month-name {
  text-align: center;
  display: block;
} */
.calendar-month .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
  display: inline-block;
}
.calendar-month
  .mbsc-ios.mbsc-calendar-width-md
  .mbsc-calendar-day-labels
  .mbsc-calendar-day-inner {
  text-align: left;
}
@media (min-width: 768px) {
  .calendar-ca .responsive-icon {
    display: none;
  }

  .calendar-ca .calendar-today-mobile {
    display: none;
  }

  .main-calendar-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .mbsc-calendar-header.mbsc-ios .mbsc-calendar-controls.mbsc-ios {
    margin-top: 0px;
    background: #fff !important;
  }

  .main-calendar-mobile .md-custom-header-nav {
    margin-top: 10px;
  }

  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-md.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda,
  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-sm.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda {
    margin-top: 20px;
  }

  .calendar-ca .statusSelector {
    display: none;
    /* width: 100% !important; */
  }

  .calendar-ca .ant-radio-button-wrapper {
    width: 50px !important;
    padding: 0 !important;
  }

  .calendarViewAddBtnMobile {
    width: 70% !important;
  }

  .calendar-ca .calendar-today-desktop {
    display: none;
  }

  .md-custom-header-view .ant-row.ant-row-start.ant-row-middle {
    justify-content: space-between !important;
  }

  .responsive-icon {
    background: #005d89;
    border: none !important;
    height: 42px;
    color: #fff;
    width: 100%;
    border-radius: 4px;
  }

  button.mbsc-calendar-button.mbsc-calendar-button-today.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat {
    margin: 0 !important;
    width: 60px;
  }

  .md-custom-header-date {
    padding: 0;
    line-height: 1;
  }

  .calendarDateHeader {
    font-size: 30px;
    margin: 0 5px;
  }

  .md-custom-header-button.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-outline {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1;
  }

  .md-custom-header-button img {
    margin-top: 0px;
  }

  .md-custom-header-nav .ant-row {
    justify-content: space-between;
  }

  .main-calendar-desktop {
    display: none !important;
  }

  .ant-row.event-mobile {
    width: 100%;
  }

  .ant-row.event-mobile-row {
    margin: 4px 20px 4px 25px;
    width: 100%;
  }

  .event-mobile-time {
    text-transform: lowercase;
    font-weight: 600;
  }

  .event-mobile-title,
  .event-mobile-client {
    text-transform: capitalize;
  }

  .event-mobile-service-type {
    text-transform: capitalize;
    font-weight: bolder;
    padding-left: 30px !important;
  }

  .mbsc-font-icon.mbsc-icon-link {
    margin-right: 5px;
  }

  .link-title {
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-row.event-mobile.forecast::before {
    width: 12px;
    content: "";
    height: 100%;
    position: absolute;
    background: #7b7a7a;
  }

  .ant-row.event-mobile.scheduled::before {
    width: 12px;
    content: "";
    height: 100%;
    position: absolute;
    background: #509dc2;
  }

  .ant-row.event-mobile.incomplete::before {
    width: 12px;
    content: "";
    height: 100%;
    position: absolute;
    background: #ca605a;
  }

  .ant-row.event-mobile.complete::before {
    width: 12px;
    content: "";
    height: 100%;
    position: absolute;
    background: #6bad64;
  }

  .ant-row.event-mobile.cancelled::before {
    width: 12px;
    content: "";
    height: 100%;
    position: absolute;
    background: #f8ad33;
  }

  .md-tooltip-header.incomplete {
    background: #ca605a;
  }

  .md-tooltip-header.cancelled {
    background: #f8ad33;
  }

  .md-tooltip-header.complete {
    background: #6bad64;
  }

  .md-tooltip-header.scheduled {
    background: #509dc2;
  }

  .md-tooltip-header.forecast {
    background: #7b7a7a;
  }

  .calendar-label-icons {
    justify-content: flex-end;
  }

  .mbsc-ios.mbsc-schedule-date-header {
    visibility: visible;
  }

  .mbsc-schedule-date-header-text {
    text-align: left !important;
  }

  .mbsc-schedule-date-header-text.mbsc-flex-1-1.mbsc-ios,
  .mbsc-event-day.mbsc-list-header.mbsc-ios.mbsc-hb,
  .mbsc-event-day.mbsc-list-header.mbsc-ios {
    font-size: 1em;
    font-weight: 500 !important;
  }

  .mbsc-ios.mbsc-schedule-date-header {
    background: #fff !important;
  }

  .mbsc-ios.mbsc-event.mbsc-list-item.mbsc-focus .mbsc-list-item-background,
  .mbsc-ios.mbsc-event.mbsc-list-item.mbsc-selected .mbsc-list-item-background {
    background: transparent !important;
  }

  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header,
  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
    background: #fff !important;
    font-size: 25px;
  }

  .mbsc-selected {
    background-color: transparent !important;
  }

  .mbsc-ios.mbsc-selected.mbsc-calendar-cell-text {
    background: #005d89 !important;
    border-color: #005d89 !important;
  }

  .mbsc-calendar-marks.mbsc-ios.mbsc-ltr {
    /*display: none!important;*/
  }

  .mbsc-ios.mbsc-calendar-cell {
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
  }

  .mbsc-calendar-row {
    border-bottom: #ccc solid 1px;
  }

  .mbsc-ios.mbsc-calendar-cell {
    background: #f6f6f6 !important;
  }

  .ant-modal.schedule_wo_modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .ant-modal.chemical_data_modal {
    width: 100% !important;
  }

  .ant-modal {
    top: 0 !important;
  }

  .ant-modal-wrap {
    background-color: #8f8f8fcf !important;
  }

  .ant-modal-content {
    margin-top: 20% !important;
    width: 100% !important;
  }

  .calendar-day .mbsc-schedule-date-header-text.mbsc-flex-1-1.mbsc-ios {
    display: none;
  }

  .mbsc-eventcalendar {
    height: 100%;
    overflow: hidden;
  }

  .mbsc-event-list.mbsc-event-list-scroll {
    flex: 1;
  }

  .mbsc-calendar-wrapper.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios.mbsc-calendar-wrapper-fixed {
    flex: 0;
    border: none;
  }

  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-md.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda,
  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-sm.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda {
    flex-direction: column;
    height: calc(100vh - 200px);
  }

  .mbsc-ios.mbsc-calendar-height-md .mbsc-calendar-day:after {
    display: none;
  }

  .chemical_row {
    width: 100%;
    padding: 10px 5px;
  }

  .chemical_col {
    text-align: right;
  }
}

@media (max-width: 402px) {
  .ant-radio-button-wrapper {
    padding: 0 !important;
    width: 60px !important;
  }
}

@media (min-width: 768px) {
  .calendar-day .ant-spin-container > .mbsc-calendar {
    min-height: 80vh;
  }
  .calendar-day .mbsc-event-list.mbsc-event-list-scroll {
    max-height: 75vh;
  }
}

@media (max-height: 800px) {
  .mbsc-event-list.mbsc-event-list-scroll {
    max-height: 300px;
  }

  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-md.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda,
  .mbsc-calendar.mbsc-font.mbsc-ios.mbsc-ltr.mbsc-calendar-height-sm.mbsc-calendar-width-sm.main-calendar-mobile.mbsc-eventcalendar.mbsc-eventcalendar-agenda {
    height: 100%;
  }
}

.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-day.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-marks.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-week-day.mbsc-ltr {
  background: #fff;
}

.mbsc-calendar-labels {
  padding-top: 5px;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-week-day {
  border-left: 1px solid #ccc !important;
}

.mbsc-ios.mbsc-calendar-day-text {
  margin-top: 0px;
}

.calendar-ca .ant-checkbox-wrapper {
  font-weight: bold;
  font-size: 16px;
  width: none !important;
}

.poolCharactersticsModal {
  min-width: 90% !important;
}

.centered-element-input {
  vertical-align: sub;
}

.activity-log-modal .ant-modal-close-x {
  display: block;
  width: 34px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.activity-log-modal .ant-modal-content {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
  border-radius: 6px;
  width: 420px;
  margin-top: 30%;
}

.activity-log-modal .ant-modal-header {
  padding: 10px 15px 20px 15px;
  border-radius: 4px;
}

.activity-log-modal .ant-modal-body {
  padding: 0 15px 20px 15px;
  border-radius: 4px;
}

.activity-log-modal .ant-modal-body .date-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.activity-log-modal .ant-modal-body button {
  border: #eee solid 1px;
  border-radius: 4px;
  font-size: 16px;
  color: #8c8c8c;
  cursor: pointer;
  background-color: #fff;
}

.activity-log-modal .customer-name {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.activity-log-modal .ant-empty-description {
  color: #7b7a7a;
}

.activity-log-modal .notes-wrapper {
  margin-top: 20px;
  max-height: 600px;
  overflow: auto;
}

.activity-log-modal .notes-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.activity-log-modal .notes-wrapper::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.activity-log-modal .notes-wrapper::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.activity-log-modal .notes-wrapper::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.activity-log-modal .note {
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 6px 0px;
  border: #eee solid 1px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 15px;
  margin-right: 2px;
}

.activity-log-modal .note .note-title {
  padding: 5px 10px 5px 10px;
  border-bottom: #e6e6e6 solid 1px;
  font-weight: bold;
}

.activity-log-modal .note .note-content {
  padding: 5px 10px 15px 10px;
  border-bottom: #e6e6e6 dashed 2px;
}

.activity-log-modal .note .note-content:last-child {
  padding: 5px 10px 15px 10px;
  border-bottom: none;
}

.activity-log-modal .note .note-content .ant-row {
  margin-bottom: 5px;
}

.activity-log-modal .note .note-content a {
  color: #000;
  text-decoration: underline;
}

.activity-log-modal .note .note-content-subtitle {
  font-weight: bold;
}

.activity-log-modal .ant-space-item {
  width: 100% !important;
}

.activity-log-modal .ant-space.ant-space-horizontal.ant-space-align-center {
  width: 100% !important;
}

.activity-log-modal .ant-input-affix-wrapper {
  width: 100%;
  margin: 10px 0px;
}

.dots-icon-alignment {
  display: flex;
  justify-content: end;
  flex: 0 0 30%;
}

.dots-icon-alignment-tech {
  flex: 0 0 50px !important;
  margin-left: 5px;
}

.popover-dots-alignment {
  margin-left: 110px !important;
}

.calendar-week .mbsc-calendar-text.mbsc-calendar-text-more {
  /* position:fixed;
  bottom:-22px; */
  width: 150px;
  background-color: #fff;
  height: 24px !important;
  font-weight: 600;
  padding: 2px 10px;
  border: 1px solid #000;
}
.calendar-month .mbsc-calendar-text.mbsc-ios.mbsc-ltr.mbsc-calendar-text-more {
  position: absolute;
  bottom: 36px;
  width: 150px;
  height: 24px !important;
  background-color: #fff;
  font-weight: 600;
  padding: 2px 10px;
  border: 1px solid #000;
}
.calendar-month .mbsc-calendar-labels {
  height: 236px;
}
.gm-style .gm-style-iw-tc::after {
  background: none;
  box-shadow: none;
}
