.custom-repeat-settings .mbsc-textfield-wrapper.mbsc-textfield-wrapper-outline,
.custom-repeat-settings.mbsc-textfield-wrapper.mbsc-textfield-wrapper-outline {
    display: inline-block;
    margin: 5px;
}

.custom-repeat-input.mbsc-textfield-wrapper .mbsc-textfield,
.custom-repeat-input .mbsc-textfield-wrapper .mbsc-textfield {
    height: 30px;
    width: 50px;
}

.custom-repeat-select-nr.mbsc-textfield-wrapper .mbsc-select.mbsc-textfield,
.custom-repeat-select-nr .mbsc-textfield-wrapper .mbsc-select.mbsc-textfield {
    width: 70px;
    padding-right: 30px;
}

.custom-repeat-select-month.mbsc-textfield-wrapper .mbsc-select.mbsc-textfield,
.custom-repeat-select-month .mbsc-textfield-wrapper .mbsc-select.mbsc-textfield {
    width: 140px;
    padding-right: 30px;
}

.custom-repeat-input.custom-specific-date input.mbsc-textfield {
    width: 140px;
}

.custom-repeat-desc {
    font-size: 12px;
    font-weight: normal;
    color: #555;
    margin-top: 0;
    margin-bottom: 15px;
}

.custom-repeat-input .mbsc-select-icon.mbsc-select-icon-outline,
.custom-repeat-select .mbsc-select-icon.mbsc-select-icon-outline {
    width: 16px;
    top: 3px;
    right: 10px;
}

.custom-repeat-settings {
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;
}

.mbsc-ios .custom-repeat-settings {
    background: #fff;
}

.mbsc-ios-dark .custom-repeat-settings {
    background: #2b2b2b;
}

.mbsc-material .custom-repeat-settings {
    background: #f5f5f5;
}

.mbsc-material-dark .custom-repeat-settings {
    background: #0a0a0a;
}

/* checkbox */

.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper {
    display: inline-block;
    padding-left: 25px;
    padding-right: 10px;
    background: transparent;
}

.custom-repeat-checkbox-cont .mbsc-checkbox .mbsc-checkbox-box {
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
}

.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper:after,
.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper:before {
    border: 0;
}

.custom-repeat-checkbox-cont .mbsc-checkbox .mbsc-checkbox-box:after {
    width: 10px;
    height: 6px;
    top: 4px;
    left: 3px;
}

.custom-condition-cont .mbsc-radio.mbsc-form-control-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
}

.custom-condition-cont .mbsc-ios.mbsc-form-control-wrapper:after,
.custom-condition-cont .mbsc-ios.mbsc-form-control-wrapper:before {
    border-color: transparent;
}

.custom-condition-cont .mbsc-radio-box {
    top: 40%;
}

.custom-condition-cont .mbsc-material.mbsc-radio-box:after,
.custom-condition-cont .mbsc-windows.mbsc-radio-box:after {
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    border-radius: 8px;
}

.custom-repeat-settings .mbsc-form-control-input {
    z-index: 0;
}

.mbsc-ios.mbsc-segmented{
    background:#fff !important;
}

.mbsc-segmented-item{
    margin-bottom: 10px !important;
    margin-right: 5px !important;
}

.mbsc-ios.mbsc-segmented-item {
    border: 0px;
    background-color: #f6f8fa;
    border-radius: 3px;
    font-size: 18px;    
    padding: 6px 10px;
}

.mbsc-ios.mbsc-segmented-item:before {
    border: 0px !important;
}

.mbsc-ios.mbsc-segmented-selectbox{
    border: 1px solid #ff7734;
}


.mbsc-ios.mbsc-form-control-wrapper:after{
    border:0px !important;
  }
  
.mbsc-ios.mbsc-checkbox-box{
    color: #ff7734 !important;
  }
  
.mbsc-ios.mbsc-form-control-wrapper:before{
    border:0px !important;
  }
  
.mbsc-textfield-inner {
    background: #f4f7f9;
    border:1px solid #f4f7f9;
    margin-bottom: 5px;
  }
  
.mbsc-ios.mbsc-select{
    background: #f4f7f9 !important;
    border:1px solid #f4f7f9;
  }
  
  .mbsc-ios.mbsc-calendar-controls
  {
    background-color:#f4f7f9 !important;
  }
  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: #000 !important;
    background-color:#f4f7f9 !important;
    border:1px solid #f4f7f9;
  }