.proposal {
    padding: 0 0 150px;
}

.proposal .proposal-container {
    padding: 25px 40px 100px;
    background-color: white;
}

.proposal .proposal-title {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 900;
    letter-spacing: 7px;
}

.proposal .proposal-desc {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.proposal .proposal-address p:nth-child(1),
.proposal .proposal-billing-address p:nth-child(2) {
    width: 250px;
}

.proposal .proposal-address p,
.proposal .proposal-billing-address p {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #22222a;
}

.proposal .proposal-address p:nth-child(3),
.proposal .proposal-address p:nth-child(4) {
    color: #26abde;
}

.proposal .proposal-container table thead tr {
    background-color: #bfd6ee;
}

.proposal .proposal-container table tbody tr {
    border: 1px solid #d1d1d1;
}

.proposal .proposal-container table th,
.proposal .proposal-container table td {
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    max-width: unset;
}

.proposal .proposal-container table th {
    color: #04608b;
    font-weight: 900;
    text-align: left;
    padding: 10px 50px 10px 20px;
    text-transform: uppercase;
    border-bottom: 3px solid #035f8b;
}

.proposal .proposal-container table th:before {
    width: 0 !important;
}

.proposal .proposal-container table td {
    color: #22222a;
    padding: 20px 50px 20px 20px;
    border-right: solid 1px #d1d1d1;
    font-weight: normal;
    height: auto;
    vertical-align: unset;
}

.proposal .proposal-container table td:nth-child(1) {
    border-left: solid 1px #d1d1d1;
}

.proposal .proposal-app {
    margin-top: 25px;
}

.proposal .proposal-app .proposal-app-title {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #22222a;
    text-align: center;
    margin-bottom: 0;
}

.proposal .proposal-app-notes {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: 12px;
    text-align: center;
    color: #5b6770;
    padding: 20px 0;
    text-transform: uppercase;
}

.proposal .proposal-application {
    margin: 0 50px;
}

.proposal .proposal-letter {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #22222a;
}

.proposal .proposal-count {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.proposal .proposal-count div b {
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #22222a;
}

.proposal .proposal-container .amount-table table th,
.proposal .proposal-container .additional-work-table table th {
    padding: 10px 20px;
    padding-left: auto;
}

.proposal .proposal-container .amount-table table td,
.proposal .proposal-container .additional-work-table table td {
    padding: 20px;
    padding-left: auto;
}

.proposal .amount-table thead th:nth-child(1),
.proposal .amount-table tbody tr td:nth-child(1),
.proposal .additional-work-table thead th:nth-child(1),
.proposal .additional-work-table tbody tr td:nth-child(1) {
    width: 15%;
}

.proposal .amount-table thead th:nth-child(3),
.proposal .amount-table tbody tr td:nth-child(3),
.proposal .amount-table thead th:nth-child(4),
.proposal .amount-table tbody tr td:nth-child(4),
.proposal .additional-work-table thead th:nth-child(3),
.proposal .additional-work-table tbody tr td:nth-child(3),
.proposal .additional-work-table thead th:nth-child(4),
.proposal .additional-work-table tbody tr td:nth-child(4),
.proposal .additional-work-table thead th:nth-child(5),
.proposal .additional-work-table tbody tr td:nth-child(5) {
    width: 12%;
    text-align: right;
}

.proposal .amount-table thead th:nth-child(2),
.proposal .amount-table tbody tr td:nth-child(2) {
    width: 61%;
}

.proposal .additional-work-table thead th:nth-child(2),
.proposal .additional-work-table tbody tr td:nth-child(2) {
    width: 49%;
}

.proposal .proposal-form {
    margin: 0 50px;
}

.proposal .proposal-information .proposal-input input {
    height: 65px;
    border: solid 1px #626262;
    background-color: white;
    border-radius: 0;
}

.proposal .proposal-information .date {
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
}

.proposal .proposal-action-btns {
    position: absolute;
    right: -90px;
    bottom: -180px;
}

.proposal .proposal-action-btns .btn-outline {
    height: 36px;
    border-radius: 3px;
    border: solid 1px rgba(255, 104, 29, 0.74);
    background-color: #fff8f2;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 8px 25px;
    color: #303030;
    position: relative;
    top: 2px;
    margin-right: 10px;
}

.proposal form p,
.proposal form span,
.proposal form label,
.proposal form div {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #22222a;
}

.proposal form .error {
    font-weight: bold;
    font-size: 14px;
    color: red;
    margin: 5px 0;
}

.proposal input[type="checkbox"] {
    width: 15px;
    height: 18px;
    padding: 1px 0.8px 1.8px 2px;
    border-radius: 4px;
}

.proposal .checkbox-label {
    position: relative;
    top: -3px;
}

.proposal .signature {
    height: 100px;
    width: 100%;
    border: solid 1px #626262;
}

.proposal .clear-signature {
    position: absolute;
    right: -40px;
    cursor: pointer;
}

.proposal .clear-signature i {
    font-size: 25px;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 5px;
}

.ml-5 {
    margin-left: 5px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mr-100 {
    margin-right: 100px;
}

.width-40-percent {
    width: 40%;
}

.align-center {
    align-items: center !important;
}

.border-bottom {
    border-bottom: 1px solid #005d89;
}

.dp-flex {
    display: flex;
}

.justity-btwn {
    justify-content: space-between;
}

.absolute {
    position: absolute;
}

.proposal_preview {
    width: 80%;
}

.proposal_preview>.ant-modal-content {
    width: auto;
    margin-top: 5%;
}

.proposal_preview>.ant-modal-content>.ant-modal-body {
    padding: 0;
}

.proposal_preview>.ant-modal-content> .ant-modal-close {
    display: none;
}